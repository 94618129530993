import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DataGridTypes } from "devextreme-react/data-grid";
import { useStopProgress } from "../../../../../hooks/task/useInfra";
import "../../../../../styles/task/infra-data.scss";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import ConfirmModal from "./helper-component/ConfirmModal";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";
import WorkflowProcessDetails from "./WorkflowDetails";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import PsButton from "../../../../../components/ui-components/button/Button";
import TaskIcon from "../../../../../assets/icons/icon-task-2.png";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";

const ProcessDetails = (props: DataGridTypes.MasterDetailTemplateData) => {
  const {
    infraId,
    execution_details,
    clientId,
    taskId,
    executionId,
    clientName,
    taskName,
    taskConfigPath,
    workflowId,
    status,
    executionName,
    infra_path,
    telementry_path,
    module,
    projectId,
  } = props?.data?.data || {};

  const [popupVisible, setPopupVisible] = useState(false);
  const [containerName, setContainerName] = useState("");
  const { mutate, isLoading, error, isSuccess } = useStopProgress();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `Terminating container instance : ${containerName}`,
        type: "success",
      });
      setPopupVisible(false);
    } else if (error) {
      showNotification({
        message: `Error terminating container instance: ${error.message}`,
        type: "error",
      });
    }
  }, [isSuccess, isLoading, error, popupVisible, containerName]);

  const onHandleInfraKillConfirm = useCallback(
    (e: any) => {
      const queueMsg = {
        container_name: containerName,
        container_group_name: containerName,
        module: "manual_shutdown_container",
        clientId: clientId,
        taskId: taskId,
        executionId: executionId,
        clientTag: module,
        clientName: clientName,
        taskName: taskName,
        taskConfigPath: taskConfigPath,
        workflowId: workflowId,
        status: status,
        executionName: executionName,
        infraPath: infra_path,
        telemetryPath: telementry_path,
        projectId: projectId,
        queueName: workflowId,
        stepNumber: 0,
      };
      mutate(queueMsg);
    },
    [
      containerName,
      clientId,
      taskId,
      executionId,
      module,
      clientName,
      taskName,
      taskConfigPath,
      workflowId,
      status,
      executionName,
      infra_path,
      telementry_path,
      projectId,
      mutate,
    ]
  );

  const onHandleModal = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const onHandleConfirm = useCallback((cell: any) => {
    setPopupVisible(true);
    setContainerName(cell?.data?.group_name);
  }, []);

  const ConfirmModalProps = useMemo(() => {
    return {
      onHandleInfraKillConfirm,
      onHandleModal,
      popupVisible,
      isLoading,
    };
  }, [onHandleInfraKillConfirm, onHandleModal, popupVisible, isLoading]);

  const renderViewIcon = useCallback((cell: any) => {
    return cell?.data?.name === "Workflow Orchestrator";
  }, []);

  const renderDisableIcon = useCallback((cell: any) => {
    return cell?.data?.status === "Completed";
  }, []);

  const renderAction = useCallback(
    (cell: any) => {
      return (
        <PsButton
          hint="Kill"
          icon={TaskIcon}
          mode="text"
          onClick={(e) => onHandleConfirm(cell)}
          eventName="Infra kill"
          visible={renderViewIcon(cell)}
          disabled={renderDisableIcon(cell)}
        />
      );
    },
    [onHandleConfirm, renderDisableIcon, renderViewIcon]
  );

  const columns = [
    {
      dataField: "name",
      caption: "Workflow Function",
    },
    {
      dataField: "startTime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ContactStatus,
    },
    {
      caption: "",
      type: "buttons",
      width: 180,
      cellRender: renderAction,
    },
  ];

  const executionDetailsWithId = execution_details?.map(
    (item: any, index: any) => ({
      id: `row-${index}`,
      ...item,
      clientId,
      taskId,
      executionId,
      module,
      clientName,
      taskName,
      taskConfigPath,
      workflowId,
      executionName,
      infra_path,
      telementry_path,
      projectId,
    })
  );

  const handleCellPrepared = (e: any) => {
    if (e.rowType === "data" && e.columnIndex === 0 && e.rowIndex === 0) {
      e.cellElement.style.visibility = "hidden";
    }
  };

  const gridProps = {
    dataSource: executionDetailsWithId,
    columns,
    keyExpr: "id",
    detailComponent: WorkflowProcessDetails,
    onCellPrepared: handleCellPrepared,
  };
  const pageProps = {
    nodataText: "No Function Details to display at this time",
    id: `processDetailsGrid-${infraId}`,
  };

  return (
    <React.Fragment>
      <PsDataGrid pageProps={pageProps} gridProps={gridProps} />
      <ConfirmModal {...ConfirmModalProps} />
    </React.Fragment>
  );
};

export default ProcessDetails;
