import  { useEffect, useState } from "react";
import Card from "../../../ui-components/card/card";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useFetchSpecificClient } from "../../../../hooks/client/useClientProfile";
import { useHelperHooks } from "../../../../hooks/helper/utils";

export const ClientDetailsCard = () => {
  const clientId = useHelperHooks().getQueryParams("clientId");
  const [clientDetails, setClientDetails] = useState<any[]>([]);
  const { data, isFetching } = useFetchSpecificClient(clientId as string);

  useEffect(() => {
    if (data) {
      setClientDetails(data);
    }
  }, [data]);

  if (isFetching) {
    return (
      <Card className="card_wrapper">
        <div className="chart-client-column">
          <PsSkeleton height={30} count={8} />
        </div>
      </Card>
    );
  }

  return (
    <Card className="card_wrapper">
      <div className="chart-client-column">
        {clientDetails.map((detail: any, index: any) => (
          <div key={index}>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Name'}</span>
              <span className="value">{detail?.name}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Short Name'}</span>
              <span className="value">{detail?.clientShortName}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Primary Contact'}</span>
              <span className="value">{detail?.firstName} {detail?.lastName}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Primary Email'}</span>
              <span className="value client-display-values" data-tooltip={detail?.primary_email}>
                {detail?.primary_email?.slice(0, 50)}{detail?.primary_email?.length > 50 ? '...' : ''}
              </span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Contact Phone'}</span>
              <span className="value">{detail?.phone}</span>
            </div>
            <div className="detail-row">
              <span className="label" style={{ width: "150px" }}>{'Address'}</span>
              <span className="value">{detail?.address1 + ', ' + detail?.address2}</span>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ClientDetailsCard;
