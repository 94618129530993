import { useMutation, useQuery, useQueryClient } from "react-query";
import { useCallback, useReducer, useRef } from "react";
import { DataGrid } from "devextreme-react";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import {
  createCloneTemplateApi,
  createTemplateApi,
  deleteTemplateById,
  fetchSpecificTemplateApi,
  fetchTemplateApi,
  fetchTemplateFunctionApi,
  updateTemplateApi,
} from "../../service/digital-service/api-service";
import { TemplateLibraryProps } from "../../pages/digital-service/webScraping/template-library/types";

const initialState: TemplateLibraryProps = {
  popupVisible: false,
  selectedTemplateData: null,
  editpopupVisible: false,
  deletePopupVisible: false,
  clonePopupVisible: false,
  selectedTemplateId: "",
  selectedTemplateShow: false,
  refreshKey: 0,
  openLineage: false,
  selectedData: null,
};

const reducer = (state: TemplateLibraryProps, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, //used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editpopupVisible: action.payload, //used 
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used 
      };
    case "SELECTED_TEMPLATE_DATA":
      return {
        ...state,
        selectedTemplateData: action.payload, //used 
      };
    case "CLONE_POPUP_VISIBLE":
      return {
        ...state,
        clonePopupVisible: action.payload, //used 
      };
    case "SELECTED_TEMPLATE_ID":
      return {
        ...state,
        selectedTemplateId: action.payload, //used 
      };
    case "SELECTED_TEMPLATE_POPUP_SHOW":
      return {
        ...state,
        selectedTemplateShow: action.payload, //used 
      };
    case "REFRESH_KEY":
      return {
        ...state,
        refreshKey: action.payload,
      };
    case "OPEN_LINEAGE":
      return {
        ...state,
        openLineage: action.payload,
      };
    case "SELECTED_DATA":
      return {
        ...state,
        selectedData: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useTemplate = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);

  const onAddTemplateClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []); //used 

  const onEditTemplateClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_TEMPLATE_DATA", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []); //used

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: isVisble });
    },
    []
  ); //used 

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  ); //used 

  const handleSelectTemplate = (data: any) => {
    dispatch({ type: "SELECTED_TEMPLATE_DATA", payload: data });
  }; //used 

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectTemplate(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []); //used 

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []); //used 

  const onClonePopupOpen = useCallback((data: any) => {
    handleSelectTemplate(data);
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: true });
  }, []); //used 

  const onClonePopupClose = useCallback(() => {
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: false });
  }, []); //used

  const handleRowClick = useCallback((e: any) => {
    const { data } = e || {};
    dispatch({ type: "SELECTED_TEMPLATE_ID", payload: data });
    dispatch({ type: "SELECTED_TEMPLATE_POPUP_SHOW", payload: true });
  }, []); //used 

  const onHandleTemplatePopupClose = useCallback(() => {
    dispatch({ type: "SELECTED_TEMPLATE_POPUP_SHOW", payload: false });
  }, []); //used 

  const setRefreshKey = useCallback((refreshKey: any) => {
    dispatch({ type: "REFRESH_KEY", payload: refreshKey + 1 });
  }, []);

  const setOpenLineage = useCallback((value: boolean) => {
    dispatch({ type: "OPEN_LINEAGE", payload: value });
  }, []);

  const setSelectedData = useCallback((data: any) => {
    dispatch({ type: "SELECTED_DATA", payload: data });
  }, []);

  return {
    state,
    gridRef,
    onAddTemplateClick,
    changePopupVisibility,
    handleSelectTemplate,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditTemplateClick,
    onClonePopupOpen,
    onClonePopupClose,
    changeEditPopupVisibility,
    handleRowClick,
    onHandleTemplatePopupClose,
    setRefreshKey,
    setOpenLineage,
    setSelectedData
  };
};

export const useFetchTemplate = () => {
  return useQuery("fetch-template", fetchTemplateApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          ...item,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; //used 

export const useFetchSpecificTemplate = (template_id: string) => {
  return useQuery(
    ["fetchSpecificTemplate", template_id],
    () => fetchSpecificTemplateApi(template_id),
    {
      enabled: !!template_id,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

export const useCreateTemplate = (
  toggleTemplateModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const templatefunc = isEdit ? updateTemplateApi : createTemplateApi;
  const message = isEdit ? "Template updated successfully" : "Template created successfully";
  return useMutation(templatefunc, {
    onSuccess: () => {
      toggleTemplateModal();
      fnQueryClient.invalidateQueries("fetch-template");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; //used 

export const useCreateCloneTemplate = (
  toggleClientModal: () => void,
  template_id: string
) => {
  const fnQueryClient = useQueryClient();
  const templatefunc = async (params: any) => {
    const type = "clone";
    const { payload } = params;
    const payloadWithCloneType = { ...payload };
    const apiFunc = createCloneTemplateApi(
      type,
      template_id,
      payloadWithCloneType
    );
    return await apiFunc;
  };
  return useMutation(templatefunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-template");
      showNotification({
        message: "Template cloned successfully",
      });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
  });
}; //used 

export const useDeleteTemplate = (
  onDeleteHandler: () => void,
  template_id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteTemplate", template_id],
    () => deleteTemplateById(template_id),
    {
      enabled: !!template_id && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-template");
        showNotification({
          message: "Template deleted successfully",
        });
      },
    }
  );
}; //used 

export const useFetchTemplateFunctionDropdown = (func_type: any) => {
  return useQuery(
    ["fetch-template-function-dropdown", func_type],
    () => fetchTemplateFunctionApi(func_type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        const functionData = data?.data;
        const functions = functionData?.functionType
        const functionTypes = functions?.map((item: any) => {
          return item?.projectType;
        });
        if (functionTypes) {
          return [functionTypes];
        }
        return [];
      },
    });
};
