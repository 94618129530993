import {
  useGetDataProcessingDataApi,
} from "../../../../hooks/datastudio/useDataAnalyzer";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { useState } from "react";
import {
  allowedPageSizes,
  dropDownOptions,
  GridActionItems,
} from "../../../../config/datastudio/commonConfig";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import PsCheckBox from "../../../../components/ui-components/checkbox/Checkbox";
import { DropDownButton} from "devextreme-react";
import MergeFunction from "./MergeFiles";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import TableSkeleton from "../../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";
import MasterDetailView from "./MyFilesInfo";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../contexts/pageContext";

const MyfilesDataGrid = ({ onRowClick, onActionHandler,tabView, userRecentData, isFilesLoading, isFilesFetching, pageIndex, pageSize, handleColumnIndexChange, apiProps}: any) => {

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    fileName,
    originalFileName,
    userName,
    processType,
    status,
    created_at,
    updated_at
  } = filterValue || {};

  const showMerge = tabView['merge'];
  const [filesData, setFilesData] = useState<any>();
  const [openPopup, setOpenPopup] = useState(false);

  const file_name = filesData?.name;
  const user_id = filesData?.userId;
  const process_type = filesData?.type;
  const dataCatalogId = filesData?.dataCatalogId;
  const { data: getFilesData, isLoading: isDataLoading } =
    useGetDataProcessingDataApi({
      filename: file_name,
      userId: user_id,
      processType: process_type,
      dataCatalogId: dataCatalogId,
      isFileUploaded: true,
    });
  const fileViewerData = JSON?.stringify(getFilesData?.data, null, 2);
  const fileExtensions = file_name?.split(".").pop();

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      name: data?.fileName,
      userId: data?.userId,
      status: data?.status,
      type: data?.processType,
      dataCatalogId: data?.dataCatalogId,
    };
    setFilesData(params);
    setOpenPopup(true);
  };


  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleRowClick = (e: any) => {
    const { data } = e;
    const params = {
      file_name: data?.fileName,
      status: data?.status,
      user_id: data?.userId,
      process_type: data?.processType,
      dataCatalogId: data?.dataCatalogId,
    };
    setFilesData(params);
    onRowClick(params);
  };

  const onHanldeCheckbox = (e: any) => {
    e?.event?.stopPropagation();
    console.log(e);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        alt="View File"
        src={MyFilesView}
        hint="View File"
        width={28}
        height={28}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  };

  const headerCellRender = () => {
    return (
      <DropDownButton
        text="Action"
        width="auto"
        stylingMode="text"
        items={GridActionItems}
        useSelectMode={true}
        displayExpr="text"
        dropDownOptions={dropDownOptions}
        onItemClick={onActionHandler}
        disabled={false}
        className="dropdown-export"
      />
    );
  };

  const renderAction = (cell: any) => {
    return (
      <PsCheckBox
        id="myfile-action"
        value={true}
        onChange={onHanldeCheckbox}
      />
    );
  };

  const title = {
    file: filesData?.name,
    status: filesData?.status,
  };

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.fileName}
      </span>
    );
  };

  if (isFilesLoading || isFilesFetching) {
    return <TableSkeleton />;
  }

  const pageProps = {
      nodataText: "Recent Updation Will Be Dispalyed Here",
      heightClass: "height-large",
      id:"myfilesgrid"
    }

  const columns = [
    {
      dataField: "action",
      caption: "Action",
      type: "buttons",
      headerCellRender: headerCellRender,
      cellRender: renderAction,
      width: 150
    },
    {
      dataField: "fileName",
      caption: "System File Name",
      filterValues: fileName,
      cellRender: renderFileName,
      width: 300
    },
    {
      dataField: "originalFileName",
      caption: "Original File Name",
      filterValues: originalFileName,
      width: 180
    },
    {
      dataField: "userName",
      caption: "Uploaded By",
      filterValues: userName,
    },
    {
      dataField: "processType",
      caption: "Type",
      filterValues: processType,
    },
    {
      dataField: "status",
      caption: "Status",
      filterValues: status,
      cellRender: ContactStatus
    },
    {
      dataField: "created_at",
      caption: "Uploaded On",
      filterValues: created_at,
      cellRender: cellStartTime
    },
    {
      dataField: "updated_at",
      caption: "Last Updated",
      filterValues: updated_at,
      cellRender: cellUpdatedTime
    },
    {
      caption: "",
      type: "buttons",
      width: 180,
      cellRender: renderViewFile,
    }
  ]

  const gridProps = {
      dataSource: DataSource,
      columns: columns,
      keyExpr: "dataCatalogId",
      allowedPageSizes: allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagerEnabled: true,
      pagingEnabled: true,
      handleColumnIndexChange,
      detailComponent: MasterDetailView,
    }

  return (
    <>
    {showMerge ?<MergeFunction /> : null}
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={isDataLoading}
        extensionType={fileExtensions}
        params={filesData}
        showExportToDataLake={true}
      />
    </div>
    </>
  );
};

export default MyfilesDataGrid;
