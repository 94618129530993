import ActiveDocuments from "./activeDocuments";
import DocumentChart from "./documentSummary";
import ComputeUitilization from "./progressStages";
import DocumentGridView from "../DocumentGridView";
import "../../../../../styles/datastudio/ManagementView.scss";
import { usePage } from "../../../../../contexts/pageContext";
import { useEffect, useMemo } from "react";

const ManagementDashboard = (componentProps: any) => {
  const {
    catalogInfo,
    onRowClick,
    onRowPrepared,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    selectedDays,
    client,
    gridRef,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData,
    mapFileData,
    setMapFileData,
    handlePageIndexChange,
    pageIndex,
    pageSize,
    apiProps,
    handleColumnIndexChange,
    handleManagementViewStatusChange,
    managementViewApiProps
  } = componentProps;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { formattedStartTime, formattedEndTime, timePeriod, t_clientName, clientId, projectId, m_status } = filterValue || {};
  const params = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
      clientId,
      projectId
    };
  }, [filterValue]);

  if(m_status){
    managementViewApiProps.additionalParams.status = m_status
  }


  return (
    <>
      <div className="management-view-wrapper">
        <DocumentChart selectedDays={params} client={t_clientName ? t_clientName : "All Clients"} />
        <div className="management-details-wrapper">
          <ActiveDocuments selectedDays={params} handleStatusChange={handleManagementViewStatusChange} />
          <ComputeUitilization selectedDays={params} client={t_clientName ? t_clientName : "All Clients"} />
        </div>
      </div>
      <div>
        <DocumentGridView
          catalogInfo={catalogInfo}
          onRowClick={onRowClick}
          onRowPrepared={onRowPrepared}
          handleClientLinkClick={handleClientLinkClick}
          handleProjectLinkClick={handleProjectLinkClick}
          handleTaskLinkClick={handleTaskLinkClick}
          gridRef={gridRef}
          telemetryData={telemetryData}
          setTelemetryData={setTelemetryData}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          pdfFinalData={pdfFinalData}
          mapFileData={mapFileData}
          setMapFileData={setMapFileData}
          setPdfFinalData={setPdfFinalData}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handlePageIndexChange={handlePageIndexChange}
          apiProps={managementViewApiProps}
          handleColumnIndexChange={handleColumnIndexChange}
          id="managementViewGrid"
        />
      </div>
    </>
  );
};

export default ManagementDashboard;