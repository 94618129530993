import React, { useEffect, useState } from "react";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import CilentDetails from "../../../../components/client/client-details/clientDetails";
import Notes from "../../../../components/client/client-details/notesTab/notes";
import ClientProfileDataTab from "../../../../components/client/client-details/clientDataTab/ClientProfileDataTab";
import FinopsTab from "../../../../components/client/client-details/FinopsTab/finopsTab";
import IntegrationTab from "../../../../components/client/client-details/integrationTab/integrationTab";
import SystemInfoTab from "../../../../components/client/client-details/systemInfoTab/systemInfoTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";
import { useLocation } from "react-router-dom";
import { useFetchSpecificClient } from "../../../../hooks/client/useClientProfile";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import { usePage } from "../../../../contexts/pageContext";

const ClientProfileTab = (props: any) => {
  const [customerId, setCustomerId] = useState("");
  const location = useLocation();
  const { selectedDays, projectInfo } = props;
  const clientId = useHelperHooks().getQueryParams("clientId");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { getPageProperties, updatePageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { selectedTabIndex: p_selectedTabIndex, selectedClientId } = filterValue || {};

  useEffect(() => {
    if (clientId) {
      if (clientId !== selectedClientId) {
        updatePageProperties("client-profile", { selectedTabIndex: 0, selectedClientId: clientId });
      } else {
        setSelectedTabIndex(p_selectedTabIndex || 0);
      }
      setCustomerId(clientId);
      fetchClientContact(clientId);
    }
  }, [clientId, location.search, p_selectedTabIndex, selectedClientId, updatePageProperties]);

  const {
    isError,
    data: clientContactData,
    error,
    isFetching,
  } = useFetchSpecificClient(customerId);

  const fetchClientContact = async (clientId: any) => {
    try {
      if (isFetching) return;

      if (isError) {
        console.error("Error fetching client contact details:", error);
        return;
      }
    } catch (error: any) {
      console.error("Error fetching client contact details:", error.message);
    }
  };

  const TabMap = [
    {
      title: "Client Profile",
      component: CilentDetails,
      componentProps: { selectedDays: selectedDays },
    },
    {
      title: "Notes",
      component: Notes,
      componentProps: { contactDetails: clientContactData },
    },
    {
      title: "Client Data",
      component: ClientProfileDataTab,
      componentProps: {
        customerId: customerId,
        selectedDays: selectedDays,
        clientContactData: clientContactData,
        projectInfo: projectInfo,
      },
    },
    {
      title: "Invoice",
      component: FinopsTab,
    },
    {
      title: "Collaboration",
      component: CollaborationChats,
    },
    {
      title: "Integration",
      component: IntegrationTab,
      componentProps: {
        clientContactData: clientContactData
      },
    },
    {
      title: "System Info",
      component: SystemInfoTab,
    },
  ];

  const onTabChange = (e: any) => {
    if (e.name === "selectedIndex") {
      setSelectedTabIndex(e.value);
      updatePageProperties("client-profile", { selectedTabIndex: e.value });
    }
  };

  const TabPanelItems = TabMap.map((tab, index) => {
    const Component = tab.component;
    return (
      <TabPanelItem title={tab.title} key={index}>
        <div style={{ minHeight: "465px" }}>
          <Component {...tab.componentProps} />
        </div>
      </TabPanelItem>
    );
  });

  return (
    <>
      <TabPanel
        focusStateEnabled={false}
        deferRendering={false}
        width={"100%"}
        animationEnabled={true}
        swipeEnabled={true}
        selectedIndex={p_selectedTabIndex || selectedTabIndex}
        onOptionChanged={onTabChange}
      >
        {TabPanelItems}
      </TabPanel>
    </>
  );
};

export default ClientProfileTab;
