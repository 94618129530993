import { ToolbarAnalytics } from "../../ui-components/toolbar-analytics";
import "../../../styles/ui-components/DataGridTitleLayout.scss";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import Pluse from "../../../assets/media/plus.png";
import Minus from "../../../assets/media/minus.png";
import RefreshIcon from "../../../assets/icons/icon-reload-1.png";
import PSIconText from "../icon-with-text/IconText";
import { useQueryClient } from "react-query";

const DataGridTitleCardLayout = (props: any) => {
  const navigate = useNavigate();
  const {
    headerText,
    refetch,
    additionalToolbarContent,
    additionalToolbarBeforeContent,
    pageBodyComponent,
    showBackButton,
    showToolbar,
    titleToolBar
  } = props;

  const initialCollapsedState = pageBodyComponent.reduce(
    (acc: any, curr: any) => {
      acc[curr.componentId] = false;
      return acc;
    },
    {}
  );

  const [collapsedStatus, setCollapsedStatus] = useState<{
    [key: string]: boolean;
  }>(initialCollapsedState);

  const handleButtonClick = useCallback((componentId: string) => {
    setCollapsedStatus((prevStatus) => ({
      ...prevStatus,
      [componentId]: !prevStatus[componentId],
    }));
  }, []);

  const fnQueryClient = useQueryClient();    
  
  const renderPageBodyComponent = () => {
    return pageBodyComponent.map((body: any, index: number) => {
      const {
        title,
        Component,
        pageRoute,
        componentId,
        componentProps,
        hideHeader,
        reloadParams
      } = body || {};

      const refresh = () => {
        if (Array?.isArray(reloadParams)) {
          reloadParams?.forEach((param) => {
            fnQueryClient.invalidateQueries(param);
          });
        } else {
          fnQueryClient.invalidateQueries(reloadParams);
        }
      };

      const onHandleNavigationClick = (item: any) => () => {
        navigate(item);
      };

      const isCollapsed = collapsedStatus[componentId];

      return (
        <>
          {titleToolBar != undefined && titleToolBar ?
            (<Component {...componentProps} className={componentId} />)
            :
            (<div
              className={`individual-grid ${isCollapsed ? "collapsed" : ""}`}
              key={title + index}
              id={componentId}
            >
              {hideHeader ? null : (
                <div
                  className={title ? "titleWrapperBar" : "titleWrapperBar-nospace"}
                >
                  <h6
                    className="underline-link"
                    onClick={onHandleNavigationClick(pageRoute)}
                  >
                    {title}
                  </h6>
                  <div style={{ display: "flex" }}>
                    <PSIconText
                      src={RefreshIcon}
                      alt="refresh"
                      onClick={() => refresh()}
                    />
                    <PSIconText
                      src={isCollapsed ? Pluse : Minus}
                      alt="expand/collapse"
                      id={`img-${componentId}`}
                      onClick={() => handleButtonClick(componentId)}
                    />
                  </div>
                </div>
              )}
              {!isCollapsed && (
                <div className="content-panel" id={componentId}>
                  <Component {...componentProps} className={componentId} />
                </div>
              )}
            </div>)}
        </>
      );
    });
  };

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics
        title={headerText}
        refresh={refetch}
        additionalToolbarContent={additionalToolbarContent}
        additionalToolbarBeforeContent={additionalToolbarBeforeContent}
        showBackButton={showBackButton}
        showToolbar={showToolbar}
      />
      {renderPageBodyComponent()}
    </div>
  );
};

export default DataGridTitleCardLayout;
