import { TreeView } from "devextreme-react";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import {
  transformProjectIntegrationData,
  useProjectProfile,
} from "../../../../hooks/client/useProjectProfile";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import React, { useMemo } from "react";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useAppHook } from "../../../../hooks/app/useApps";
import { ReferenceComponent } from "../../../core-component/referenceDataGrid/referenceComponent";
import { useGetDataProcessingDataApi } from "../../../../hooks/datastudio/useDataAnalyzer";

export const IntegrationTab = React.memo((props: any) => {
  const { projectData } = props;
  const projectId = useHelperHooks().getQueryParams("projectId") ?? "";

  const project = projectData?.length > 0 ? projectData[0] : {};
  const projectName = project?.projectName;
  const projectShortName = project?.projectShortName;

  const finalData = useMemo(() => {
    return projectId ? transformProjectIntegrationData(projectId, projectName, projectShortName) : [];
  }, [projectId, projectName, projectShortName]);

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewIntegrationFileClick,
  } = useProjectProfile();

  const {
    handleColumnIndexChange,
  } = useAppHook();

  const { data: SelectedProjectFileData, isLoading: isDataLoading, isFetching } = useGetDataProcessingDataApi({ filename: selectedFileData?.fileName, userId: selectedFileData?.userId, processType: selectedFileData?.processType, dataCatalogId: selectedFileData?.dataCatalogId, isFileUploaded: true });

  const renderItem = (item: any) => {
    const gridProps = {
      id: { projectId: item?.projectId },
      projectShortName: item?.projectShortName,
      onViewIntegrationFileClick,
      handleColumnIndexChange,
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? <ReferenceComponent {...gridProps} /> : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.fileName,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
      {isFetching ? (
        <PsSkeleton height={30} count={6} />
      ) : (
        <TreeView id="simple-treeview" items={finalData} itemRender={renderItem} />
      )}
      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewIntegrationFileClick}
        data={JSON?.stringify(SelectedProjectFileData?.data)}
        title={title}
        isLoading={isDataLoading}
        extensionType={selectedFileData?.assetType}
      />
    </>
  );
});

export default IntegrationTab;