import { useCallback } from "react";
import { Form, Item } from "devextreme-react/form";

const UserEditForm = (props: any) => {
  const { userData, updateField } = props;

  const handleFieldChange = useCallback(
    (e: any) => {
      updateField((prev: any) => ({
        ...prev,
        [e.dataField]: e.value,
      }));
    },
    [updateField]
  );

  return (
    <Form
      formData={userData}
      onFieldDataChanged={handleFieldChange}
      colCount={2}
    >
      <Item dataField="name" editorType="dxTextBox" label={{ text: "Name" }} />
      <Item
        dataField="given_name"
        editorType="dxTextBox"
        label={{ text: "Given Name" }}
      />
      <Item
        dataField="status"
        editorType="dxSelectBox"
        editorOptions={{
          items: ["Active", "Inactive", "Pending"],
        }}
        label={{ text: "Status" }}
      />
      <Item
        dataField="emails"
        editorType="dxTextBox"
        label={{ text: "Email" }}
      />
    </Form>
  );
};

export default UserEditForm;
