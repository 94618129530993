export const STATUS_LIST = [
    'Active',
    'InActive'
];
export const filterStatusList = ["All Items", ...STATUS_LIST];

export const dropDownOptions = { width: "auto" };

interface State {
    stateShort: string;
}
export type ContactStatus = (typeof STATUS_LIST)[number];

export type FilterContactStatus = ContactStatus | 'All Items';

export interface Client {
    status: ContactStatus,
    city: string,
    state: State,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    note: string,
    primary_contact: string,
    primary_email: string,
    address1: string,
    address2: string,
    important_links_and_urls: string,
    specialInstructions: string,
    technology_integration_requirements: string,
    kpis: string,
    keyMetrics_and_reporting_frequency: string;
    name: string;

}

    export interface TaskData {
        clientId: string,
        projectType: string,
        taskDescription: string,
        urls: string[],
        status: string,
        taskScheduling: string,
        taskShortName: string,
        taskName: string,
        projectId: string,
        projectName: string,
        projectTag: string,
        clientTag: string,
        clientShortName: string,
        projectShortName: string,
        maxContainerGroup: any,
        containersPerGroup: any,
        postProcessorModule?: string,
        clientName: string,
        containerSize: string;
        urlList?: string[];
        scheduler?: {
            name: string,
            frequency: string,
            start_time: string,
            month: string,
            day: string,
        },
        manifestId: string,
        workflowName: string,
        userId:'',
        userName:'',
    }

export interface TaskState {
    popupVisible: boolean,
    taskId: number,
    selectedTask: TaskData | null,
    deletePopupVisible: boolean,
    startPopupVisible: boolean,
    editPopupVisible: boolean,
    clonePopupVisible: boolean,
    fileViewerPopupVisible: boolean,
    selectedFileData: any;
    pageCount: number,
    pageSize: number,
    pageIndex: any[],
    isNextPage:boolean;
    sortOrder: string,
    sortColumn: any,
}

export const newTaskFormData: TaskData = {
    clientId: '',
    projectType: '',
    taskDescription: '',
    urls: [],
    status: '',
    taskScheduling: '',
    taskName: '',
    taskShortName: '',
    projectShortName: '',
    projectId: '',
    projectName: '',
    clientShortName: '',
    projectTag:'',
    clientTag:'',
    maxContainerGroup: '',
    containersPerGroup: '',
    postProcessorModule: '',
    clientName: '',
    containerSize: '',
    urlList: [],
    scheduler: {
        name: '',
        frequency: '',
        start_time: '',
        month: '',
        day: '',
    },
    manifestId: '',
    workflowName: '',
    userId:'',
    userName:'',
};

export const SET_PROJECT_TYPE = 'SET_PROJECT_TYPE';

export const SET_SCHEDULER_TYPE = 'SET_SCHEDULER_TYPE';

export const initialProjectState = {
    selectWebScraping: true,
    selectDocument: true,
    selectDataAnalytics: true
};

export const initialSchedulerState = {
    selectDaily: true,
    selectWeekly: true,
    selectMonthly: true
};