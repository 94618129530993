import Popup from "devextreme-react/popup";
import { useDeleteTemplate } from "../../../../../hooks/digital-service/useTemplate";
import PsButton from "../../../../../components/ui-components/button/Button";

const DeleteTemplate = (props: any) => {

  const { deletePopupVisible, onDeletePopupClose, selectedTemplateData } = props;
  const { row } = selectedTemplateData || {};
  const { data } = row || {};
  const { template_id, template_name } = data || {};
  const { isLoading, refetch, isRefetching } = useDeleteTemplate(onDeletePopupClose, template_id);

  const loaderProps = {
    show: isLoading || isRefetching,
    loadertext: "Please Wait...",
  };

  return (
    <Popup
      title="Delete Template"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height='260px'
    >
        <div className="task-start">
          <p>Are you sure you want to delete the <b>{template_name}</b> Template ?</p>
          <p><b >If you delete your Template File, you will not be able to recover it.</b></p>
        </div>
        <div>
          <PsButton onClick={() => refetch()} text="Confirm" type="normal" loaderProps={loaderProps} />
          <div style={{ float: 'right' }}>
            <PsButton onClick={onDeletePopupClose} text="Close" type="normal" />
          </div>
        </div>
    </Popup>
  );
};

export default DeleteTemplate;