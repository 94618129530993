import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import PsDataGrid from "../../../ui-components/dataGrid/DataGrid";
import {
  cellStartTime,
  cellEndTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import { useMemo } from "react";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const workflowsteps = props?.data?.data?.workflowsteps;

  const columns = useMemo(
    () => [
      { caption: "Step ID", dataField: "stepNumber", dataType: "string" },
      { caption: "Step Name", dataField: "name", dataType: "string" },
      { caption: "Description", dataField: "description", dataType: "string" },
      {
        caption: "Status",
        dataField: "status",
        dataType: "string",
        cellRender: ContactStatus,
      },
      { caption: "Functions", dataField: "functions", dataType: "string" },
      {
        caption: "Start Time",
        dataField: "startTime",
        dataType: "datetime",
        cellRender: cellStartTime,
      },
      {
        caption: "End Time",
        dataField: "endTime",
        dataType: "datetime",
        cellRender: cellEndTime,
      },
    ],
    []
  );

  const gridProps = useMemo(
    () => ({
      dataSource: workflowsteps,
      columns,
      keyExpr: "stepNumber",
    }),
    [workflowsteps, columns]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No Recent Details to display at this time",
      id: `recenthierarchyview-${props?.data?.data?.executionId}`,
    }),
    []
  );
  return <PsDataGrid pageProps={pageProps} gridProps={gridProps} />;
};

export default MasterDetailView;