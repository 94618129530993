// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-uploader-container {
  border: 2px dotted #ccc;
  display: inline-block;
  margin-bottom: 10px;
}

.file-uploader-container .dx-fileuploader-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.file-uploader-container .dx-fileuploader-input-wrapper {
  flex: 1 1;
}

.file-uploader-container .dx-fileuploader-content {
  display: flex;
  align-items: flex-start;
}

.file-uploader-container .dx-fileuploader-files-container {
  margin-left: 10px;
  display: flex;
  gap: 5px;
}

.file-form-container {
  margin-bottom: 50px;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding: 1px 1px 0 !important;
}

.file-uploader-container .note {
  display: block;
}

.progressDiv {
  margin-left: 12px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.file-uploader-title {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/fileUploader.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,QAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AACF","sourcesContent":[".file-uploader-container {\n  border: 2px dotted #ccc;\n  display: inline-block;\n  margin-bottom: 10px;\n}\n\n.file-uploader-container .dx-fileuploader-wrapper {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.file-uploader-container .dx-fileuploader-input-wrapper {\n  flex: 1;\n}\n\n.file-uploader-container .dx-fileuploader-content {\n  display: flex;\n  align-items: flex-start;\n}\n\n.file-uploader-container .dx-fileuploader-files-container {\n  margin-left: 10px;\n  display: flex;\n  gap: 5px;\n}\n\n.file-form-container {\n  margin-bottom: 50px;\n}\n\n.dx-fileuploader-show-file-list .dx-fileuploader-files-container {\n  padding: 1px 1px 0 !important;\n}\n\n.file-uploader-container .note {\n  display: block;\n}\n\n.progressDiv {\n  margin-left: 12px;\n  margin-top: 20px;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.file-uploader-title {\n  font-size: 20px;\n  font-weight: 400;\n  margin-top: 10px;\n  margin-bottom: 5px;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
