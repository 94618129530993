import { useCallback, useReducer, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { DataGrid } from "devextreme-react";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import {
  FilterContactStatus,
  filterStatusList,
  FilterUrlStatus,
} from "../../config/client/commonConfig";
import { formatDate } from "devextreme/localization";
import {
  fetchClientNames,
  fetchClientsInfo,
  fetchClientsInfoDataApi,
  fetchExceptionApi,
  fetchInvoiceApi,
  fetchtaskInvoiceApi,
  fetchRecentActivityAPI,
  fetchSpecificProjectApi,
  fetchTaskExecutionDetailsByIdApi,
  fetchExecutiontData,
  fetchestimateProjectApi,
  fetchTotalCostApi,
  fetchFunctionTypeApi,
  fetchExecutionDataList,
  fetchSidebarInfoApi,
  fetchAnalyticsScript,
  fetchSpecificProjectDropdownApi,
  exportDataApi,
  fetchWorkflowStepDataList,
  fetchRecentBoardAPI,
} from "../../service/app/api-service";
import {
  collectIdsRecursively,
  transformData,
} from "../helper/utils";
import { TelemetryItem } from "../../pages/client/client-profile/types";
import {
  fetchDateRangeApi,
  fetchUserInformationData,
} from "../../service/admin/api-service";
import { fetchDataCatalogDropdownApi, fetchFunctionDropDownDataApi } from "../../service/datastudio/api-service";
import { replaceQueryUrl } from "../../utlis/helper";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";
import { usePage } from "../../contexts/pageContext";
import { useLocation } from "react-router-dom";

export interface FilterState {
  client: any;
  status: string;
  statusName: string;
  urlStatus: string;
  datacatalogStatus: string;
  datacatalogProcessType: string;
  clientId: string;
  projectId: string;
  projectName: string;
  opexParams?: any;
  showTelemetryModal: boolean;
  selectedExecution: any;
  functions: string;
  pageCount: number;
  pageSize: number;
  pageIndex: any[];
  isNextPage: boolean;
  sortOrder: string;
  sortColumn: any;
  grid_status: string;
  kanban_clientId: string;
  selectedDays: {
    startTime: string;
    endTime: string;
    timePeriod: string;
  };
}

const initialState: FilterState = {
  client: "All Clients",
  status: filterStatusList[0],
  statusName: "",
  urlStatus: "All Items",
  datacatalogStatus: "All Items",
  datacatalogProcessType: "All Types",
  clientId: "",
  projectId: "",
  projectName: "All Projects",
  opexParams: { viewType: "opex" },
  showTelemetryModal: false,
  selectedExecution: null,
  functions: "All Functions",
  pageCount: 1,
  pageSize: 25,
  pageIndex: [0],
  isNextPage: false,
  sortOrder: "",
  sortColumn: "",
  grid_status: "",
  kanban_clientId: "",
  selectedDays: {
    startTime: "",
    endTime: "",
    timePeriod: "",
  },
};

const reducer = (state: FilterState, action: any) => {
  switch (action.type) {
    case "CLIENT":
      return {
        ...state,
        client: action.payload, // for client dashboard
      };
    case "PROJECTNAME":
      return {
        ...state,
        projectName: action.payload,
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload,
      };
    case "STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "STATUS_NAME":
      return {
        ...state,
        statusName: action.payload,
      };
    case "URLSTATUS":
      return {
        ...state,
        urlStatus: action.payload,
      };
    case "DATACATALOG_STATUS":
      return {
        ...state,
        datacatalogStatus: action.payload,
      };
    case "DATACATALOG_PROCESSTYPE":
      return {
        ...state,
        datacatalogProcessType: action.payload,
      };
    case "CLIENTID":
      return {
        ...state,
        clientId: action.payload, // for client dashboard
      };
    case "OPEXPARAMS":
      return {
        ...state,
        opexParams: action.payload,
      };
    case "SELECTED_EXECUTION":
      return {
        ...state,
        selectedExecution: action.payload, // used
      };
    case "SHOW_TELEMETRY_MODAL":
      return {
        ...state,
        showTelemetryModal: action.payload, // used
      };
    case "TEMPLATE_TYPE":
      return {
        ...state,
        functions: action.payload,
      };
    case "PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      };

    case "PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload,
      };
    case "IS_NEXTPAGE":
      return {
        ...state,
        isNextPage: action.payload,
      };
    case "PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SORTING_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };
    case "SORTING_COLUMN":
      return {
        ...state,
        sortColumn: action.payload,
      };
    case "GRID_STATUS":
      return {
        ...state,
        grid_status: action.payload,
      };
    case "KANBAN_CLIENTID":
      return {
        ...state,
        kanban_clientId: action.payload,
      };
    case "SET_SELECTED_DAYS":
      return { ...state, selectedDays: action.payload };
      
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useAppHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);
  const { updatePageProperties, getPageProperties } = usePage();
  const { pathname } = useLocation();
  const path = pathname.split("/").join("");
  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
  } = filterValue || {};

  const { data: clientDropDownData } = useFetchClientNames();
  const { data: clientData } = useFetchClient();
  const { data: templateFunction } = useFetchFunctionDropdown("template");
  const { data: datacatalogProcessType } = useFetchProcessTypeDropdown();
  const { data: datacatalogStatus } = useFetchDataCatalogStatusDropdown();
  const { data: sidebarInfo, isLoading: isSideInfoLoading } =
    useFetchSidebarInfo();
  const { data: projectDropDownData } = useFetchSpecificProjectDropdown(
    state.clientId ? state.clientId : p_clientId
  );
  const getFunctionTypeList = templateFunction;

  const formatClientData = useCallback(
    (name: any) => {
      if (name === "All Clients") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([
          ["clientName", "=", name],
          "or",
          ["name", "=", name],
        ]);
      }
      if (clientData) {
        const selectedClient = clientData?.find(
          (client: { name: string }) => client.name === name
        );
        const clientId = selectedClient ? selectedClient.clientId : null;
        dispatch({ type: "CLIENTID", payload: clientId });
        updatePageProperties(path, {
          clientId: clientId,
          t_clientName: name,
          projectId: "",
          t_projectName: "",
          headercolumn: [],
        });
        dispatch({ type: "KANBAN_CLIENTID", payload: clientId });
        const opexParamsData = { clientId: clientId, viewType: "opex" };
        dispatch({ type: "OPEXPARAMS", payload: opexParamsData });
      }
      dispatch({ type: "CLIENT", payload: name });
    },
    [clientData, path, updatePageProperties]
  );

  const filterByClient = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: name }: { item: FilterContactStatus } = e;
      formatClientData(name);
      replaceQueryUrl("client", name);
      const selectedClient = clientData?.find(
        (client: { name: string }) => client.name === name
      );
      dispatch({ type: "PROJECTNAME", payload: "" });
      if (selectedClient?.projects && selectedClient?.projects.length > 0) {
        dispatch({
          type: "PROJECTID",
          payload: selectedClient?.projects[0]?.projectId,
        });
      } else {
        dispatch({ type: "PROJECTID", payload: null });
      }
    },
    [clientData, formatClientData]
  );

  const filterByProject = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item } = e;
      const { projectName, projectId } = item;
      dispatch({ type: "PROJECTID", payload: projectId });
      dispatch({ type: "PROJECTNAME", payload: projectName });
      updatePageProperties(path, {
        projectId: projectId,
        t_projectName: projectName,
      });
      if (projectName === "All Projects") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([["projectName", "=", projectName]]);
      }
      const eventName = `project filter ${projectName}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const filterByStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterContactStatus } = e;
      if (status === "All Items") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["status", "=", status]);
      }
      dispatch({ type: "STATUS", payload: status });
      const statusName = status === "All Items" ? "" : status;
      dispatch({ type: "STATUS_NAME", payload: statusName });
      updatePageProperties(path, {
        status,
        statusName,
      });
      const eventName = `status filter ${status}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const filterByUrlStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterUrlStatus } = e;
      if (status === "All Items") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["status", "=", status]);
      }
      dispatch({ type: "URLSTATUS", payload: status });
      updatePageProperties(path, {
        url_status: status,
      });
      const eventName = `url status filter ${status}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const filterByDataCatalogProcessType = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: processtype }: { item: FilterContactStatus } = e;
      if (processtype === "All Types") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["processType", "=", processtype]);
      }
      dispatch({ type: "DATACATALOG_PROCESSTYPE", payload: processtype });
      updatePageProperties(path, {
        datacatalog_processtype: processtype,
      });
      const eventName = `data catalog process type filter ${processtype}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const filterByDataCatalogStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterContactStatus } = e;
      if (status === "All Items") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["status", "=", status]);
      }
      dispatch({ type: "DATACATALOG_STATUS", payload: status });
      updatePageProperties(path, {
        datacatalog_status: status,
      });
      const eventName = `data catalog status filter ${status}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const handleDropDownPageIndex = useCallback(() => {
    dispatch({ type: "IS_NEXTPAGE", payload: false });
    dispatch({
      type: "PAGE_INDEX",
      payload: [0],
    });
  }, []);


  const handleColumnIndexChange = useCallback(
    (e: { name: string; fullName: string; value: any; component: any }) => {
    const element = e.component?.element ? e.component.element() : null;
    const gridName = element?.getAttribute('id') as keyof FilterState || null;

      if (e.name === "columns") {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        updatePageProperties(path, {
          [gridName]: {
            ...state[gridName],
            [sortColumn]: e.value, 
          }
        });
      }
      if (e.fullName === "paging.pageIndex") {
        dispatch({
          type: "PAGE_INDEX",
          payload: [...state.pageIndex, e.value],
        });
        updatePageProperties(path, {
          [gridName]:{
          pageIndex: e.value,
          pageSize: filterValue?.[gridName]?.pageSize,
          }
        });
        const isNextPage = state.pageIndex?.includes(e.value);
        dispatch({ type: "IS_NEXTPAGE", payload: isNextPage });
      } else if (e.fullName === "paging.pageSize") {
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        const pageSize = e.value;
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "PAGE_SIZE",
          payload: pageSize,
        });
        updatePageProperties(path, {
          [gridName]:{
            pageSize: pageSize,
            pageIndex: filterValue?.[gridName]?.pageIndex,
          }
        });
      }
  }, [updatePageProperties, path, state.pageIndex, filterValue]);

  const handlePageIndexChange = useCallback(
    (e: { name: string; fullName: string; value: number; component: any }) => {
      if (e.name === "columns") {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        updatePageProperties(path, {
          [sortColumn]: e.value,
        });
      }
      if (e.fullName === "paging.pageIndex") {
        dispatch({
          type: "PAGE_INDEX",
          payload: [...state.pageIndex, e.value],
        });
        updatePageProperties(path, {
          pageIndex: e.value,
        });
        const isNextPage = state.pageIndex?.includes(e.value);
        dispatch({ type: "IS_NEXTPAGE", payload: isNextPage });
      } else if (e.fullName === "paging.pageSize") {
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        const pageSize = e.value;
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "PAGE_SIZE",
          payload: pageSize,
        });
        updatePageProperties(path, {
          pageSize,
        });
      } else if (
        e.fullName.startsWith("columns[") &&
        e.fullName.endsWith("].sortOrder")
      ) {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        const sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "SORTING_ORDER",
          payload: sortOrder,
        });
        dispatch({
          type: "SORTING_COLUMN",
          payload: sortColumn,
        });
        updatePageProperties(path, {
          sortOrder,
        });
      }
    },
    [updatePageProperties, path, state.pageIndex, state.sortOrder]
  );

  const handleStatusChange = useCallback((status: string, clientId: string) => {
    dispatch({ type: "GRID_STATUS", payload: status });
    updatePageProperties(path, {
      status,
    });
    dispatch({ type: "KANBAN_CLIENTID", payload: clientId });
  }, []);

  const handleManagementViewStatusChange = useCallback((status: string) => {
    updatePageProperties(path, {
      m_status: status,
    });
  }, []);

  const filterByFunction = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: functions }: { item: FilterContactStatus } = e;
      if (functions === "All Functions") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter([
          ["template_function", "=", functions],
        ]);
      }
      dispatch({ type: "TEMPLATE_TYPE", payload: functions });
      updatePageProperties(path, {
        functions,
      });
      const eventName = `function filter ${functions}- ${window.location.pathname}`;
      GTAButtonEventHandler(eventName);
    },
    [path, updatePageProperties]
  );

  const onRowClick = useCallback(
    ({ data }: DataGridTypes.RowClickEvent) => {
      dispatch({ type: "SELECTED_EXECUTION", payload: data });
      dispatch({
        type: "SHOW_TELEMETRY_MODAL",
        payload: !state.showTelemetryModal,
      });
      const eventName = `row click ${data?.executionId}`;
      GTAButtonEventHandler(eventName);
    },
    [state.showTelemetryModal]
  ); // used

  const onPopUpCloseTelementry = useCallback(() => {
    dispatch({ type: "SHOW_TELEMETRY_MODAL", payload: false });
    const eventName = `close telemetry modal`;
    GTAButtonEventHandler(eventName);
  }, []); // used

  const handleDateRangeChange = useCallback((value: any) => {
    const [startTime, endTime, timePeriod] = value?.value;

    const formattedStartTime = startTime
      ? formatDate(startTime, "yyyy-MM-dd")
      : null;
    const formattedEndTime = endTime ? formatDate(endTime, "yyyy-MM-dd") : null;

    const selectedDays =
      timePeriod === "CustomDateRange"
        ? {
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            timePeriod,
          }
        : { startTime: null, endTime: null, timePeriod };

    dispatch({ type: "SET_SELECTED_DAYS", payload: selectedDays });

    if (timePeriod === "CustomDateRange") {
      replaceQueryUrl(
        "period",
        `startTime=${formattedStartTime}&endTime=${formattedEndTime}`
      );
      updatePageProperties(path, {
        formattedStartTime,
        formattedEndTime,
        timePeriod
      });
    } else {
      updatePageProperties(path, {
        timePeriod,
      });
      replaceQueryUrl("period", timePeriod);
    }

    const eventName = `date_range : ${timePeriod}_${"Projects"}`;
    GTAButtonEventHandler(eventName);
  }, [path, updatePageProperties]);


  return {
    state,
    filterByClient,
    filterByProject,
    filterByStatus,
    gridRef,
    clientDropDownData,
    projectDropDownData,
    filterByUrlStatus,
    clientData,
    onRowClick,
    onPopUpCloseTelementry,
    filterByFunction,
    getFunctionTypeList,
    sidebarInfo,
    isSideInfoLoading,
    handlePageIndexChange,
    handleDropDownPageIndex,
    handleStatusChange,
    handleDateRangeChange,
    handleColumnIndexChange,
    datacatalogProcessType,
    datacatalogStatus,
    filterByDataCatalogStatus,
    filterByDataCatalogProcessType,
    handleManagementViewStatusChange
  };
};

export const useFetchClientNames = () => {
  return useQuery("fetch-clients-name", fetchClientNames, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const clientNames = data?.data?.map((item: any) => {
        return item?.name;
      });
      if (clientNames) {
        return ["All Clients", ...clientNames];
      }
      return ["All Clients"];
    },
  });
};

export const useFetchClient = () => {
  return useQuery("fetch-clients", fetchClientsInfo, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

const fetchRecentActivity = async (queryParams: any) => {
  const response: any = await fetchRecentActivityAPI(queryParams);
  if (response) {
    return response;
  }
};

export const useFetchRecentTasks = (selectedDays: any) => {
  const startTime = selectedDays?.startTime
  
  const endTime = selectedDays?.endTime

  const timePeriod = selectedDays?.timePeriod;
  const queryParams = {
    startTime,
    endTime,
    timePeriod,
  };

  return useQuery(
    ["fetch-recent-activity", queryParams],
    () => fetchRecentActivity(queryParams),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchRecentActivity = (
  clientId: any,
  selectedDays: any,
  projectId: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: boolean
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length - 1] + 1;
  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  };

  return useQuery(
    ["fetch-recent-activity", queryParams],
    () => fetchRecentActivity(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchExceptionData(executionId: string) {
  return useQuery(
    ["fetch-exception-data"],
    () => fetchExceptionApi(executionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionId,
      onSuccess: (data: any) => {},
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

const fetchFinopsCostApi = async (data: any) => {
  const { clientId, timePeriod, screen, startTime, endTime } = data || {};
  const queryParams = {
    clientId: clientId,
    screen: screen,
    frequency: timePeriod,
    startDate: startTime,
    endDate: endTime,
  };
  const response: any = await fetchTotalCostApi(queryParams);
  if (response) {
    return response;
  }
};

export const useFetchTotalCost = (data: any) => {
  return useQuery(["fetch-finops-cost", data], () => fetchFinopsCostApi(data), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useBoarViewdGrid = (
  clientId: any,
  status: any,
  // selectedDays: any,
  // projectId: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: boolean
) => {
  // const startTime = selectedDays?.startTime ? formatDate(selectedDays.startTime, "yyyy-MM-dd"): null;
  // const endTime = selectedDays?.endTime ? formatDate(selectedDays.endTime, "yyyy-MM-dd"): null;
  // const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length - 1] + 1;
  const queryParams = {
    clientId,
    status,
    // projectId,startTime,
    // endTime,
    // timePeriod,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  };

  return useQuery(
    ["fetch-board-activity", queryParams],
    () => fetchRecentActivity(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchBoardData = () => {
  return useQuery(["fetch-board-data"], () => fetchRecentBoardAPI(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export function useFetchTaskExecutionDetailsById(
  selectedExecution: any,
  workflowStepId: string
) {
  const {
    clientName,
    status,
    projectShortName,
    clientShortName,
    executionName,
    projectId,
    executionTime
  } = selectedExecution || {};
  const isStatus = status === "Completed";
  const fnQueryClient = useQueryClient();
  return useQuery(
    [
      "fetch-task-execution-ById",
      executionName,
      clientShortName,
      projectShortName,
      projectId,
      executionTime
    ],
    () =>
      fetchTaskExecutionDetailsByIdApi(
        executionName,
        clientShortName,
        projectShortName,
        projectId,
        executionTime
      ),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!projectShortName &&
        !!clientShortName &&
        !!executionName &&
        !!projectId &&
        !!executionTime,
      onSuccess: (data: any) => {
        if (isStatus) {
          fnQueryClient.invalidateQueries("fetch-task-execution");
        }
      },
      select: (data: any) => {
        const telemetryData = data?.data || [];
        const updatesData = [telemetryData]?.map((item: any) => {
          return {
            ...item,
            clientName: clientName,
            status: status,
            expanded: true,
            modules: item?.modules
              ?.filter((module: any) => {
                return !workflowStepId || module?.moduleName === workflowStepId;
              })
              ?.map((module: any, index: number) => {
                const objIndex = `${index + 1}_1`;
                const stepId = module?.moduleName;
                return {
                  ...module,
                  id: objIndex,
                  expanded: true,
                  telemetry: module?.telemetry?.map(
                    (telemetry: any, telemetryIndex: number) => {
                      const telemetryId = `${objIndex}_${telemetryIndex + 1}`;
                      const module = telemetry?.moduleName;
                      return {
                        ...telemetry,
                        id: telemetryId,
                        expanded: true,
                        result: [
                          {
                            id: telemetryId + "_1",
                            expanded: true,
                            executionDetails: telemetry?.executionDetails?.map(
                              (exe: any, executionId: number) => {
                                return {
                                  ...exe,
                                  id: `${telemetryId}_1_${executionId + 1}`,
                                };
                              }
                            ),
                            name: "Telemetry",
                          },
                          {
                            id: telemetryId + "_2",
                            expanded: true,
                            data: [
                              {
                                fileName: "data.json",
                                module: module,
                                stepId: stepId,
                              },
                            ],
                            name: "Data",
                          },
                          {
                            exception: [{ fileName: "exceptionData.json" }],
                            name: "Exception",
                            expanded: true,
                            id: telemetryId + "_3",
                          },
                        ],
                      };
                    }
                  ),
                };
              }),
          };
        });
        // addExpandProperty(updatesData);
        const transformedData: TelemetryItem[] = updatesData
          ? transformData(updatesData)
          : [];

        if (transformedData) {
          const finalItemsId = collectIdsRecursively(transformedData);
          return { finalItemsId, source: transformedData };
        }
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchExecutionDataList(selectedParams: any) {
  const { executionName, stepId, manifestId, module, executionTime } = selectedParams || {};
  return useQuery(
    ["fetch-execution-data-list", executionName, stepId, manifestId, module, executionTime],
    () => fetchExecutionDataList(executionName, stepId, manifestId, module, executionTime),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionName && !!stepId && !!manifestId && !!module && !!executionTime,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchSelectedExecutionFileData(data: any) {
  const { projectId, fileExtension, filename, type, createdDate } = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
    createdDate: createdDate,
  };
  return useQuery(
    ["fetch-execution-data", queryParams],
    () => fetchExecutiontData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type && !!createdDate,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchDropDownData = () => {
  return useQuery("fetch-clients-info-data", fetchClientsInfoDataApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useExportData = (data: any) => {
  return useQuery(["fetch-export-data", data], () => exportDataApi(data), {
    enabled: !!data,
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchSpecificProject = (clientId: string) => {
  return useQuery(
    ["fetch-project-byId", clientId],
    () => fetchSpecificProjectApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFinopsProject = (clientId: string) => {
  return useQuery(
    ["fetch-project-byId", clientId],
    () => fetchestimateProjectApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchInvoiceInfo = (clientId: any) => {
  return useQuery(
    ["fetch-invoice-info", clientId],
    () => fetchInvoiceApi(clientId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchUserInformation = (userId: any) => {
  return useQuery(
    ["fetch-user-info", userId],
    () => fetchUserInformationData(userId),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDateRange = () => {
  return useQuery("fetch-date-range", fetchDateRangeApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchFunctionDropdown = (func_type: any) => {
  return useQuery(
    ["fetch-function-dropdown", func_type],
    () => fetchFunctionTypeApi(func_type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        const functionData = data?.data;
        const functions = functionData?.templateFunction;
        const functionTypes = functions?.map((item: any) => {
          return item?.types;
        });
        if (functionTypes) {
          return ["All Functions", ...functionTypes];
        }
        return ["All Functions"];
      },
    }
  );
};

export const useFetchTaskInfo = (clientId: any, projectId: any) => {
  return useQuery(
    ["fetch-invoice-info", clientId, projectId],
    () => fetchtaskInvoiceApi(clientId, projectId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchFunctionDropDownData = (process: any) => {
  return useQuery(
    ["fetch-function-drop-down-data", process],
    () => fetchFunctionDropDownDataApi(process),
    {
      refetchOnWindowFocus: false,
      enabled: !!process,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

const transformMenu = (json: any) => {
  const icons: any = {
    Clients: "clients",
    "Data Studio": "dataStudio",
    "Digital Services": "digitalService",
    "Activity Monitor": "activityMonitor",
    "Tasks & Automation": "controlPanel",
    Finops: "finops",
    "Operations Workbench": "workbench",
    Administration: "administration",
  };

  return json.map((service: any) => ({
    text: service.service,
    icon: icons[service.service],
    path: "",
    items: service.items.map((item: any) => ({
      text: item.name,
      path: item.path,
    })),
  }));
};
export const useFetchSidebarInfo = () => {
  return useQuery(["fetch-sidebar-info"], () => fetchSidebarInfoApi(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const navData = data?.data?.[0]?.Navigation;
      const transformedJson = transformMenu(navData);
      return transformedJson;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useGetAnalyticsScript = () => {
  return useQuery(["fetch-analytics-script"], () => fetchAnalyticsScript(), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export function useFetchSpecificProjectDropdown(clientId: string) {
  return useQuery(
    ["fetch-specific-project-dropdown", clientId],
    () => fetchSpecificProjectDropdownApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchWorkflowStepDataList(selectedParams: any) {
  const { executionName, stepId, manifestId, folder, executionTime } = selectedParams || {};
  return useQuery(
    [
      "fetch-Workflow-step-data-list",
      executionName,
      stepId,
      manifestId,
      folder,
      executionTime
    ],
    () => fetchWorkflowStepDataList(executionName, stepId, manifestId, folder, executionTime),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionName && !!stepId && !!executionName && !!folder && !!executionTime,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchProcessTypeDropdown = () => {
  return useQuery("fetch-processtype-dropdown", fetchDataCatalogDropdownApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const processDropdown = data?.data?.processDropdown?.map((item: any) => {
        return item?.processType;
      });
      if (processDropdown) {
        return ["All Types", ...processDropdown];
      }
      return ["All Types"];
    },
  });
};

export const useFetchDataCatalogStatusDropdown = () => {
  return useQuery("fetch-datacatalog-status-dropdown", fetchDataCatalogDropdownApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      const statusDropdown = data?.data?.statusDropdown?.map((item: any) => {
        return item?.status;
      });
      if (statusDropdown) {
        return ["All Items", ...statusDropdown];
      }
      return ["All Items"];
    },
  });
};