import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import TaskDetails from "../../../../components/task/task-details/TaskDetails";
import Notes from "../../../../components/task/task-details/notesTab/notes";
import FinopsTab from "../../../../components/task/task-details/finopsTab/finopsTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";
import TaskDataTab from "../../../../components/task/task-details/TaskDataTab";
import { useEffect, useState } from "react";
import { usePage } from "../../../../contexts/pageContext";
import { useHelperHooks } from "../../../../hooks/helper/utils";

const TaskSummary = (props: any) => {
  const { selectedDays, taskData } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const taskId = useHelperHooks().getQueryParams("taskId");
  const { getPageProperties, updatePageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { selectedTabIndex: p_selectedTabIndex, selectedTaskId } = filterValue || {};

  useEffect(() => {
    if ( taskId !== selectedTaskId ) {
      updatePageProperties("client-task", { selectedTabIndex: 0, selectedTaskId: taskId });
    } else {
      setSelectedTabIndex(p_selectedTabIndex || 0);
    }
  }, [p_selectedTabIndex, selectedTaskId, taskId, updatePageProperties])

  const TabMap = [
    {
      title: "Task Details",
      component: TaskDetails,
      componentProps: { selectedDays },
    },
    { title: "Notes", component: Notes },
    {
      title: "Client Data",
      component: TaskDataTab,
      componentProps: { selectedDays, taskData },
    },
    {
      title: "Invoice",
      component: FinopsTab,
      componentProps: { taskData },
    },
    { title: "Collaboration", component: CollaborationChats },
  ];

  const TabPanelItems = TabMap.map((tab, index) => {
    const Component = tab.component;
    return (
      <TabPanelItem title={tab.title} key={index}>
        <div style={{ minHeight: "465px" }}>
          <Component {...tab.componentProps} />
        </div>
      </TabPanelItem>
    );
  });

  const onTabChange = (e: any) => {
    if (e.name === "selectedIndex") {
      setSelectedTabIndex(e.value);
      updatePageProperties("client-task", { selectedTabIndex: e.value });
    }
  };

  return (
    <TabPanel
      focusStateEnabled={false}
      deferRendering={false}
      animationEnabled={true}
      swipeEnabled={true}
      selectedIndex={p_selectedTabIndex || selectedTabIndex}
      onOptionChanged={onTabChange}
    >
      {TabPanelItems}
    </TabPanel>
  );
};

export default TaskSummary;
