import { useCallback, useEffect, useState } from "react";
import UserEditForm from "./userEditFrom";
import { useEditUserStatus } from "../../../hooks/admin/useUserAdministration";
import { FormPopup } from "../../../components/ui-components/form/form-popup/FormPopup";

const EditUser = ({ editPopupVisible, selectedUser, onClose, onRefetch }: any) => {
  const { mutate: editUser, isLoading } = useEditUserStatus();
  const [formData, setFormData] = useState(selectedUser || {});

  useEffect(() => {
    setFormData(selectedUser || {}); 
  }, [selectedUser]);
  

  const handleFormSubmit = useCallback(() => {
    if (formData) {
      editUser(formData, {
        onSuccess: () => {
          onRefetch(); 
          onClose();
        },
      });
    }
  }, [editUser, formData, onRefetch, onClose]);

  const updateField = useCallback((updatedData: any) => {
    setFormData((prev:any) => ({ ...prev, ...updatedData }));
  }, []);

  return (
    <FormPopup
      title="Edit User"
      visible={editPopupVisible}
      setVisible={onClose}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <UserEditForm userData={formData} updateField={updateField} />
    </FormPopup>
  );
};

export default EditUser;
