// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content-container {
  display: contents;
}

.file-container,
.telemetry-popup {
  background-color: white;
  width: 100%;
  margin-top: 20px;
  height: 85vh;
  max-height: 85vh;
}

.telemetry-title {
  font-weight: bold;
  font-size: 18px;
}

.telemetry-sub-title {
  font-weight: bold;
}

.grid-container,
.telemetry-popup .dx-treeview {
  margin-top: 20px;
}

.title-container {
  display: flex;
  align-items: center;
  font-size: 8px;
}

.title-text {
  font-size: 22px !important;
}

.title-details {
  text-align: right;
  flex: 1 1;
}

.title-detail {
  text-align: left;
  white-space: nowrap;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/telemetry.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEE;;EAEE,uBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACE;;EAEE,gBAAA;AAEJ;;AACE;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AAEJ;;AACE;EACE,0BAAA;AAEJ;;AACE;EACE,iBAAA;EACA,SAAA;AAEJ;;AACE;EACE,gBAAA;EACA,mBAAA;AAEJ;;AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAEF","sourcesContent":[".popup-content-container {\n    display: contents;\n  }\n  \n  .file-container,\n  .telemetry-popup {\n    background-color: white;\n    width: 100%;\n    margin-top: 20px;\n    height: 85vh;\n    max-height: 85vh;\n  }\n\n  .telemetry-title{\n    font-weight:bold;\n    font-size:18px;\n}\n\n.telemetry-sub-title{\n    font-weight:bold;\n}\n  .grid-container,\n  .telemetry-popup .dx-treeview {\n    margin-top: 20px;\n  }\n  \n  .title-container {\n    display: flex;\n    align-items: center;\n    font-size: 8px;\n  }\n  \n  .title-text {\n    font-size: 22px !important;\n  }\n  \n  .title-details {\n    text-align: right;\n    flex: 1;\n  }\n  \n  .title-detail {\n    text-align: left;\n    white-space: nowrap;\n  }\n\n.loader-container{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
