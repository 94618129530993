import Popup from "devextreme-react/popup";
import PsButton from "../../../../../../components/ui-components/button/Button";
import { useCallback } from "react";
import { useCreateCloneTemplate } from "../../../../../../hooks/digital-service/useTemplate";

const TemplateClone = (props: any) => {
  const { clonePopupVisible, onClonePopupClose, selectedTemplateData } = props;
  const { row } = selectedTemplateData || {};
  const { data } = row || {};
  const { template_id, template_name } = data || {};
  const { mutate } = useCreateCloneTemplate(
    onClonePopupClose,
    template_id
  );

  const handleConfirmClick = useCallback(() => {
    const newData = {
      ...data,
    };
    mutate({ template_id, payload: newData });
  }, [mutate, data, template_id]);

  return (
    <Popup
      title="Clone Template Confirmation"
      visible={clonePopupVisible}
      onHiding={onClonePopupClose}
      width="30%"
      height="240px"
    >
        <div className="task-start">
          <p>
            Are you sure you want to clone the <b>{template_name}</b> Template ?
          </p>
        </div>
        <div style={{ float: "left" }}>
        <PsButton
          onClick={handleConfirmClick}
          text="Confirm"
          type="normal"
          mode="contained"
        />
        </div>
        <div style={{ float: "right" }}>
          <PsButton
            onClick={onClonePopupClose}
            text="Cancel"
            type="normal"
            mode="contained"
          />
        </div>
    </Popup >
  );
};

export default TemplateClone;
