import { useCallback, useEffect, useState } from "react";
import TaskNewForm from './taskNewFormPopup'
import { TaskData } from "../../types";
import { newTaskFormData } from "../../types";
import { useCreateTask, useFetchProject, useFetchUrls, useFetchWorkflowTemplateDropdown } from '../../../../../hooks/task/useTask';
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { DropDownBoxTypes } from "devextreme-react/cjs/drop-down-box";
import CustomStore from "devextreme/data/custom_store";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";
import { useApp } from "../../../../../contexts/app";


export const TaskFormPopup = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;
  const [gridBoxValue, setGridBoxValue] = useState([""]);
  const {
    user: { userId, name },
  } = useApp();

  

  const [newTaskData, setNewTaskData] = useState<TaskData>({
    ...newTaskFormData,
  });

  const updateField = (field: string) => (value: any) => {
    setNewTaskData({ ...newTaskData, ...{ [field]: value } });
  };

  const { mutate,isLoading } = useCreateTask(changePopupVisibility);
  const [selectedValues, setSelectedValues] = useState<any>('');
  const { data: urlGridProp, isLoading: isUrlLoading } = useFetchUrls(selectedValues) as any;
  const { data: fetchProject } = useFetchProject(selectedValues) as any;
  const [selectedProjectType, setSelectedProjectType] = useState<any>(null);
  const { data: workflowTemplateDropdown } = useFetchWorkflowTemplateDropdown(selectedProjectType?.projectId) as any;

  useEffect(() => {
    if (!popupVisible) {
      setNewTaskData({ ...newTaskFormData });
    }
  }, [popupVisible]);
  
  const makeLocalDataSource = (localData: any[]) => {
    if (!Array.isArray(localData) || localData.length === 0 || !localData[0].hasOwnProperty("url_id")) {
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "url_id",
      load() {
        return localData;
      },
    });
  };

  const gridDataSourceMy = urlGridProp ? makeLocalDataSource(urlGridProp) : null;

  const handleFormSubmit = useCallback(() => {
    const taskDataWithUserDetails = {
      ...newTaskData,
      userId,
      userName: name,
    };
    mutate(taskDataWithUserDetails);
  }, [mutate, newTaskData, userId, name]);

  const onOptionChanged = (e: any) => {
    if (e && e.clientId) {
      setNewTaskData({ ...newTaskData, ...{ "clientId": e.clientId, "clientName": e.name, "projectId": '', "projectName": '',"clientTag":e.clientTag,"clientShortName":e.clientShortName } });
      setSelectedValues(e.clientId);
    }
  };

  const onProjectOptionChanged = (e: { projectName: string, projectId: string ,projectType: string,projectTag:any,projectShortName:any}) => {
    if (e && newTaskData.clientId) {
      setNewTaskData({ ...newTaskData, ...{ "projectName": e.projectName, "projectId": e.projectId, "projectType":e.projectType,"projectTag":e.projectTag,"projectShortName":e.projectShortName } });
      setSelectedProjectType(e);
    }
  };

  const onWorkflowOptionChanged = (e: { workflowName: string, manifestId: string}) => {
    if (e && newTaskData.projectId) {
      setNewTaskData({ ...newTaskData, ...{ "workflowName": e.workflowName, "manifestId": e.manifestId } });
    }
  };

  const onOptionMaxContainer = (e: { value: string }) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "containersPerGroup": e } });
    }
  };

  const onOptionTaskScedule = (e: any) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "scheduler": e, "taskScheduling": e.frequency } });
    }
  };

  const onOptionTaskActive = (e: string) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "status": e } });
    }
  };

  const onOptionContainerSize = (e: string) => {
    if (e) {
      setNewTaskData({ ...newTaskData, ...{ "containerSize": e } });
    }
  };

  const dataGridOnSelectionChanged = useCallback((e: DataGridTypes.SelectionChangedEvent) => {
    setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
    setNewTaskData((prevData) => ({
      ...prevData,
      "urls": e.selectedRowKeys,
      "urlList": e.selectedRowsData
    }));

  }, []);

  const syncDataGridSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    setGridBoxValue(e.value || []);
  }, []);

  if (typeof window === 'undefined') return null;

  return (
    <FormPopup
      title="Add New Task"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
      width={1200}
      height={700}
    >
      <TaskNewForm
        newTaskData={newTaskData}
        onOptionChanged={onOptionChanged}
        onOptionTaskScedule={onOptionTaskScedule}
        onOptionMaxContainer={onOptionMaxContainer}
        onOptionContainerSize={onOptionContainerSize}
        onOptionTaskActive={onOptionTaskActive}
        dataGridOnSelectionChanged={dataGridOnSelectionChanged}
        gridBoxValue={gridBoxValue}
        updateField={updateField}
        syncDataGridSelection={syncDataGridSelection}
        urlGridData={gridDataSourceMy}
        selectedProjectType={selectedProjectType}
        fetchProject={fetchProject}
        onProjectOptionChanged={onProjectOptionChanged}
        isUrlLoading={isUrlLoading}
        onWorkflowOptionChanged={onWorkflowOptionChanged}
        workflowTemplateDropdown={workflowTemplateDropdown}
      />
    </FormPopup>
  );
};


