import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import RefreshIcon from "../../../assets/icons/icon-reload-1.png";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Button as ActionsButton,
  Editing,
  Scrolling,
  Pager,
  SearchPanel,
  LoadPanel,
  Item,
  StateStoring,
} from "devextreme-react/data-grid";
import { HeaderFilter } from "devextreme-react/cjs/gantt";
import DataSource from "devextreme/data/data_source";
import { LoadOptions } from "devextreme/data";
import { PsDataGridProps } from "./helper-component/types";
import TableSkeleton from "./helper-component/TableSkeleton";
import { usePage } from "../../../contexts/pageContext";
import { Toolbar } from "devextreme-react";
import PSIconText from "../icon-with-text/IconText";
import { useLocation, useNavigate } from "react-router-dom";
import Pluse from "../../../assets/media/plus.png";
import Minus from "../../../assets/media/minus.png";

const PsDataGrid = React.memo((props: PsDataGridProps) => {
  const { pageProps, gridProps, apiProps } = props;
  const { nodataText, heightClass,id } = pageProps;

  const {
    dataSource,
    columns,
    keyExpr,
    allowedPageSizes,
    searchPanelVisible,
    headerFilterVisible,
    pagingEnabled,
    pagerEnabled,
    detailComponent,
    onRowEdit,
    onRowDelete,
    onRowClone,
    onCellPrepared,
    handleColumnIndexChange,
    handleRefetch,
    title,
    pageRoute,
    loaderEnabled
  } = gridProps;


  const { getPageProperties } = usePage();
  // const { filterValue } = getPageProperties() || {};
  // const { pageSize, pageIndex } = filterValue?.[id] || {};
  const { apiFunction, additionalParams, refreshKey } = apiProps || {};

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);
  const location = useLocation();
  const {pathname} = location;
  const pathValue = pathname.split('/')[1];
  const path = pathValue ? pathValue : "home"

  const navigate = useNavigate()


  const loaderDivRef = useRef<HTMLDivElement>(null);
  const gridDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (apiFunction === undefined) {
      // setIsDataFetched(true);
    } else {
      // setIsDataFetched(false);
    }
    setIsDataFetched(true)
    handleShowLoader();
  }, [apiFunction, refreshKey]);

  const loadData = useCallback(
    async (loadOptions: LoadOptions) => {
      const { skip = 0, take = pageSize ? pageSize : 25 , sort = [], filter = [] } = loadOptions;

      let currentSkip = 0;

      if (Array.isArray(sort) && sort.length > 0) {
        currentSkip = 0;
      }

      if (filter.length === 0 && skip === 0 && currentSkip !== 0) {
        loadOptions.skip = currentSkip;
      } else {
        currentSkip = skip;
      }

      if (skip !== currentSkip) {
        currentSkip = skip;
      }

      const page = Math.floor((skip ?? 0) / take) + 1;
      const sortArray = Array.isArray(sort)
        ? (sort as Array<{ selector: string; desc: boolean }>)
        : [];
      const sortColumn = sortArray[0]?.selector || null;
      const sortOrder =
        sortArray[0]?.desc === true
          ? "DESC"
          : sortArray[0]?.desc === false
          ? "ASC"
          : null;

      const queryParams = {
        Page: page,
        Count: take,
        sortColumn,
        sortOrder,
        ...additionalParams,
      };

      try {
        const response = await apiFunction(queryParams);
        // setIsDataFetched(true);
        const data = response?.data || [];
        const totalCount = response?.pagination?.totalRecords || data.length;

        return {
          data,
          totalCount,
        };
      } catch (error) {
        console.error("Error loading data:", error);
        // setIsDataFetched(false);
        throw error;
      }
    },
    [ additionalParams, apiFunction, pageSize]
  );

  const createDataSource = useCallback(() => {
    if (!apiFunction) {
      return new DataSource({
        store: {
          type: "array",
          data: dataSource || [],
          key: keyExpr,
        },
      });
    }

    return new DataSource({
      key: keyExpr,
      load: loadData,
    });
  }, [apiFunction, dataSource, keyExpr, loadData]);

  const finalDataSource = useMemo(() => {
    if (apiFunction === undefined) {
      // setIsDataFetched(true);
      return dataSource;
    } else {
      return createDataSource();
    }
  }, [apiFunction, dataSource, createDataSource]);

  const handleOptionChanged = (e: any) => {
    setIsDataFetched(true)
    if (e.fullName === "paging.pageSize") {
        setPageSize(e.value);
        if(pageIndex === 0){
            const gridProperties = localStorage.getItem(`GRID-${path}-${id}`)
            const gridPropertiesParsed = gridProperties ? JSON.parse(gridProperties) : null;
            if(gridPropertiesParsed?.pageIndex > 0){
                setPageIndex(gridPropertiesParsed.pageIndex)
            }
            
        } else {
            setPageIndex(0)
        }        
    } else if (e.fullName === "paging.pageIndex") {
        setPageIndex(e.value);
    }
};

  const handleContentReady = (dataSource: any) => {
    if (loaderDivRef.current) {
      loaderDivRef.current.style.display = "none";
    }
    if (gridDivRef.current) {
      gridDivRef.current.style.display = "block";
    }
  };

  const handleShowLoader = () => {
    if (loaderDivRef.current) {
      loaderDivRef.current.style.display = "block";
    }
    if (gridDivRef.current) {
      gridDivRef.current.style.display = "none";
    }
  }

  return (
    <>
      {title && pageRoute &&
        <Toolbar>
          <Item
            location="before"
            text={title}
            render={() => (
              <h6 className="underline-link"
                onClick={() => navigate(pageRoute)}
              >
                {title}
              </h6>
            )}
          />
          {!isCollapsed && 
          <Item location="after" widget="dxButton">
            <PSIconText
              src={RefreshIcon}
              alt="refresh"
              onClick={handleRefetch}
            />
          </Item>
          }

          <Item location="after" widget="dxButton">
            <PSIconText
              src={isCollapsed ? Pluse : Minus}
              alt="expand/collapse"
              onClick={() => {
                setIsCollapsed(!isCollapsed)
                if (isCollapsed) {
                  handleShowLoader();
                  // setIsDataFetched(false)
                }
              }
              }
            />
          </Item>
        </Toolbar>
      }
      <div ref={loaderDivRef}>
      {loaderEnabled !== false && !isCollapsed && <TableSkeleton />}
      </div>
      <div ref={gridDivRef}>

        {/* <div>{isDataFetched ? "" : <TableSkeleton />}</div> */}
      {!isCollapsed && 
        <DataGrid
          id={id}
          className={`grid theme-dependent ${heightClass}`}
          dataSource={finalDataSource}
          keyExpr={keyExpr}
          showBorders={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          repaintChangesOnly={true}
          noDataText={nodataText}
          remoteOperations={{ filtering: false, paging: true, sorting: true }}
          onCellPrepared={onCellPrepared}
          onOptionChanged={handleOptionChanged}
          onContentReady={() => handleContentReady(finalDataSource)}
          loadPanel={{ enabled: isDataFetched, text: "" }}
        >
          {/* {pagingEnabled && <Paging defaultPageSize={25}  pageSize={pageSize} pageIndex={pageIndex} />} */}
          <StateStoring savingTimeout={0} enabled={true} type="localStorage" storageKey={`GRID-${path}-${id}`}  />
          {pagingEnabled && <Paging pageSize={pageSize ? pageSize : 25} pageIndex={pageIndex} />}
          {pagerEnabled && (
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          )}
          {searchPanelVisible && <SearchPanel visible width={200} />}
          {headerFilterVisible && <HeaderFilter visible />}
          <Scrolling rowRenderingMode="standard" />
          {columns?.map((col, index) => (
            <Column key={index} {...col} />
          ))}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={!!onRowEdit}
            allowDeleting={!!onRowDelete}
          />
          {/* <LoadPanel showIndicator={true} showPane={true} text="" /> */}
          {onRowEdit && (
            <Column caption="" type="buttons" width={120}>
              <ActionsButton
                name="edit"
                onClick={onRowEdit}
                hint="Edit"
                cssClass="action-image"
              />
              {onRowClone && (
                <ActionsButton
                  text="Clone"
                  hint="Clone"
                  icon="copy"
                  cssClass="action-image"
                  onClick={onRowClone}
                />
              )}
              <ActionsButton
                name="delete"
                onClick={onRowDelete}
                hint="Delete"
                cssClass="action-image"
              />
            </Column>
          )}
          {detailComponent && (
            <MasterDetail enabled={true} component={detailComponent} />
          )}
        </DataGrid>
        }
      </div>
    </>
  );
});

export default PsDataGrid;
