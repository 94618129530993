import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import { editCellStatusRender } from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../config/client/commonConfig";
import { useCallback, useMemo } from "react";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import WorkflowIcon from "../../../../../assets/images/icons/icon-processing-2.png";
import TemplateLineage from "../../../../../lineageTracing/template";
import LineageViewer from "../../../../../components/ui-components/lineageView/LineageView";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import DataSource from "devextreme/data/data_source";
import { usePage } from "../../../../../contexts/pageContext";
import React from "react";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";

const TemplateGrid = React.memo((props: any) => {
  const {
    onEditTemplateClick,
    onDeletePopupOpen,
    onClonePopupOpen,
    handleRowClick,
    showPopup,
    onHandleTemplatePopupClose,
    dataTemplateJson,
    apiProps,
    isTemplateLoading,
    selectedData,
    openLineage,
    setOpenLineage,
    setSelectedData,
    selectedTemplateId
  } = props;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    template_name: template_nameColumn,
    template_function: template_functionColumn,
    status: statusColumn,
    notes: notesColumn,
    template_path: template_pathColumn,
  } = filterValue || {};

  const onHanldingLineage = useCallback(() => {
    setOpenLineage(!openLineage);
  }, [openLineage, setOpenLineage]);

  const onHandleLineageData = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  }, [onHanldingLineage, setSelectedData]);

  const renderLineageIcon = useCallback((cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  }, [onHandleLineageData]);

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: <TemplateLineage data={selectedData} />,
  };

  const TemplateFileName = useCallback((cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.template_name}
      </span>
    );
  }, [handleRowClick]);

  const columns = useMemo(() => [
    {
      dataField: "template_name",
      caption: "Template Name",
      filtervalues: template_nameColumn,
      cellRender: TemplateFileName
    },
    {
      dataField: "template_function",
      caption: "Template Types",
      filtervalues: template_functionColumn
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ContactStatus,
      editCellRender: editCellStatusRender,
      filtervalues: statusColumn,
    },
    {
      dataField: "notes",
      caption: "Note",
      filtervalues: notesColumn,
    },
    {
      dataField: "template_path",
      caption: "Template Path",
      filtervalues: template_pathColumn
    },
    {
      caption: "",
      type: "buttons",
      cellRender: renderLineageIcon,
      width: 70
    },
  ], [TemplateFileName, notesColumn, renderLineageIcon, statusColumn, template_functionColumn, template_nameColumn, template_pathColumn])

  const pageProps = useMemo(() => ({
    nodataText: "No template to display at this time",
    heightClass: "height-large",
    id: "templategrid",
  }), []);

  const gridProps = useMemo(() => ({
    dataSource: DataSource,
    columns,
    keyExpr: "id",
    allowedPageSizes,
    searchPanelVisible: true,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    onRowEdit: onEditTemplateClick,
    onRowClone: onClonePopupOpen,
    onRowDelete: onDeletePopupOpen,
  }), [columns, onClonePopupOpen, onDeletePopupOpen, onEditTemplateClick]);

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      </div>
      <div>
        <FileViewer
          visible={showPopup}
          onHiding={onHandleTemplatePopupClose}
          data={JSON?.stringify(dataTemplateJson?.blobfile_info, null, 2)}
          title={{ file: selectedTemplateId?.template_name, createdDate: selectedTemplateId?.created_at }}
          isLoading={isTemplateLoading}
          extensionType={'json'}
          isGridViewEnable={false}
        />
        <LineageViewer {...lineageDataProps} />
      </div>
    </>
  );
});

export default TemplateGrid;
