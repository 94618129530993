import { allowedPageSizes } from "../../../../../config/constants";
import { cellStartTime } from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import { useFetchManifestData } from "../../../../../hooks/task/useWorkflow";
import React, { useCallback, useMemo } from "react";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";
import { useNavigate } from "react-router-dom";
import TabView from "./tab-view/tabView";
import { useQueryClient } from "react-query";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../../contexts/pageContext";

const WorkflowGrid = (props: any) => {
  const {
    gridRef,
    height,
    openPopup,
    setOpenPopup,
    selectedWorkflowData,
    setSelectedWorkflowData,
    onEditWorkflowOpen,
    onDeletePopupOpen,
    apiProps,
    handleColumnIndexChange
  } = props;

  const queryClient = useQueryClient();
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    workflowName: workflowColumn,
    description: descriptionColumn,
    clientName: clientColumn,
    projectName: projectColumn,
    taskName: taskNameColumn,
    status: statusColumn,
    createdUser: createdUserColumn,
    createdDate: createdDateColumn
  } = filterValue || {};

  const closePopupException = useCallback(() => {
    queryClient.removeQueries("fetch-manifest-data");
    setOpenPopup(false);
  }, [setOpenPopup, queryClient]);
  const navigate = useNavigate();

  const { data: manifestData, isLoading: isManifestLoading } =
    useFetchManifestData(selectedWorkflowData?.manifestId);
  const fileViewer = JSON?.stringify(manifestData, null, 2);

  const handleRowClick = useCallback((cell: any) => {
    const { data } = cell;
    setSelectedWorkflowData(data);
    setOpenPopup(true);
  }, [setSelectedWorkflowData, setOpenPopup]);

  const renderFileName = useCallback((cell: any) => {
    return (
      <span
        className="file-name"
        style={{
          cursor: "pointer",
          color: "#0F6CBD",
          textDecoration: "underline",
        }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.workflowName}
      </span>
    );
  }, [handleRowClick]);

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const customeFileViewerProps = {
    title: selectedWorkflowData?.workflowName,
    component: <TabView data={manifestData} />,
  };

  const fileviewerTitle = {
    file: selectedWorkflowData?.workflowName,
    status: selectedWorkflowData?.status,
    createdDate: selectedWorkflowData?.createdDate,
  };

  const columns = useMemo(() => [
    {
      dataField: "workflowName",
      caption: "Workflow Name",
      cellRender: renderFileName,
      filterValues: workflowColumn
    },
    {
      dataField: "description",
      caption: "Description",
      filterValues: descriptionColumn
    },
    {
      dataField: "clientName",
      caption: "Client",
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleClientLinkClick(e, data?.data?.clientId)}
        >
          {data?.data?.clientName}
        </a>
      ),
      filterValues: clientColumn
    },
    {
      dataField: "projectName",
      caption: "Project Name",
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId)}
        >
          {data?.data?.projectName}
        </a>
      ),
      filterValues: projectColumn
    },
    {
      dataField: "taskName",
      caption: "Task Name",
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleTaskLinkClick(e, data?.data?.taskId)}
        >
          {data?.data?.taskName}
        </a>
      ),
      filterValues: taskNameColumn
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ContactStatus,
      filterValues: statusColumn
    },
    {
      dataField: "createdUser",
      caption: "Created By",
      filterValues: createdUserColumn
    },
    {
      dataField: "createdDate",
      caption: "Timestamp",
      cellRender: cellStartTime,
      filterValues: createdDateColumn
    }
  ], [renderFileName, workflowColumn, descriptionColumn, clientColumn, projectColumn, taskNameColumn, statusColumn, createdUserColumn, createdDateColumn, handleClientLinkClick, handleProjectLinkClick, handleTaskLinkClick]);

  const pageProps = useMemo(() => ({
    nodataText: "No workflow to display at this time",
    heightClass: height,
    id: "workflowGrid",
  }), [height]);

  const gridProps = useMemo(() => ({
    dataSource: DataSource,
    columns: columns,
    keyExpr: "manifestId",
    allowedPageSizes: allowedPageSizes,
    searchPanelVisible: true,
    grifRef: gridRef,
    headerFilterVisible: true,
    pagerEnabled: true,
    pagingEnabled: true,
    onRowEdit: onEditWorkflowOpen,
    onRowDelete: onDeletePopupOpen,
    handleColumnIndexChange,
  }), [columns, gridRef, handleColumnIndexChange, onDeletePopupOpen, onEditWorkflowOpen]);

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <FileViewer
        key={"file-viewer"}
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewer}
        title={fileviewerTitle}
        isLoading={isManifestLoading}
        extensionType={"json"}
        type='custom-component'
        customComponentData={customeFileViewerProps}
      />
    </div>
  );
};

export default WorkflowGrid;
