import { useState } from "react";
import "../../../styles/client/client-profile.scss";
import { useCallback, useMemo } from "react";
import ClientProfileTab from "./helper-component/clientProfileTab";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { Item } from "devextreme-react/toolbar";
import ClientInfo from "../projects/helper-component/clientInformation/clientInfoV2";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import {
  useFetchProjectClientInfo,
  useFetchSpecificClient,
} from "../../../hooks/client/useClientProfile";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { customDateRange } from "../../../config/constants";
import { Projects } from "../projects/projects";

export const ClientProfile = () => {
  const { data: DateRange } = useFetchDateRange();
  const customerId = useHelperHooks().getQueryParams("clientId");
  const projectId = useHelperHooks().getQueryParams("projectId");
  const pageCount = 1
  const pageSize = 25
  const sortColumn = 'name'
  const sortOrder = 'asc'

  const { data: customerDetails, isFetching, refetch } = useFetchSpecificClient(
    customerId as string
  );

  const title = customerDetails
    ? `Client 360 - ${customerDetails[0]?.name}`
    : "Client 360";

    const 
    {state:{selectedDays},
    handleDateRangeChange
  } = useAppHook()

  const DataGridProps = useMemo(() => {
    return {
      clientId: customerId,
      projectId,
      PagerVisible: true,
      toolbarVisible: true,
      selectedDays,
      showToolbar: false,
      refetch,
      isFetching,
    };
  }, [customerId,  projectId, selectedDays, refetch, isFetching]);

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} />
    </Item>
  );

  const pageBodyComponent = () => [
    {
      title: "",
      Component: ClientProfileTab,
      componentProps: DataGridProps,
      reloadParams: ['fetch-client-byId', 'fetch-client-document-list', 'fetch-collaboration', 'fetch-invoice-info', 'fetch-ytdmtd-cost', 'fetch-task-details', 'fetch-finops-cost']
    },
    {
      title: "Projects",
      Component: Projects,
      componentProps: {
        showToolbar: false,
        filteredClientId: customerId,
        selectedDays: selectedDays,
        height: "height-medium",
        title: "Project Details",
        pageRoute: "/projects"

      },
      pageRoute: "/projects",
      componentId: "projects",
      reloadParams: ['fetch-project-clientInfo', 'fetch-projectInfo'],
      titleToolBar: true,
      hideHeader: true
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: { showToolbar: false, height: "height-medium", selectedDays: selectedDays, filteredClientId : customerId, onCurrentValueChange: handleDateRangeChange,
      title: "Recent Activity",
      pageRoute: "/activity-dashboard",  
       },
      pageRoute: "/activity-dashboard",
      componentId: "activitydashboard",
      reloadParams: 'fetch-recent-activity',
      titleToolBar: true,
      hideHeader: true
    },
    {
      title: "Alerts & Exceptions",
      Component: AlertExceptionPage,
      componentProps: { showToolbar: false, height: "height-medium", selectedDays: selectedDays, filteredClientId : customerId, onCurrentValueChange: handleDateRangeChange,
      title: "Alerts & Exceptions",
      pageRoute: "/alert-exceptions", 
    },
      pageRoute: "/alert-exceptions",
      componentId: "alertsexceptions",
      reloadParams: 'fetch-alert-Exception',
      titleToolBar: true,
      hideHeader: true,
      
    },
  ];


  const LayoutProps = {
    headerText: title,
    additionalToolbarContent,
    isLoading: isFetching,
    showBackButton: true,
    pageBodyComponent: pageBodyComponent(),
  };

  return (
    <>
      <DataGridTitleCardLayout {...LayoutProps} />
    </>
  );
};
