import PsButton from "../../ui-components/button/Button";
import MyFilesView from "../../../assets/icons/icon-eye.png";
import { allowedPageSizes } from "../../../config/client/commonConfig";
import { cellStartTime } from "../../ui-components/dataGridFormat/dataGridFormat";
import PsDataGrid from "../../ui-components/dataGrid/DataGrid";
import React, { useCallback, useMemo } from "react";
import DataSource from "devextreme/data/data_source";
import { customDateRange } from "../../../config/constants";
import { fetchClientIntegrationData, fetchTaskData } from "../../../service/app/api-service";
import { usePage } from "../../../contexts/pageContext";
import MapfileEdit from "../../../pages/datastudio/dataCatalog/helper-component/mapfile-edit/mapFileEdit";
import { useDataCatalog } from "../../../hooks/datastudio/useDataCatalog";

export const ReferenceComponent = React.memo((props: any) => {

  const { onViewIntegrationFileClick, id, projectShortName, handleColumnIndexChange } = props;
  const { projectId, taskId } = id || {};
  const {
    state: {
      popupVisible,
      selectedData,
      updatedData
    },
    setPopupOpen,
    setPopupClose,
    setUpdatedData
  } = useDataCatalog();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    fileName: filenameColumn,
    createdDate: createdDateColumn,
    fileSize: fileSizeColumn,
    processType: processTypeColumn,
  } = filterValue || {};

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      ...(taskId
        ? { taskId }
        : { projectId, projectShortName }),
      timePeriod,
      ...(timePeriod === customDateRange && { startTime, endTime }),
    };
  }, [projectId, taskId, filterValue, projectShortName]);

  const apiProps = useMemo(() => ({
    apiFunction: taskId ? fetchTaskData : fetchClientIntegrationData,
    additionalParams: additionalParams,
  }), [additionalParams, taskId]);

  const editProps = useMemo(() => {
    return {
      selectedData,
      popupVisible,
      setPopupClose,
      updatedData,
      setUpdatedData
    }
  }, [
    selectedData,
    popupVisible,
    setPopupClose,
    updatedData,
    setUpdatedData
  ]);

  const renderAction = useCallback((cell: any) => {
    return (
      <PsButton
        hint="MyFiles"
        icon={MyFilesView}
        mode="text"
        onClick={(e) => onViewIntegrationFileClick(e, cell)}
        eventName="reference data files click event"
      />
    );
  }, [onViewIntegrationFileClick]);

  const columns = useMemo(() => [
    {
      caption: "File Name",
      dataField: "fileName",
      dataType: "string",
      filterValues: filenameColumn,
    },
    {
      caption: "Asset Type",
      dataField: "processType",
      dataType: "string",
      filterValues: processTypeColumn,
    },
    {
      caption: "Uploaded on",
      dataField: "createdDate",
      dataType: "string",
      cellRender: cellStartTime,
      filterValues: createdDateColumn,
    },
    {
      caption: "File Size",
      dataField: "fileSize",
      dataType: "string",
      filterValues: fileSizeColumn,
    },
    {
      caption: "",
      type: "buttons",
      width: 180,
      cellRender: renderAction
    },
  ], [filenameColumn, processTypeColumn, createdDateColumn, fileSizeColumn, renderAction]);

  const pageProps = useMemo(() => ({
    nodataText: "No Results to display at this time",
    heightClass: "height-small",
    id: "datalakegrid",
  }), []);

  const gridProps = useMemo(() => ({
    dataSource: DataSource,
    columns,
    allowedPageSizes,
    searchPanelVisible: false,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    handleColumnIndexChange,
    onRowEdit: setPopupOpen
  }), [columns, handleColumnIndexChange, setPopupOpen]);

  return (
    <>
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <MapfileEdit {...editProps} />
    </>
  );
});
