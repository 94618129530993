import {
  fetchTaskDescriptionApi,
  createTaskApi,
  updateTaskApi,
  fetchImageApi,
  StartProgressApi,
  deleteTaskById,
  fetchTaskDetailApi,
  fetchSpecificProjectDetailsApi,
  fetchResouceDetailsApi,
  fetchUrlsApi,
  createCloneTaskApi,
  fetchtTaskData,
  fetchTaskDataList,
  fetchWorkflowTemplateDropdown,
} from "../../service/task/api-service";
import { useCallback, useReducer } from "react";
import { TaskState } from "../../pages/task/tasksAutomation/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHelperHooks } from "../helper/utils";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { formatDate } from "devextreme/localization";

const initialState: TaskState = {
  popupVisible: false,
  taskId: 0,
  selectedTask: null,
  deletePopupVisible: false,
  startPopupVisible: false,
  editPopupVisible: false,
  clonePopupVisible: false,
  fileViewerPopupVisible: false,
  selectedFileData: null,
  pageCount: 1,
  pageSize: 25,
  pageIndex: [0],
  isNextPage: false,
  sortOrder: "",
  sortColumn: "",
};

const reducer = (state: TaskState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload,
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload,
      };
    case "SELECTED_TASK":
      return {
        ...state,
        selectedTask: action.payload,
      };
    case "SELECTED_CLIENT_DATA":
      return {
        ...state,
        selectedClientData: action.payload,
      };
    case "START_POPUP_VISIBLE":
      return {
        ...state,
        startPopupVisible: action.payload,
      };
    case "CLONE_POPUP_VISIBLE":
      return {
        ...state,
        clonePopupVisible: action.payload,
      };
    case "SHOW_FILE_VIEWER_POPUP":
      return {
        ...state,
        fileViewerPopupVisible: action.payload,
      };
    case "SELECTED_FILE_DATA":
      return {
        ...state,
        selectedFileData: action.payload,
      };
    case "PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      };

    case "PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload,
      };
    case "IS_NEXTPAGE":
      return {
        ...state,
        isNextPage: action.payload,
      };
    case "PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SORTING_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };
    case "SORTING_COLUMN":
      return {
        ...state,
        sortColumn: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useTask = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddTaskClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []);

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectTask = (data: any) => {
    dispatch({ type: "SELECTED_TASK", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []);

  const onStartPopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "START_POPUP_VISIBLE", payload: true });
  }, []);

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
  };

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []);

  const onStartPopupClose = useCallback(() => {
    dispatch({ type: "START_POPUP_VISIBLE", payload: false });
  }, []);

  const onEditTaskClick = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "SELECTED_TASK", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: false });
    },
    []
  ); //used

  const onClonePopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: true });
  }, []);

  const onClonePopupClose = useCallback(() => {
    dispatch({ type: "CLONE_POPUP_VISIBLE", payload: false });
  }, []);

  const onEditClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_TASK", payload: data });
    dispatch({ type: "IS_PANEL_OPENED", payload: true });
  }, []);

  const onViewScrapingButtonClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );
  const handleDropDownPageIndex = useCallback(() => {
    dispatch({ type: "IS_NEXTPAGE", payload: false });
    dispatch({
      type: "PAGE_INDEX",
      payload: [0],
    });
  }, []);

  const handlePageIndexChange = useCallback(
    (
      e: {
        fullName: string;
        value: number;
        component: any;
      },
    ) => {
      if (e.fullName === "paging.pageIndex") {
        dispatch({
          type: "PAGE_INDEX",
          payload: [...state.pageIndex, e.value],
        });
        const isNextPage = state.pageIndex?.includes(e.value);
        dispatch({ type: "IS_NEXTPAGE", payload: isNextPage });
      } else if (e.fullName === "paging.pageSize") {
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        const pageSize = e.value;
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "PAGE_SIZE",
          payload: pageSize,
        });
      } else if (
        e.fullName.startsWith("columns[") &&
        e.fullName.endsWith("].sortOrder")
      ) {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        const sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "SORTING_ORDER",
          payload: sortOrder,
        });
        dispatch({
          type: "SORTING_COLUMN",
          payload: sortColumn,
        });
      }
    },
    [state.sortOrder, state.pageIndex, state.pageCount,state.isNextPage]
  );

  return {
    state,
    onAddTaskClick,
    changePopupVisibility,
    handleSelectClient,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onStartPopupClose,
    onEditClick,
    onEditTaskClick,
    onClonePopupOpen,
    onClonePopupClose,
    changeEditPopupVisibility,
    onViewScrapingButtonClick,
    handleDropDownPageIndex,
    handlePageIndexChange,
  };
};

export const useFetchTask = (
  clientId: any,
  projectId: any,
  selectedDays: any,
  status : any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage:boolean
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length -1] + 1;
  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    status,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  };
  return useQuery(
    ["fetch-task", queryParams],
    () => fetchTaskDescriptionApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchTaskDetails = () => {
  const taskId = useHelperHooks().getQueryParams("taskId");
  const QueryClient = useQueryClient();
  return useQuery(
    ["fetch-task-detail", taskId],
    () => fetchTaskDetailApi(taskId),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskId,
      onSuccess: (data: any) => {
        QueryClient.setQueryData("task-details", data);
      },
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useCreateTask = (
  toggleClientModal: () => void,
  isEdit?: boolean,
  userId?: string,
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? 
   (data: any) => updateTaskApi(data, userId)
  : createTaskApi;
  const message = isEdit
    ? "Task updated successfully"
    : "New task added successfully";
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-task");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateCloneTask = (
  toggleClientModal: () => void,
  taskId: string
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = async (params: any) => {
    const type = "clone";
    const { payload } = params;
    const payloadWithCloneType = { ...payload };
    const apiFunc = createCloneTaskApi(type, taskId, payloadWithCloneType);
    return await apiFunc;
  };
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-task");
      showNotification({
        message: "Task cloned successfully",
      });
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
  });
};

export const useFetchImage = () => {
  return useQuery("fetch-url-image", fetchImageApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          label: item.clientName,
          imageName: item.imageName,
          postProcessorModule: item.postProcessorModule,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useStartProgress = (
  taskId: string,
  userId: string,
  name: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["start-progress", taskId, userId, name],
    () => StartProgressApi(taskId, userId, name),
    {
      enabled: !!taskId && !!userId && !!name && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        queryClient.invalidateQueries("fetch-recent-activity");
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useDeleteTask = (
  onDeleteHandler: () => void,
  taskId: string,
  taskName: string,
  userId: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteTask", taskId, userId],
    () => deleteTaskById(taskId, userId),
    {
      enabled: !!taskId && !!userId && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-task");
        showNotification({
          message: `${taskName} Deleted Successfully`,
        });
      },
    }
  );
};

const fetchProjectDetailsApi = async (id: any) => {
  const response: any = await fetchSpecificProjectDetailsApi(id);
  if (response) {
    return response;
  }
};

export const useFetchProject = (id: string) => {
  return useQuery(["fetch-project", id], () => fetchProjectDetailsApi(id), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchResouce = (type: string) => {
  return useQuery(
    ["fetch-resource", type],
    () => fetchResouceDetailsApi(type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchUrls(_id: string) {
  return useQuery(["fetch-url", _id], () => fetchUrlsApi(_id), {
    enabled: !!_id,
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          ...item,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}

export function useFetchTaskDataList(taskIds: any) {
  // const queryParams = {
  //   executionId: JSON.stringify(executionIds),
  //   taskId: JSON.stringify(taskIds),
  // };
  return useQuery(
    ["fetch-task-webscraping-list", taskIds],
    () => fetchTaskDataList(taskIds),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskIds,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchSelectedTaskFileData(data: any) {
  const { projectId, fileExtension, filename, type, createdDate } = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
    createdDate: createdDate,
  };
  return useQuery(
    ["fetch-project-data", queryParams],
    () => fetchtTaskData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type && !!createdDate,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const transformTaskStorageData = (data: any, taskName: string) => {
  const result: {
    id: string;
    text: string;
    expanded: boolean;
    items: any[];
  }[] = [
    {
      id: "1",
      text: `Polaris-data-lake (${taskName})`,
      expanded: true,
      items: [],
    },
  ];

  let categoryId = 1;

  Object?.keys(data)?.forEach((category) => {
    const categoryData = data[category] || [];

    const categoryNode = {
      id: `1_${categoryId}`,
      text: category,
      expanded: false,
      items: [
        {
          id: `1_${categoryId}_1`,
          text: "",
          expanded: false,
          items: [],
          data: categoryData,
        },
      ],
    };

    result[0]?.items?.push(categoryNode);
    categoryId++;
  });

  return result;
};

export const useFetchWorkflowTemplateDropdown = (projectId: string) => {
  return useQuery(
    ["fetch-workflow-template-dropdown", projectId],
    () => fetchWorkflowTemplateDropdown(projectId),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchSpecificTask = (taskId: string) => {
  return useQuery(
    ["fetch-task-detail", taskId],
    () => fetchTaskDetailApi(taskId),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
