import FileManager, {
  Permissions,
  ItemView,
  FileManagerTypes,
  IItemViewProps,
} from "devextreme-react/file-manager";
import "../../../styles/ui-components/FileManager.scss";
import { useCallback, useState } from "react";
import FileViewer from "../../ui-components/fileViewer/FileViewer";
import { LoadPanel } from 'devextreme-react/load-panel';

const PSFileManager = (props: any) => {
  const { data, isFetching, onHandleFileView, fileviewData, extension, isSingleFileloading, setSelectedDirectory, selectedFile } = props;

  const [itemViewMode, setItemViewMode] =
    useState<IItemViewProps["mode"]>("thumbnails");
  const [popupVisible, setPopupVisible] = useState(false);

  const onOptionChanged = useCallback(
    (e: FileManagerTypes.OptionChangedEvent) => {
      if (e?.fullName === "itemView.mode") {
        setItemViewMode(e?.value);
      }
    },
    [setItemViewMode]
  );

  const displayImagePopup = useCallback(
    (e: FileManagerTypes.SelectedFileOpenedEvent) => {
      setPopupVisible(true);
      onHandleFileView(e?.file?.dataItem);
    },
    [onHandleFileView]
  );

  const hideImagePopup = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  const DirectoryChanged = useCallback(
    (e: FileManagerTypes.CurrentDirectoryChangedEvent) => {
      const pathKeys = e?.directory?.pathKeys;
      const module = pathKeys?.[0];
      const namePath = pathKeys?.[1];
      const name = namePath?.split("/")?.[1];
      const locationPath = pathKeys?.[2];
      const location = locationPath?.split("/")?.[2];
      const projectPath = pathKeys?.[3];
      const project = projectPath?.split("/")?.[3];
      const yearPath = pathKeys?.[4];
      const year = yearPath?.split("/")?.[4];
      const params = {
        module: module,
        name: name,
        location: location,
        projectname: project,
        year: year,
      };
      setSelectedDirectory(params);
    },
    [setSelectedDirectory]
  );

  // Customize Detail Columns
  const customizeDetailColumns = (columns: any) => {
    // Remove the default 'dateModified' and 'size' columns
    const modifiedColumns = columns?.filter(
      (column: any) => column?.dataField !== "dateModified" && column?.dataField !== "size"
    );

    modifiedColumns?.push(
      {
        dataField: "createdDate",
        caption: "Created Date",
        dataType: "date",
        width: 200,
      },
      {
        dataField: "size",
        caption: "File Size",
        dataType: "string",
        width: 100,
      }
    );

    return modifiedColumns;
  };

  return (
    <>
      <FileManager
        fileSystemProvider={data}
        height={`calc(100vh - 200px)`}
        onOptionChanged={onOptionChanged}
        onSelectedFileOpened={displayImagePopup}
        customizeDetailColumns={customizeDetailColumns}
        onCurrentDirectoryChanged={DirectoryChanged}
        className="file-manager"
      >
        <ItemView mode={itemViewMode} />
        <Permissions
          create={true}
          copy={true}
          move={true}
          delete={true}
          rename={true}
          upload={true}
          download={true}
        />
      </FileManager>
      <LoadPanel
        visible={isFetching}
        position={{ of: '.dx-filemanager-files-view' }}
        showIndicator={false}
        showPane={false}
        className="file-manager-loader"
      />
      <FileViewer
        visible={popupVisible}
        onHiding={hideImagePopup}
        data={fileviewData}
        title={{
          file: selectedFile?.name,
          createdDate: selectedFile?.createdDate
        }}
        isLoading={isSingleFileloading}
        extensionType={extension}
      />
    </>
  );
};

export default PSFileManager;
