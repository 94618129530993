import OpportunitiesTicker from "./OpportunitiesTicker";
import { useFetchActiveDocument } from "../../../../../hooks/datastudio/useDocumentCatalog";
import "../../../../../styles/datastudio/ManagementView.scss";

const ActiveDocuments = (props: any) => {
  const { selectedDays, handleStatusChange } = props;
  const { data: activeDocument } = useFetchActiveDocument(selectedDays) as any;

  const parseAndValidateInt = (value: any) => {
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  return (
    <div className="cards compact" style={{ columnGap: "-10px", marginTop: "5px", marginLeft: "-10px" }}>
      <OpportunitiesTicker
        title="Pending"
        onClick={ () => handleStatusChange("Pending")}
        value={parseAndValidateInt(
          activeDocument?.summary?.Pending
        )}
        colur="black"
      />
      <OpportunitiesTicker
        title="Completed"
        onClick={ () => handleStatusChange("Completed")}
        value={parseAndValidateInt(
          activeDocument?.summary?.Completed
        )}
        colur="green"
      />
      <OpportunitiesTicker
        title="Failed"
        onClick={ () => handleStatusChange("Failed")}
        value={parseAndValidateInt(
          activeDocument?.summary?.Failed
        )}
        colur="red"
      />
      <OpportunitiesTicker
        title="Ready For Review"
        onClick={ () => handleStatusChange("Ready for Review")}
        value={parseAndValidateInt(
          activeDocument?.summary?.["Ready for Review"]
        )}
        colur="grey"
      />
      <OpportunitiesTicker
        title="In Progress"
        onClick={ () => handleStatusChange("In Progress")}
        value={parseAndValidateInt(
          activeDocument?.summary?.["In Progress"]
        )}
        colur="blue"
      />
      <OpportunitiesTicker
        title="Rejected"
        onClick={ () => handleStatusChange("Rejected")}
        value={parseAndValidateInt(
          activeDocument?.summary?.Rejected
        )}
        colur="red"
      />
    </div>
  );
};

export default ActiveDocuments;