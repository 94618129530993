import { Client } from '../pages/client/client-catalog/types';
import { ClientUrl } from '../pages/client/url-catalog/types';
import { jsPDF as JsPdf } from "jspdf";
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import { DataGridTypes } from "devextreme-react/data-grid";
import { Template } from '../pages/digital-service/webScraping/template-library/types';
import { ProjectData } from '../types/project-add';
import { DigitalServicesData } from '../types/digital-services-add';
import { OperationsData } from '../types/operations-add';
import { InfrastructureData } from '../types/infrastructure-add';


export const ANALYTICS_PERIODS:any = {
  Week: {
    period: '2020-01-24/2020-01-31',
    index: 0,
  },
  '2 Weeks': {
    period: '2020-01-14/2020-01-31',
    index: 1,
  },
  Month: {
    period: '2020-01-01/2020-02-01',
    index: 2,
  },
  Year: {
    period: '2020-01-01/2021-01-01',
    index: 3,
  },
  All: {
    period: '2018-01-01/2022-01-01',
    index: 4,
  },
};

export const newInfrastructureFormData: InfrastructureData = {
  resource: '',
  id: '',
  resource_name:'', 
  resource_type:'',
  cost_per_day:'',
  type:'',
};

export const newDigitalFormData: DigitalServicesData = {
  id: '',
  designation:'',
  description:'',
  cost_per_day:'',
};

export const chartData = {
  "dataProcessingSummary": [
      {
          "clientName": "Rental Beast",
          "tasks": 10,
          "totalDataProcessed": 100,
          "exceptions": 60
      },
      {
          "clientName": "Discovery Data",
          "task": 9,
          "totalDataProcessed": 60,
          "exceptions": 0
      },
      {
          "clientName": "Velocity",
          "task": 5,
          "totalDataProcessed": 5,
          "exceptions": 5
      }
  ]
}


export const newOperationsFormData: OperationsData = {
  id: '',
  designation:'',
  description:'',
  cost_per_day:'',
};

export const DEFAULT_ANALYTICS_PERIOD_KEY = 'All';

export const DEFAULT_ANALYTICT_KEY = [
  // "This Week",
  "This Month",
]

export const DEFAULT_ANALYTICT_VALUE = {
  "This Week":"thisWeek",
  "This Month":"thisMonth"
  }

export const newTemplate: Template = {
  template_id: '',
  template_name: '',
  template_function: '',
  status: '',
  notes: '',
  template_path: '',
  file: new File([''], ''),
};

export const PRIORITY_ITEMS = ['Low', 'Normal', 'High'];

export const STATUS_ITEMS = ['Open', 'In Progress', 'Deferred', 'Completed'];

export const STATUS_LIST = [
  'Active',
  'InActive'
];

export const URL_STATUS = ['Pending', 'Development', 'QA', 'Production'];

export const newContact: Client = {
  firstName: '',
  lastName: '',
  status: 'Active',
  city: '',
  state: {
    stateShort: '',
  },
  phone: '',
  email: '',
  note: '',
  primary_contact: '',
  primary_email: '',
  address1: '',
  address2: '',
  important_links_and_urls: '',
  specialInstructions: '',
  technology_integration_requirements: '',
  kpis: '',
  keyMetrics_and_reporting_frequency: '',
  name: '',
  clientShortName:'',
};

export const newClientUrl: ClientUrl = {
  url: '',
  note: '',
  status: 'Pending',
  url_template_path: '',
  url_keyword: '',
  url_file_name: '',
  driver_wait_time: '',
  complexity: '',
  clientName: '',
  clientId: '',
};

export const newProjectFormData: ProjectData = {
  projectId: '',
  clientId: '',
  projectDescription: '',
  status: '',
  projectName: '',
  clientName: '',
  startDate: new Date(),
  endDate: new Date(),
  technicalResource:[],
  operationalResource:[],
  infraResources:[],

};


export const CONTAINER_SCHEDULE = ["Daily", "Weekly", "Monthly", "Ad-hoc"]

export const statusItems = ['Active', 'Pending', 'Disabled'];

export const CONTAINER_VALUE = ["1", "2", "3", "4"]

export const CONTAINER_SIZE = ["Small", "Medium", "Large"]

export const TASK_STATUS = ["Active", "InActive"]

export const DATA_TIRE =["Bronze", "Silver", "Gold"]

export const ACTION_TYPE = ["Copy"]

export const simpleProductLabel = { 'aria-label': 'Simple Product' };

export const dropDownOptions = { width: "auto", maxHeight: 300 };

export const customDateRange = "CustomDateRange";

export const allClients = "All Clients"

export const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === "pdf") {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save("Contacts.pdf");
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Contacts");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Contacts.xlsx"
        );
      });
    });
    e.cancel = true;
  }
};

export const FREQUENCY_LIST = [
  'thisWeek',
  'thisMonth',
  'specificDate'
];

export const filterFrequenctList = ["thisMonth", ...FREQUENCY_LIST];

export const resourceTypeOptions = ["Small", "Medium", "Large", "Standard"];

export const typeOptions = ["Standard", "Template"];

export const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
  25,
  50,
  100
];

export const pathMapping: any = {
  Bronze: "inputPath",
  Silver: "processPath",
  Gold: "outputPath",
};

export const processStatus = ["In Progress", "Completed", "Failed"];
