import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchDataCatalogGridApi, updateMapFileApi } from '../../service/datastudio/api-service';
import { formatDate } from 'devextreme/localization';
import { useCallback, useReducer } from 'react';
import showNotification from '../../components/ui-components/alertPopup/AlertPopup';

export interface MapFileState {
  popupVisible: boolean
  selectedData: any
  filesData: any
  openPopup: boolean
  refreshKey: any
  openLineage: boolean
  updatedData: any
}

const initialState: MapFileState = {
  popupVisible: false,
  selectedData: {},
  filesData: {},
  openPopup: false,
  refreshKey: 0,
  openLineage: false,
  updatedData: {}
};

const reducer = (state: MapFileState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      }
    case "SELECTED_DATA":
      return {
        ...state,
        selectedData: action.payload,
      };
    case "FILES_DATA":
      return {
        ...state,
        filesData: action.payload,
      };
    case "OPEN_POPUP":
      return {
        ...state,
        openPopup: action.payload,
      };
    case "REFRESH_KEY":
      return {
        ...state,
        refreshKey: action.payload,
      };
    case "OPEN_LINEAGE":
      return {
        ...state,
        openLineage: action.payload,
      };
    case "UPDATED_DATA":
      return {
        ...state,
        updatedData: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDataCatalog = () => {
  
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSelectedData = useCallback((data: any) => {
    dispatch({ type: "SELECTED_DATA", payload: data });
  }, []);

  const setPopupOpen = useCallback((data: any) => {
    setSelectedData(data);
    dispatch({ type: "POPUP_VISIBLE", payload: true })
  }, [setSelectedData]);

  const setPopupClose = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: false })
  }, []);

  const setFilesData = useCallback((data: any) => {
    dispatch({ type: "FILES_DATA", payload: data });
  }, []);

  const setOpenPopup = useCallback((data: any) => {
    dispatch({ type: "OPEN_POPUP", payload: data });
  }, []);

  const setRefreshKey = useCallback((refreshKey: any) => {
    dispatch({ type: "REFRESH_KEY", payload: refreshKey + 1 });
  }, []);

  const setOpenLineage = useCallback((data: any) => {
    dispatch({ type: "OPEN_LINEAGE", payload: data });
  }, []);

  const setUpdatedData = useCallback((data: any) => {
    dispatch({ type: "UPDATED_DATA", payload: data });
  }, []);

  return {
    state,
    setPopupOpen,
    setPopupClose,
    setSelectedData,
    setFilesData,
    setOpenPopup,
    setRefreshKey,
    setOpenLineage,
    setUpdatedData
  };
};

export const useFetchDataCatalogGridApi = (
  selectedDays: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: any,
) => {
  const { startTime, endTime, timePeriod } = selectedDays;
  const formattedStartTime = startTime ? formatDate(startTime, "yyyy-MM-dd") : null;
  const formattedEndTime = endTime ? formatDate(endTime, "yyyy-MM-dd") : null;
  const page = pageIndex[pageIndex.length - 1] + 1;

  const queryParams = {
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    timePeriod,
    Count,
    sortOrder,
    sortColumn,
    Page: page,
  };

  return useQuery(
    ["fetch-datacatalog-data", queryParams],
    () => fetchDataCatalogGridApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const list = data?.data || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return { list, totalcount };
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};

export const useUpdateMapFile = (closePanel: () => void) => {
  const fnQueryClient = useQueryClient();
  return useMutation(updateMapFileApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.response || "Edited successfully",
      });
      closePanel();
      fnQueryClient.invalidateQueries("fetch-datacatalog-data");
    },
    onError: (error: Error) => {
      showNotification({
        message: "Edit failed",
        type: "error",
      });
    },
  });
};
