import {
  fetchProjectClientInfoApi,
  fetchYTDMTDCostApi,
  fetchTaskDetailsApi,
  fetchSpecificClientApi,
  fetchProjectData,
  fetchClientDataList,
  fetchProjectApi,
  fetchClientIntegrationList,
} from "../../service/client/api-service";
import { useQuery } from "react-query";
import { useHelperHooks } from "../helper/utils";
import { fetchRecentActivityAPI } from "../../service/app/api-service";
import { useCallback, useReducer } from "react";
import {
  ClientDataState,
  initialState,
} from "../../pages/client/client-profile/types";
import { formatDate } from "devextreme/localization";

const reducer = (state: ClientDataState, action: any) => {
  switch (action.type) {
    case "SHOW_FILE_VIEWER_POPUP":
      return {
        ...state,
        fileViewerPopupVisible: action.payload,
      };
    case "SELECTED_FILE_DATA":
      return {
        ...state,
        selectedFileData: action.payload,
      };
    case "CLEAR":
      return initialState;

    default:
      return state;
  }
};

export const useClientProfileData = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onViewScrapingButtonClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );

  const onViewIntegrationFileClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );

  return {
    state,
    onViewScrapingButtonClick,
    onViewIntegrationFileClick
  };
};

export const useFetchClientProjectInfo = (clientId: string) => {
  return useQuery(
    ["fetch-project-clientInfo", clientId],
    () => fetchProjectClientInfoApi(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchYTDMTDCost() {
  const clientId = useHelperHooks().getQueryParams("clientId");
  const frequency = "YTD,MTD";
  const queryParams = {
    clientId: clientId,
    frequency: frequency,
  };
  return useQuery(
    ["fetch-ytdmtd-cost", queryParams],
    () => fetchYTDMTDCostApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchTaskDetails = () => {
  const taskId = useHelperHooks().getQueryParams("taskId");
  return useQuery(
    ["fetch-task-details", taskId],
    () => fetchTaskDetailsApi(taskId),
    {
      refetchOnWindowFocus: false,
      enabled: !!taskId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchSpecificClient = (clientId: string) => {
  return useQuery(
    ["fetch-client-byId", clientId],
    () => fetchSpecificClientApi(clientId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
const fetchClientTaskExecution = async ({ pageParam }: any, data: any) => {
  const queryParams = {
    type: "clientexecutionList",
    clientId: data.clientId,
  };

  const response: any = await fetchRecentActivityAPI(queryParams);
  if (response) {
    return response;
  }
};

export const useFetchClientExecutionTask = (fetchProps: any) => {
  return useQuery(
    ["fetch-task-execution", fetchProps],
    ({ pageParam = 1 }) => fetchClientTaskExecution({ pageParam }, fetchProps),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastPage: any, pages: any) => {
        if (pages.length < 100) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      select: (data: any) => {
        const updatedData = data?.map((item: any) => {
          return {
            ...item,
            name: item?.clientName,
          };
        });
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchClientDataList(clientId: string) {
  return useQuery(
    ["fetch-client-document-list", clientId],
    () => fetchClientDataList(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchSelectedClientFileData(data: any) {
  const { projectId, fileExtension, filename, type, createdDate } = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
    createdDate: createdDate,
  };
  return useQuery(
    ["fetch-client-document", queryParams],
    () => fetchProjectData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type && !!createdDate,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}


export const useFetchProjectClientInfo = (clientId:any, projectId:any, selectedDays:any, pageCount:any, pageSize:any, sortColumn:any, sortOrder:any) => {
  const startTime = selectedDays?.startTime ? formatDate(selectedDays.startTime, "yyyy-MM-dd") : null;
  const endTime = selectedDays?.endTime ? formatDate(selectedDays.endTime, "yyyy-MM-dd") : null;
  const timePeriod = selectedDays?.timePeriod;

  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    pageCount,
    pageSize,
    sortColumn,
    sortOrder
  };

  return useQuery(
    ["fetch-project-clientInfo", clientId, projectId, selectedDays, pageCount, pageSize, sortColumn, sortOrder],
    () => fetchProjectApi(queryParams),
    {
      select: (data) => {
        return data;
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};



export const transClientStorageData = (
  originalData: any,
  clientName: string
) => {
  const categories = Object?.keys(originalData) || [];
  const outputData = [
    {
      id: "1",
      text: `Polaris-data-lake (${clientName})`,
      expanded: true,
      items: categories?.map((category, catIndex) => ({
        id: `1_${catIndex + 1}`,
        text: category,
        expanded: false,
        items: Object?.keys(originalData[category])?.map(
          (project, projIndex) => {
            const projectData = originalData[category][project];
            return {
              id: `1_${catIndex + 1}_${projIndex + 1}`,
              text: projectData.projectName,
              expanded: false,
              items: [
                {
                  id: `1_${catIndex + 1}_${projIndex + 1}_1`,
                  text: "",
                  expanded: false,
                  projectId: projectData.projectId,
                  items: [],
                  data: projectData,
                },
              ],
            };
          }
        ),
      })),
    },
  ];

  return outputData;
};

export const transformClientIntegrationData = (
  originalData: any,
  clientName: string
) => {
  const outputData = [
    {
      id: "1",
      text: `Reference Data (${clientName})`,
      expanded: true,
      items: Object?.keys(originalData)?.map(
        (project, projIndex) => {
          const projectData = originalData[project];
          return {
            id: `1_${projIndex + 1}`,
            text: projectData?.projectName,
            expanded: false,
            items: [
              {
                id: `1_${projIndex + 1}_1`,
                text: "",
                expanded: false,
                projectId: projectData?.projectId,
                projectShortName: projectData?.projectShortName,
                data: projectData,
                items: []
              },
            ],
          };
        }
      ),
    },
  ];
  return outputData;
};

export function useFetchClientIntegrationList(clientId: string) {
  return useQuery(
    ["fetch-client-integration-list", clientId],
    () => fetchClientIntegrationList(clientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};