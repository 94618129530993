import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/core-components/alertException.scss";
import { dropDownOptions } from "../../../config/constants";
import { DropDownButton } from "devextreme-react";
import DateDropDown from "../../ui-components/datebox/DateBox";
import RecentAlertExceptionGridView from "./helper-component/GridView";
import DataGridMultiViewLayout from "../../ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { Item } from "devextreme-react/toolbar";
import {
  useFetchSpecificException,
} from "../../../hooks/activity-monitor/useAlertExceptions";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { usePage } from "../../../contexts/pageContext";

const AlertExceptionPage = (props: any) => {
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    t_clientName: clientName,
    clientId: clientid
  } = filterValue || {};
  const {
    showToolbar,
    filteredClientId,
    filteredProjectId,
    height,
    title, 
    pageRoute
  } = props;
  const { data: DateRange } = useFetchDateRange();
  const [executionId, setExecutionId] = useState<any | null>(null);
  const { data: exceptionData, isLoading: isExceptionsLoading } = useFetchSpecificException(executionId?.executionId);
  const [openPopup, setOpenPopup] = useState(false);
  const [fileViewerData, setFileViewerData] = useState(null);
  const fileViewer = JSON?.stringify(fileViewerData, null, 2);
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const [refreshKey, setRefreshKey] = useState(0);

  const navigate = useNavigate();

  const closePopupException = useCallback(() => {
    setOpenPopup(false);
    setFileViewerData(null);
    setExecutionId(null);
  }, [setOpenPopup]);

  useEffect(() => {
    if (exceptionData) {
      setFileViewerData(exceptionData);
    }
  }, [exceptionData]);

  const {
    filterByClient,
    clientDropDownData,
    gridRef,
    handleDropDownPageIndex,
    handleDateRangeChange,
    handleColumnIndexChange
  } = useAppHook();

  const handleExceptionClick = useCallback((e: any, id: string) => {
    e.preventDefault();
    setExecutionId(id);
    setOpenPopup(true);
  }, []);

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );
  const clientfiltered = filteredClientId ?? clientid;
  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      clientId: clientfiltered,
      projectId: filteredProjectId,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [filterValue, clientfiltered, filteredProjectId]);

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const DataGridProps = useMemo(() => {
    return {
      PagerVisible: true,
      fileViewer,
      openPopup,
      handleClientLinkClick,
      closePopupException,
      handleTaskLinkClick,
      handleExceptionClick,
      gridRef,
      height: height ? height : "height-large",
      additionalParams,
      refreshKey,
      handleColumnIndexChange,
      title, 
      pageRoute,
      handleRefetch,
      executionId,
      isExceptionsLoading
    };
  }, [
    fileViewer,
    openPopup,
    closePopupException,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleExceptionClick,
    gridRef,
    height,
    additionalParams,
    refreshKey,
    handleColumnIndexChange,
    title, 
    pageRoute,
    executionId,
    isExceptionsLoading
  ]);
  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: RecentAlertExceptionGridView,
      componentProps: DataGridProps,
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={clientName ? clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DateDropDown
        onDateChange={handleDateRangeChange}
        data={DateRange}
        handleDropDownPageIndex={handleDropDownPageIndex}
      />
    </Item>
  );

  const layoutProps = {
    headerText: "Alerts & Exceptions",
    refetch: handleRefetch,
    additionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: false,
    showToolbar,
    showBackButton: showBackButton,
  };
  

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default AlertExceptionPage;
