import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useDocumentCatalog,
} from "../../../hooks/datastudio/useDocumentCatalog";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { DropDownButton } from "devextreme-react";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import DocumentBoardView from "./helper-component/DocumentBoardView";
import ManagementDashboard from "./helper-component/management-view/ManagementView";
import DocumentGridView from "./helper-component/DocumentGridView";
import { useNavigate } from "react-router-dom";
import { Item } from "devextreme-react/toolbar";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import AddFiles from "./helper-component/AddFiles";
import { ViewType } from "./types";
import { validStatuses } from "../../../config/datastudio/pageConfig";
import { allClients, customDateRange, dropDownOptions } from "../../../config/constants";
import "../../../styles/datastudio/DocumentCatalog.scss";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook } from "../../../hooks/app/useApps";
import uploadIcon from "../../../assets/icons/icon-upload-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { replaceQueryUrl } from "../../../utlis/helper";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";
import { usePage } from "../../../contexts/pageContext";
import { formatDate } from "devextreme/localization";
import { fetchCatalogApi } from "../../../service/datastudio/api-service";

const viewOptions: ViewType[] = [
  { text: "Grid View", value: "gridView" },
  { text: "Workflow View", value: "boardView" },
  { text: "Management View", value: "managmentView" },
];

export const DocumentCatalog = () => {
  const [viewType, setViewType] = useState("gridView");
  const navigate = useNavigate();
  const { getPageProperties } = usePage();
  const [selectedDays, setSelectedDays] = useState<any>({});
  const [show, setPopupAddFilesVisible] = useState(false);
  const { data: DateRange } = useFetchDateRange();
  const { getQueryParams } = useHelperHooks();
  const showBackButton = Boolean(getQueryParams("action"));
  const initialView = getQueryParams("view");
  const [refreshKey, setRefreshKey] = useState(0);

  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
    status,
    pageIndex: p_pageIndex,
    pageSize: p_pageSize,
    sortOrder: p_sortOrder,
    formattedStartTime,
    formattedEndTime,
    timePeriod
  } = filterValue || {};

  const {
    state: {
      clientId: client_id,
      projectId: project_id,
      telemetryData,
      metricsData,
      pdfFinalData,
      mapFileData,
    },
    fetchDropDown,
    getClientId,
    getProjectId,
    setTelemetryData,
    setMetricsData,
    setPdfFinalData,
    setMapFileData,
  } = useDocumentCatalog();

  const {
    state: { client, clientId, projectId, pageIndex, pageSize, isNextPage, sortColumn, sortOrder, grid_status },
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
    clientData,
    handleDropDownPageIndex,
    handlePageIndexChange,
    handleStatusChange,
    handleColumnIndexChange,
    handleDateRangeChange,
    handleManagementViewStatusChange
  } = useAppHook();

  useEffect(() => {
    if (initialView) setViewType(initialView);
  }, [initialView]);

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const onCurrentValueChange = useCallback((value: any) => {
    const [startTime, endTime, timePeriod] = value?.value;
    if (timePeriod === customDateRange) {
      setSelectedDays({ startTime, endTime, timePeriod });
      replaceQueryUrl("period", `startTime=${startTime}&endTime=${endTime}`);
    } else {
      setSelectedDays({ timePeriod });
      replaceQueryUrl("period", timePeriod);
    }
    const eventName = `date_range : ${timePeriod}_${"Projects"}`;
    GTAButtonEventHandler(eventName);
  }, []);

  const handleViewChange = useCallback(
    (e: any) => {
      const { item } = e;
      if (item) {
        setViewType(item?.value);
        replaceQueryUrl("view", item.value);
      }
    },
    [setViewType]
  );

  const handleAddFilesClick = useCallback(() => {
    setPopupAddFilesVisible(!show);
  }, [show]);

  const onRowClick = useCallback(
    (e: { data: any }) => {
      const { DocumentName, executionStatus, DocumentId, isMap, createdDate } = e?.data;

      if (validStatuses?.includes(executionStatus)) {
        navigate(
          `/document-processing?DocumentName=${DocumentName}&executionStatus=${executionStatus}&DocumentId=${DocumentId}&isMap=${isMap}&createdDate=${createdDate}`
        );
      }
    },
    [navigate]
  );

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const boardViewAdditionalParams = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
      clientId: p_clientId,
      projectId: p_projectId,
      status
    };
  }, [filterValue]);

  const managementViewAdditionalParams = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
      clientId: p_clientId,
      projectId: p_projectId,
    };
  }, [filterValue]);

  const additionalParams = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
      clientId: p_clientId,
      projectId: p_projectId
    };
  }, [filterValue]);


  const apiProps = useMemo(
    () => ({
      apiFunction: fetchCatalogApi,
      additionalParams: additionalParams,
      refreshKey
    }),
    [additionalParams, refreshKey]
  );

  const boardViewApiProps = useMemo(
    () => ({
      apiFunction: fetchCatalogApi,
      additionalParams: boardViewAdditionalParams,
      refreshKey,
    }),
    [boardViewAdditionalParams, refreshKey]
  );

  const managementViewApiProps = useMemo(
    () => ({
      apiFunction: fetchCatalogApi,
      additionalParams: managementViewAdditionalParams,
      refreshKey,
    }),
    [managementViewAdditionalParams, refreshKey]
  );

  const DataGridProps = useMemo(() => {
    return {
      clientId: p_clientId,
      projectId: p_projectId,
      sortColumn,
      sortOrder,
      client: p_clientName ? p_clientName : allClients,
      onRowClick,
      handleClientLinkClick,
      handleProjectLinkClick,
      handleTaskLinkClick,
      selectedDays,
      gridRef,
      telemetryData,
      setTelemetryData,
      metricsData,
      setMetricsData,
      pdfFinalData,
      setPdfFinalData,
      mapFileData,
      setMapFileData,
      pageIndex,
      pageSize,
      handlePageIndexChange,
      handleDropDownPageIndex,
      status,
      handleStatusChange,
      apiProps,
      boardViewApiProps,
      handleColumnIndexChange,
      handleManagementViewStatusChange,
      managementViewApiProps
    };
  }, [p_clientId, p_projectId, sortColumn, sortOrder, p_clientName, onRowClick, handleClientLinkClick, handleProjectLinkClick, handleTaskLinkClick, selectedDays, gridRef, telemetryData, setTelemetryData, metricsData, setMetricsData, pdfFinalData, setPdfFinalData, mapFileData, setMapFileData, pageIndex, pageSize, handlePageIndexChange, handleDropDownPageIndex, status, handleStatusChange, apiProps, handleColumnIndexChange]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: DocumentGridView,
      componentProps: DataGridProps,
    },
    {
      mode: "board",
      Component: DocumentBoardView,
      componentProps: DataGridProps,
    },
    {
      mode: "management",
      Component: ManagementDashboard,
      componentProps: DataGridProps,
    },
  ];

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData}
        width={"auto"}
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        text={p_projectName ? p_projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        text={
          viewOptions.find((item) => item.value === viewType)?.text ||
          "gridView"
        }
        stylingMode="text"
        displayExpr="text"
        items={viewOptions}
        width={"auto"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={handleViewChange}
      />
      <DateDropDown
        onDateChange={handleDateRangeChange}
        data={DateRange}
        handleDropDownPageIndex={handleDropDownPageIndex}
      />
      <PSIconText
        alt="addFiles"
        text="Upload Files"
        src={uploadIcon}
        onClick={handleAddFilesClick}
      />
    </Item>
  );

  const layoutProps = {
    headerText: "Document Catalog",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: viewType,
    isLoading: false,
    showBackButton: showBackButton,
  };

  const addFileProps = useMemo(() => {
    return {
      show,
      onClose: handleAddFilesClick,
      client_id,
      project_id,
      fetchDropDown,
      getClientId,
      getProjectId,
      clientData,
    };
  }, [
    show,
    handleAddFilesClick,
    client_id,
    project_id,
    fetchDropDown,
    getClientId,
    getProjectId,
    clientData,
  ]);

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <AddFiles {...addFileProps} />
    </>
  );
};

export default DocumentCatalog;
