import {
  HeaderData,
  MetaData,
  WorkflowData,
  DataItems,
} from "../../types";
import WorkflowSteps from "./tabs/workflowTab";
import { useState } from "react";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import Button from "devextreme-react/button";
import HeaderTab from "./tabs/headerTab";
import MetaTab from "./tabs/metaTab";
import DataTab from "./tabs/dataTab";
import { EditConfirmation } from "./EditConfirmation";
import PsButton from "../../../../../components/ui-components/button/Button";

const WorkFlowEditPanel = ({
  editHeaderData,
  editMetaData,
  editWorkflowData,
  editDataItems,
  updateHeaderField,
  updateMetaField,
  updateWorkflowField,
  updateDataField,
  handleFormSubmit,
  onEditClose,
  actionData,
  functionFilterData,
  fileTypeData,
  showCancelPopup,
  setShowCancelPopup,
  typeData,
  isSuccess,
  selectedValues,
  setSelectedValues
}: {
  editHeaderData: HeaderData;
  editMetaData: MetaData;
  editWorkflowData: WorkflowData[];
  editDataItems: DataItems;
  updateHeaderField: (field: string) => (value: any) => void;
  updateMetaField: (field: string) => (value: any) => void;
  updateWorkflowField: (nodeId: number, field: string) => (value: any) => void;
  updateDataField: (field: string) => (value: any) => void;
  handleFormSubmit: () => void;
  onEditClose: () => void;
  actionData: any;
  functionFilterData: any;
  fileTypeData: any;
  showCancelPopup: boolean;
  setShowCancelPopup: (value: boolean) => void;
  typeData: any;
  isSuccess: boolean;
  selectedValues: any;
  setSelectedValues: any;
}) => {

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleNextTab = () => {
    if (activeTab < 3) setActiveTab(activeTab + 1);
  };

  const handlePreviousTab = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  const handleCancelClick = () => {
    setShowCancelPopup(true);
  };

  const handleCancelConfirm = () => {
    setShowCancelPopup(false);
    onEditClose();
  };

  const handleCancelReject = () => {
    setShowCancelPopup(false);
  };

  const loaderProps = {
    show: isSuccess,
    loadertext: "Saving...",
  };

  return (
    <div className="workflow-container">
      <TabPanel
        selectedIndex={activeTab}
        onSelectionChanged={(e: any) => {
          if (e?.component?.option("selectedIndex") !== activeTab) {
            setActiveTab(e?.component?.option("selectedIndex"));
            e.cancel = true;
          }
        }}
        className="edit-tab-panel"
      >
        <TabPanelItem title="Header">
          <HeaderTab
            editHeaderData={editHeaderData}
            updateHeaderField={updateHeaderField}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </TabPanelItem>
        <TabPanelItem title="Meta">
          <MetaTab
            editMetaData={editMetaData}
            updateMetaField={updateMetaField}
          />
        </TabPanelItem>
        <TabPanelItem title="Workflow">
          <WorkflowSteps
            editWorkflowData={editWorkflowData}
            updateWorkflowField={updateWorkflowField}
            actionData={actionData}
            functionFilterData={functionFilterData}
            typeData={typeData}
            fileTypeData={fileTypeData}
          />
        </TabPanelItem>
        <TabPanelItem title="Data">
          <DataTab
            editDataItems={editDataItems}
            updateDataField={updateDataField}
          />
        </TabPanelItem>
      </TabPanel>
      <div className="footer-actions">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeTab > 0 && (
            <Button
              text="Previous"
              onClick={handlePreviousTab}
              style={{ marginRight: '10px' }}
            />
          )}
          {activeTab < 3 && (
            <Button
              text="Next"
              onClick={handleNextTab}
              style={{ marginRight: '10px' }}
            />
          )}
          {activeTab === 3 && (
            <>
              <Button
                text="Cancel"
                onClick={handleCancelClick}
                style={{ marginRight: '10px' }}
              />
              <PsButton
                text="Save"
                type="default"
                width={'auto'}
                onClick={handleFormSubmit}
                loaderProps={loaderProps}
                eventName="Save Workflow"
              />
            </>
          )}
        </div>
      </div>
      <EditConfirmation
        visible={showCancelPopup}
        onConfirm={handleCancelConfirm}
        onCancel={handleCancelReject}
      />
    </div>
  );
};

export default WorkFlowEditPanel;
