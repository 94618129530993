import { useCallback, useState } from "react";
import Pagination from "../../../../../components/ui-components/pagination/Pagination";
import { ParagraphView } from "./helper-component/paragraphView";
import { TableView } from "./helper-component/tableView";
import { ExtractView } from "./helper-component/extractView";

export const DocumentProcessingViewer = (props: any) => {
  const { pageData, selectedConfidence, onPageChange, viewType, fileType } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderView = useCallback(() => {
    if (viewType === fileType?.[0]) {
      if (pageData?.[`page-${currentPage}`]?.length === 0) {
        return <div className="no-data">No Data</div>;
      }
      return pageData?.[`page-${currentPage}`]?.map((data: any) => {
        if (data) {
          return <ExtractView data={data} selectedConfidence={selectedConfidence} />;
        } else {
          return null;
        }
      });
    } else if (viewType === fileType?.[1]) {
      if (pageData?.[`page-${currentPage}`]?.length === 0) {
        return <div className="no-data">No Data</div>;
      }
      return pageData?.[`page-${currentPage}`]?.map((data: any) => {
        const { type, content } = data || {};
        if (type === "paragraph") {
          return <ParagraphView text={content} selectedConfidence={selectedConfidence} />;
        } else if (type === "table") {
          return <TableView table={content} selectedConfidence={selectedConfidence} />;
        } else {
          return null;
        }
      });
    }
    return null;
  }, [viewType, pageData, currentPage, selectedConfidence, fileType]);

  return (
    <div className="data-viewer">
      <div className="result-container">{renderView()}</div>
      <div>
        <Pagination
          totalPage={pageData?.totalpage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          TelemetryDescription={null}
          TelemetryHidden={true}
        />
      </div>
    </div>
  );
};
