import { useMutation, useQuery } from "react-query";
import {
  fetchDocumentProcessApi,
  fetchDocumentProcessSaveApi,
  fetchDocumentProcessFinalizeApi,
  fetchDocumentProcessRejectApi,
} from "../../service/datastudio/api-service";
import { useNavigate } from "react-router-dom";
import { DataStudioState } from "../../pages/datastudio/documentProcessing/types";
import { useReducer, useEffect, useCallback } from "react";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { useHelperHooks } from "../helper/utils";
import { useFetchFunctionDropDownData } from "../app/useApps";
import { getDocumentProcessingData } from "../../config/datastudio/pageConfig";

const initialState: DataStudioState = {
  documentProcessingStateData: [],
  selectedConfidence: null,
  confidenceValue: "",
  pdfPage: 1,
  documentProcessingFiles: "Extracted"
};

const reducer = (state: DataStudioState, action: any) => {
  switch (action?.type) {
    case "SET_DOCUMENT_PROCESSING_DATA":
      return {
        ...state,
        documentProcessingStateData: action?.payload
      };
    case "SET_SELECTED_CONFIDENCE":
      return {
        ...state,
        selectedConfidence: action?.payload
      };
    case "SET_CONFIDENCE_VALUE":
      return {
        ...state,
        confidenceValue: action?.payload
      };
    case "SET_PDF_PAGE":
      return {
        ...state,
        pdfPage: action?.payload
      };
    case "SET_DOCUMENT_PROCESSING_FILES":
      return {
        ...state,
        documentProcessingFiles: action?.payload
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDocumentProcessingStateProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const DocumentId = useHelperHooks()?.getQueryParams("DocumentId");
  const createdDate = useHelperHooks()?.getQueryParams("createdDate");

  const {
    data: DocumentProcessingData,
    isLoading: isDocumentProcessingDataLoading,
    refetch: DocumentProcessingRefetch
  } = useFetchDocumentProcessingApi(DocumentId as string, createdDate as string);

  const {
    mutate: DocumentProcessingSaveMutate,
    isLoading: isDocumentProcessingSaveLoading
  } = useDocumentProcessingSaveApi();

  const {
    mutate: DocumentProcessingFinalizeMutate,
    isLoading: isDocumentProcessingFinalizeLoading,
    isSuccess: isFinalizeSuccess
  } = useDocumentProcessingFinalizeApi();

  const {
    mutate: DocumentProcessingReject,
    isLoading: isDocumentProcessingRejectLoading,
    isSuccess: isRejectSuccess
  } = useDocumentProcessingRejectApi();

  const {
    data: fetchFunctionDropDownFeatures
  } = useFetchFunctionDropDownData("dropdown");
  const fileTypeMap = fetchFunctionDropDownFeatures?.documentProcessingFiles || [];
  const fileType = fileTypeMap?.map((item: any) => item?.value);

  const setDocumentProcessingStateData = (value: any) => {
    dispatch({ type: "SET_DOCUMENT_PROCESSING_DATA", payload: value });
  };

  const setSelectedConfidence = (value: number | null) => {
    dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: value });
  }

  const setPdfPage = (value: number) => {
    dispatch({ type: "SET_PDF_PAGE", payload: value });
  }

  const setConfidenceValue = (value: string | null) => {
    dispatch({ type: "SET_CONFIDENCE_VALUE", payload: value });
  };

  const refetch = useCallback(() => {
    DocumentProcessingRefetch();
    dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: null });
  }, [DocumentProcessingRefetch]);

  const handleApplyConfidence = (e: any) => {
    if (!e) {
      dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: [] });
      dispatch({ type: "SET_CONFIDENCE_VALUE", payload: [] });
    } else {
      const { value, confidence } = e || {};
      if (value) {
        const confidenceValue = parseFloat(value) / 100;
        setSelectedConfidence(confidenceValue);
        setConfidenceValue(confidence);
      }
    }
  };

  const setDocumentProcessingFiles = useCallback((value: string) => {
    dispatch({ type: "SET_DOCUMENT_PROCESSING_FILES", payload: value });
  }, []);

  const handleDocumentProcessingFiles = (e: any) => {
    const { value } = e || {};
    if (value) {
      setDocumentProcessingFiles(value);
    }
  }

  const handleSaveDocumentProcessing = () => {
    const queryParams = {
      document_catalog_id: DocumentId,
      fileType: state.documentProcessingFiles
    };
    const editedData = getDocumentProcessingData(fileType, DocumentProcessingData, state?.documentProcessingFiles);
    DocumentProcessingSaveMutate({ queryParams, payload: editedData });
  };

  const handleFinalizeDocumentProcessing = async () => {
    const queryParams = {
      document_catalog_id: DocumentId,
      actions: "finalized",
    };
    const finalizedData = getDocumentProcessingData(fileType, DocumentProcessingData, state?.documentProcessingFiles);
    try {
      DocumentProcessingFinalizeMutate({ queryParams, payload: finalizedData });
    } catch (error) {
      console.error("Failed to finalize document processing: ", error);
    }
  };

  useEffect(() => {
    if (isFinalizeSuccess) {
      navigate(-1);
    }
  }, [isFinalizeSuccess, navigate]);

  const handleRejectDocumentProcessing = async () => {
    const queryParams = {
      document_catalog_id: DocumentId,
      actions: "rejected",
    };
    try {
      DocumentProcessingReject(queryParams);
    } catch (error) {
      console.error("Failed to reject document processing: ", error);
    }
  };

  useEffect(() => {
    if (isRejectSuccess) {
      navigate(-1);
    }
  }, [isRejectSuccess, navigate]);

  return {
    state,
    setDocumentProcessingStateData,
    DocumentProcessingData,
    isDocumentProcessingDataLoading,
    refetch,
    isDocumentProcessingSaveLoading,
    handleSaveDocumentProcessing,
    isDocumentProcessingFinalizeLoading,
    handleFinalizeDocumentProcessing,
    isDocumentProcessingRejectLoading,
    handleRejectDocumentProcessing,
    setSelectedConfidence,
    handleApplyConfidence,
    handleDocumentProcessingFiles,
    fetchFunctionDropDownFeatures,
    setPdfPage,
    setDocumentProcessingFiles,
    fileType
  };
};

export const useFetchDocumentProcessingApi = (DocumentId: string, createdDate: string) => {
  const queryParams = {
    document_catalog_id: DocumentId,
    createdDate: createdDate
  };
  return useQuery(
    ["fetch-document-processing", queryParams],
    () => fetchDocumentProcessApi(queryParams),
    {
      enabled: !!DocumentId && !!createdDate,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useDocumentProcessingSaveApi = () => {
  return useMutation(fetchDocumentProcessSaveApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.message,
      });
    },
    onError: (error: Error) => {
      showNotification({
        message: "Save Failed",
        type: "error",
      });
    },
  });
};

export const useDocumentProcessingFinalizeApi = () => {
  return useMutation(fetchDocumentProcessFinalizeApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.message,
      });
    },
    onError: (error: Error) => {
      showNotification({
        message: "Finalizing Failed",
        type: "error",
      });
    },
  });
};

export const useDocumentProcessingRejectApi = () => {
  return useMutation(fetchDocumentProcessRejectApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.message,
      });
    },
    onError: (error: Error) => {
      showNotification({
        message: "Rejecting Failed",
        type: "error",
      });
    },
  });
};