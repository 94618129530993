import showNotification from "../../../components/ui-components/alertPopup/AlertPopup"

export interface HeaderData {
  workflowName: string,
  description: string,
  version: string,
  activity: string,
  type: string,
  applicationName: string,
  clientName: string,
  projectName: string,
  clientId: string,
  projectId: string,
  serviceName: string,
  moduleId: string,
  source: string,
  target: string,
  correlationId: string,
  createdBy: string,
  createdUser: string,
  priority: string,
  severity: string,
}

export const newHeaderTab: HeaderData = {
  workflowName: '',
  description: '',
  version: '1.0',
  activity: '',
  type: '',
  applicationName: 'Polaris',
  clientName: '',
  projectName: '',
  clientId: '',
  projectId: '',
  serviceName: '',
  moduleId: '',
  source: '',
  target: '',
  correlationId: '',
  createdBy: '',
  createdUser: '',
  priority: '',
  severity: ''
}

export interface MetaData {
  tags: string,
  description: string,
  headers?: {
    contentType: string,
    contentLength: any,
    encoding: string,
  }
  catalog: string,
  index: string,
  environment: string,
  retryCount: any,
  durationMs: any,
  geoLocation: string,
  additionalInfo?: {
    osVersion: string,
    pythonVersion: string,
    dockerInfo: string
  }
}

export const newMetaTab: MetaData = {
  tags: '',
  description: '',
  headers: {
    contentType: '',
    contentLength: '',
    encoding: ''
  },
  catalog: '',
  index: '',
  environment: '',
  retryCount: '',
  durationMs: '',
  geoLocation: '',
  additionalInfo: {
    osVersion: '',
    pythonVersion: '',
    dockerInfo: ''
  }
}

export interface WorkflowData {
  name: string,
  description: string,
  inputType: string,
  inputLocation: string,
  inputFilename: string,
  action: string,
  functions: string[],
  type: string,
  impact: string,
  outputType: string,
  outputFilename: string,
  outputLocation: string
}

export const newWorkflowTab: WorkflowData[] = [{
  name: '',
  description: '',
  inputType: '',
  inputLocation: '',
  inputFilename: '',
  action: '',
  functions: [],
  type: '',
  impact: '',
  outputType: '',
  outputFilename: '',
  outputLocation: ''
}]

export interface DataItems {
  items: string[]
}

export const newDataTab: DataItems = {
  items: []
}

export interface WorkflowState {
  popupVisible: boolean
  openPopup: boolean
  selectedWorkflowData: any
  showCancelPopup: boolean
  selectedValues: string
  editPopupVisible: boolean,
  deletePopupVisible : boolean,
}

export const requiredFieldsConfig = {
  header: Object?.keys(newHeaderTab)?.filter((key) => key !== "correlationId"),
  meta: {
    fields: Object?.keys(newMetaTab)?.filter(
      (key) => key !== "headers" && key !== "additionalInfo"
    ),
    headers: Object?.keys(newMetaTab?.headers || {}),
    additionalInfo: Object?.keys(newMetaTab?.additionalInfo || {}),
  },
  workflow: Object?.keys(newWorkflowTab?.[0] || {})?.filter(
    (key) => key !== "functions"
  ),
  workflowArrayFields: ["functions"],
};

export const validateHeaderTab = (newHeaderData: any) => {
  const requiredFields = requiredFieldsConfig?.header || [];
  const invalidFields = requiredFields?.filter(
    (field) => !newHeaderData[field as keyof HeaderData] || newHeaderData[field as keyof HeaderData]?.trim() === ""
  );
  if (invalidFields?.length > 0) {
    showNotification({
      message: `Please fill the following fields in the Header tab: ${invalidFields?.join(", ")}`,
      type: "success",
    });
    return false;
  }
  return true;
};

export const validateMetaTab = (newMetaData: any) => {
  const { fields, headers, additionalInfo } = requiredFieldsConfig?.meta || {};

  const isNonEmptyString = (value: unknown): value is string =>
    typeof value === "string" && value?.trim() !== "";

  const invalidFields = fields?.filter((field) => {
    const value = newMetaData[field as keyof MetaData];
    return !isNonEmptyString(value);
  });

  const invalidHeaders = headers?.filter((field) => {
    const metaHeaders = newMetaData?.headers || {};
    const value = metaHeaders[field as keyof typeof metaHeaders];
    return !isNonEmptyString(value);
  });

  const invalidAdditionalInfo = additionalInfo?.filter((field) => {
    const metaAdditionalInfo = newMetaData?.additionalInfo || {};
    const value = metaAdditionalInfo[field as keyof typeof metaAdditionalInfo];
    return !isNonEmptyString(value);
  });

  const allInvalidFields = [
    ...(invalidFields?.map((field) => `Meta: ${field}`) || []),
    ...(invalidHeaders?.map((field) => `Meta Headers: ${field}`) || []),
    ...(invalidAdditionalInfo?.map((field) => `Meta AdditionalInfo: ${field}`) || []),
  ];

  if (allInvalidFields?.length > 0) {
    showNotification({
      message: `Please fill the following fields in the Meta tab: ${allInvalidFields.join(", ")}`,
      type: "success",
    });
    return false;
  }
  return true;
};

export const validateWorkflowTab = (newWorkflowData: any) => {
  const requiredFields = requiredFieldsConfig?.workflow || [];

  const isNonEmptyString = (value: unknown): value is string =>
    typeof value === "string" && value?.trim() !== "";

  if (!newWorkflowData || newWorkflowData?.length === 0) {
    newWorkflowData = [{}];
  }

  const invalidFields = newWorkflowData?.flatMap((step: any, index: any) => {
    const missingFields = requiredFields?.filter((field) => {
      const value = step[field as keyof WorkflowData];
      return !isNonEmptyString(value);
    });

    const missingFunctions =
      !step?.functions || step?.functions?.length === 0 ? ["functions"] : [];

    return [
      ...missingFields?.map((field) => `Workflow Step ${index + 1}: ${field}`),
      ...missingFunctions?.map((field) => `Workflow Step ${index + 1}: ${field}`),
    ];
  });

  if (invalidFields?.length > 0) {
    showNotification({
      message: `Please fill the following fields in the Workflow tab: ${invalidFields?.join(", ")}`,
      type: "success",
    });
    return false;
  }
  return true;
};
