import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { dropDownOptions } from "../../../../config/constants";
import { ToolbarAnalytics } from "../../../../components/ui-components/toolbar-analytics";
import "../../../../styles/activity-monitor/filterToolbar.scss";

export const LogsAndMetricsFilter = (props: any) => {
  const {
    clientDropDownData,
    filterByClientData,
    filterByProject,
    filterByTask,
    filterByExecutions,
    filterByPdf,
    projectData,
    taskData,
    executionData,
    pdfData,
    projectType,
    selectedClient,
    selectedProject,
    selectedTask,
    selectedExecution,
  } = props;

  const AdditionalToolbarBeforeContent = (
    <Item location="before" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData || []}
        hoverStateEnabled
        displayExpr="name"
        dropDownOptions={dropDownOptions}
        text={selectedClient ? selectedClient : "Clients"}
        useSelectMode
        onSelectionChanged={filterByClientData}
      />
      <DropDownButton
        stylingMode="text"
        displayExpr={"projectName"}
        text={selectedProject ? selectedProject : "Projects"}
        dataSource={projectData || []}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <DropDownButton
        dataSource={taskData || []}
        displayExpr="taskName"
        stylingMode="text"
        dropDownOptions={dropDownOptions}
        onSelectionChanged={filterByTask}
        text={selectedTask ? selectedTask : "Tasks"}
        useSelectMode
      />
      <DropDownButton
        dataSource={executionData || []}
        displayExpr="executionName"
        stylingMode="text"
        dropDownOptions={dropDownOptions}
        onSelectionChanged={filterByExecutions}
        text={selectedExecution ? selectedExecution : "Executions"}
        useSelectMode
      />
      {projectType === "Document" && (
        <DropDownButton
          dataSource={pdfData || []}
          displayExpr={"outputFileName"}
          stylingMode="text"
          dropDownOptions={dropDownOptions}
          // onSelectionChanged={handlePdfChange}
          // text={
          //   state?.currentPdf ? state?.currentPdf?.documentFile : "Select File"
          // }
          useSelectMode
        />
      )}
    </Item>
  );

  return (
    <ToolbarAnalytics
      title={""}
      additionalToolbarBeforeContent={AdditionalToolbarBeforeContent}
    />
  );
};
