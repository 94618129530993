import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { cellTimeStamp } from "../../../ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import DataSource from "devextreme/data/data_source";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import { useCallback, useMemo, useState } from "react";
import AlertExceptionsLineage from "../../../../lineageTracing/alerExceptions";
import LineageViewer from "../../../ui-components/lineageView/LineageView";
import PsDataGrid from "../../../ui-components/dataGrid/DataGrid";
import { fetchClientAlertExceptionApi } from "../../../../service/activity-monitor/api-service";
import { usePage } from "../../../../contexts/pageContext";

const RecentAlertExceptionGridView = (props: any) => {

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName,
    taskName,
    executionId,
    exception,
    timeStamp
  } = filterValue || {};

  const {
    openPopup,
    handleClientLinkClick,
    isExceptionsLoading,
    closePopupException,
    fileViewer,
    handleTaskLinkClick,
    handleExceptionClick,
    height,
    additionalParams,
    refreshKey,
    handleColumnIndexChange,
    title,
    pageRoute,
    handleRefetch,
    executionId: executiondata,
  } = props;

  const [selectedData, setSelectedData] = useState<any>();
  const [openLineage, setOpenLineage] = useState(false);

  const onHanldingLineage = useCallback(() => {
    setOpenLineage(!openLineage);
  }, [openLineage]);

  const onHandleLineageData = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  }, [onHanldingLineage]);


  const renderLineageIcon = useCallback((cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  }, [onHandleLineageData]);

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: (
      <AlertExceptionsLineage data={selectedData} />
    ),
  };

  const columns = useMemo(
    () => [
      {
        dataField: "clientName",
        caption: "Client",
        filterValues: clientName,
        cellRender: (data: any) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;

          return (
            <a
              href="null"
              onClick={(e) => handleClientLinkClick(e, clientId)}
            >
              {clientName}
            </a>
          );
        }
      },
      {
        dataField: "taskName",
        caption: "Task Name",
        filterValues: taskName,
        cellRender: (data: any) => {
          const TaskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {TaskName}
            </a>
          );
        }
      },
      {
        dataField: "executionId",
        caption: "Execution ID",
        filterValues: executionId,
        cellRender: (data: any) => {
          const executionId = data?.data?.executionId;
          const truncatedId = executionId
            ? executionId.toString().substr(0, 10)
            : "";
          return <>{truncatedId}</>;
        }
      },
      {
        dataField: "exception",
        caption: "Exceptions",
        filterValues: exception,
        cellRender: (data: any) => {
          const executionId = data?.data;
          return (
            <a
              href="null"
              onClick={(e) => handleExceptionClick(e, executionId)}
            >
              List of Exceptions
            </a>
          );
        }
      },
      {
        dataField: "timeStamp",
        caption: "Time Stamp",
        filterValues: timeStamp,
        cellRender: cellTimeStamp
      },
      {
        caption: "",
        type: "buttons",
        width: 70,
        cellRender: renderLineageIcon,
      }
    ], [clientName, taskName, executionId, exception, timeStamp, renderLineageIcon, handleClientLinkClick, handleTaskLinkClick, handleExceptionClick]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No alerts & exceptions to display at this time",
      heightClass: height ? height : "height-large",
      id: "alertexceptiongrid",
    }),
    [height]
  );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns: columns,
      keyExpr: "executionId",
      allowedPageSizes: allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagerEnabled: true,
      pagingEnabled: true,
      handleColumnIndexChange,
      title,
      pageRoute,
      handleRefetch
    }),
    [columns, handleColumnIndexChange, title, pageRoute, handleRefetch]
  );

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchClientAlertExceptionApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
        <FileViewer
          visible={openPopup}
          onHiding={closePopupException}
          data={fileViewer || []}
          title={{
            clientName: executiondata?.clientName,
            taskName: executiondata?.taskName,
            createdDate: executiondata?.timeStamp,
          }}
          isLoading={isExceptionsLoading}
          extensionType={"json"}
        />
        <LineageViewer {...lineageDataProps} />
      </div>
    </>
  );
};

export default RecentAlertExceptionGridView;
