import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  DataGridTypes,
  Button as ActionButton,
} from "devextreme-react/data-grid";
import { useStopProgress } from "../../../../../hooks/task/useInfra";
import "../../../../../styles/task/infra-data.scss";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import ConfirmModal from "./helper-component/ConfirmModal";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";
import AciProcessDetails from "./helper-component/AciProcessDetails";
import ReusableWorkflowDataGrid from "./helper-component/WorkflowGrid";
import PsButton from "../../../../../components/ui-components/button/Button";
import TaskIcon from "../../../../../assets/icons/icon-task-2.png";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";

const WorkflowProcessDetails = (
  props: DataGridTypes.MasterDetailTemplateData
) => {
  const {
    id,
    execution_data = [],
    clientId,
    taskId,
    executionId,
    clientName,
    taskName,
    taskConfigPath,
    workflowId,
    status,
    executionName,
    infra_path,
    telementry_path,
    module,
    projectId,
  } = props?.data?.data || {};

  const {
    pre_executions = [],
    post_execution = [],
    main_executions = [],
  } = execution_data[0] || {};

  const [popupVisible, setPopupVisible] = useState(false);
  const [containerName, setContainerName] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const { mutate, isLoading, error, isSuccess } = useStopProgress();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `Terminating container instance: ${containerName}`,
        type: "success",
      });
      setPopupVisible(false);
    } else if (error) {
      showNotification({
        message: `Error terminating container instance: ${error.message}`,
        type: "error",
      });
    }
  }, [isSuccess, isLoading, error, containerName]);

  const onHandleInfraKillConfirm = useCallback(
    (e: any) => {
      const queueMsg = {
        container_name: containerName,
        container_group_name: containerName,
        module: "manual_shutdown_container",
        clientId: clientId,
        taskId: taskId,
        executionId: executionId,
        clientTag: module,
        clientName: clientName,
        taskName: taskName,
        taskConfigPath: taskConfigPath,
        workflowId: workflowId,
        status: status,
        executionName: executionName,
        infraPath: infra_path,
        telemetryPath: telementry_path,
        projectId: projectId,
        queueName: workflowId,
        stepNumber: stepNumber,
      };
      mutate(queueMsg);
    },
    [
      containerName,
      clientId,
      taskId,
      executionId,
      module,
      clientName,
      taskName,
      taskConfigPath,
      workflowId,
      status,
      executionName,
      infra_path,
      telementry_path,
      projectId,
      stepNumber,
      mutate,
    ]
  );

  const renderDisableIcon = useCallback((cell: any) => {
    return cell?.data?.status === "Completed";
  }, []);

  const onHandleConfirm = useCallback((cell: any) => {
    setPopupVisible(true);
    setContainerName(cell?.data?.group_name);
    setStepNumber(cell?.data?.stepNumber);
  }, []);

  const renderAction = useCallback(
    (cell: any) => {
      return (
        <PsButton
          hint="Kill"
          icon={TaskIcon}
          mode="text"
          onClick={(e) => onHandleConfirm(cell)}
          eventName="Infra kill"
          disabled={renderDisableIcon(cell)}
        />
      );
    },
    [onHandleConfirm, renderDisableIcon]
  );

  const onHandleModal = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const ConfirmModalProps = useMemo(() => {
    return {
      onHandleInfraKillConfirm,
      onHandleModal,
      popupVisible,
      isLoading,
    };
  }, [onHandleInfraKillConfirm, onHandleModal, popupVisible, isLoading]);

  const precolumns = [
    { dataField: "function_app_name", caption: "Function App Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
  ];

  const postColumns = [
    { dataField: "function_app_name", caption: "Function App Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
  ];

  const mainColumns = [
    { dataField: "group_name", caption: "Container Group Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
    {
      caption: "",
      type: "buttons",
      width: 130,
      cellRender: renderAction,
    },
  ];

  const createGridProps = (
    data: any[],
    columns: any,
    detailComponent?: (props: any) => JSX.Element
  ) => ({
    dataSource: data?.map((item, index) => ({ ...item, id: index + 1 })) || [],
    columns,
    keyExpr: "id",
    ...(detailComponent && { detailComponent }),
  });

  return (
    <React.Fragment>
      <ReusableWorkflowDataGrid
        title="Pre-Processor"
        gridProps={createGridProps(pre_executions, precolumns)}
        pageProps={{
          nodataText: "No Function Details to display at this time",
          id: `workflowProcessDetails-${executionId}-${id}-pre-processor`
        }}
      />
      <ReusableWorkflowDataGrid
        title="Main Processor"
        gridProps={createGridProps(
          main_executions,
          mainColumns,
          AciProcessDetails
        )}
        pageProps={{
          nodataText: "No Container Group Details to display at this time",
          id: `workflowProcessDetails-${executionId}-${id}-main-processor`
        }}
      />
      <ReusableWorkflowDataGrid
        title="Post-Processor"
        gridProps={createGridProps(post_execution, postColumns)}
        pageProps={{
          nodataText: "No Post Execution Details to display at this time",
          id: `workflowProcessDetails-${executionId}-${id}-post-processor`
        }}
      />
      <ConfirmModal {...ConfirmModalProps} />
    </React.Fragment>
  );
};

export default WorkflowProcessDetails;
