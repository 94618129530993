import { TextArea } from "devextreme-react";
import Card from "../../../../../../components/ui-components/card/card";

const MetaTab = (props: any) => {

  const { editMetaData, updateMetaField } = props;

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <div className="dx-field">
          <div className="dx-field-label">Tags</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Tags..."
              height={35}
              value={editMetaData?.tags}
              onValueChange={updateMetaField("tags")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Description</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Description..."
              height={35}
              value={editMetaData?.description}
              onValueChange={updateMetaField("description")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Content Type</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Content Type..."
              height={35}
              value={editMetaData?.headers?.contentType}
              onValueChange={updateMetaField("headers.contentType")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Content Length</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Content Length..."
              height={35}
              value={editMetaData?.headers?.contentLength}
              onValueChange={updateMetaField("headers.contentLength")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Encoding</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Encoding..."
              height={35}
              value={editMetaData?.headers?.encoding}
              onValueChange={updateMetaField("headers.encoding")}
            />
          </div>
        </div >
        <div className="dx-field">
          <div className="dx-field-label">Catalog</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Catalog..."
              height={35}
              value={editMetaData?.catalog}
              onValueChange={updateMetaField("catalog")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Index</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Index..."
              height={35}
              value={editMetaData?.index}
              onValueChange={updateMetaField("index")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Environment</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Environment..."
              height={35}
              value={editMetaData?.environment}
              onValueChange={updateMetaField("environment")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Retry Count</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Retry Count..."
              height={35}
              value={editMetaData?.retryCount}
              onValueChange={updateMetaField("retryCount")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Duration Ms</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Duration Ms..."
              height={35}
              value={editMetaData?.durationMs}
              onValueChange={updateMetaField("durationMs")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Geo Location</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Geo Location..."
              height={35}
              value={editMetaData?.geoLocation}
              onValueChange={updateMetaField("geoLocation")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">OS Version</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="OS Version..."
              height={35}
              value={editMetaData?.additionalInfo?.osVersion}
              onValueChange={updateMetaField("additionalInfo.osVersion")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Python Version</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Python Version..."
              height={35}
              value={editMetaData?.additionalInfo?.pythonVersion}
              onValueChange={updateMetaField("additionalInfo.pythonVersion")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Docker Info</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Docker Info..."
              height={35}
              value={editMetaData?.additionalInfo?.dockerInfo}
              onValueChange={updateMetaField("additionalInfo.dockerInfo")}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default MetaTab;