import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { LogsAndMetricsFilter } from "./filter-toolbar/filterToolbar";
import {
  useFetchDataProcessDropDownDataApi,
  useLogsAndMetrics,
} from "../../../hooks/activity-monitor/useLogsMetrics";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { allowedPageSizes } from "../../../config/activity-monitor/commonConfig";
import { useFetchDropDownData } from "../../../hooks/app/useApps";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../contexts/pageContext";
import { fetchMetricsDataApi } from "../../../service/activity-monitor/api-service";

const initialState = {
  selectedClient: null,
  selectedProject: null,
  selectedTask: null,
  selectedExecution: null,
  taskData: [],
  executionData: [],
  projectData: [],
  isDataGridVisible: false,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SELECTED_CLIENT":
      return {
        ...state,
        selectedClient: action.payload,
        isDataGridVisible: false,
      };
    case "SET_SELECTED_PROJECT":
      return {
        ...state,
        selectedProject: action.payload,
        isDataGridVisible: false,
      };
    case "SET_SELECTED_TASK":
      return {
        ...state,
        selectedTask: action.payload,
        isDataGridVisible: false,
      };
    case "SET_SELECTED_EXECUTION":
      return {
        ...state,
        selectedExecution: action.payload,
        isDataGridVisible: true,
      };
    case "SET_TASK_DATA":
      return { ...state, taskData: action.payload };
    case "SET_EXECUTION_DATA":
      return { ...state, executionData: action.payload };
    case "SET_PROJECT_DATA":
      return { ...state, projectData: action.payload };
    default:
      return state;
  }
};

export const LogsAndMetrics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [refreshKey, setRefreshKey] = useState(0);

  const {
    state: { clientId },
    filterByClientData,
    filterByProject,
    filterByTask,
    filterByExecutions,
  } = useLogsAndMetrics();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    at_clientId,
    at_projectShortName,
    at_executionTime,
    at_clientShortName,
    at_clientName,
    at_projectName,
    at_executionName,
    at_taskId,
    at_projectId,
    at_taskName,
    metric_id,
    action,
    process_result,
    process_error,
    elapsed_time,
    timestamp,
  } = filterValue || {};

  const { data: fetchDropDown }: { data: any; isLoading: boolean } =
    useFetchDataProcessDropDownDataApi(clientId ? clientId : at_clientId);

  const additionalParams = useMemo(() => {
    return {
      clientShortName: at_clientShortName,
      projectShortName: at_projectShortName,
      projectId: at_projectId,
      executionName: at_executionName,
      executionTime: at_executionTime,
    };
  }, [
    at_clientShortName,
    at_projectShortName,
    at_projectId,
    at_executionName,
    at_executionTime,
  ]);

  const { data: clientSpecificDropDownData } = useFetchDropDownData();

  const { taskData, executionData, projectData } = state;

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchMetricsDataApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    dispatch({
      type: "SET_PROJECT_DATA",
      payload: fetchDropDown?.projects[at_clientId] || [],
    });
  }, [fetchDropDown, at_clientId]);

  useEffect(() => {
    dispatch({
      type: "SET_TASK_DATA",
      payload: fetchDropDown?.tasks[at_projectId] || [],
    });
  }, [fetchDropDown?.tasks, at_projectId]);

  useEffect(() => {
    dispatch({
      type: "SET_EXECUTION_DATA",
      payload: fetchDropDown?.executions[at_taskId] || [],
    });
  }, [fetchDropDown?.executions, at_taskId]);

  const renderErrorDescription = useCallback((data: any) => {
    const { process_error } = data?.data || {};
    const { error_description } = process_error || {};
    return error_description ? error_description : "";
  }, []);

  const pageProps = {
    nodataText: "No Logs & Metrics to display at this time",
    heightClass: "height-large",
    id: "logsandmetricsgrid",
  };

  const columns = [
    {
      dataField: "metric_id",
      caption: "Metric ID",
      filterValues: metric_id,
    },
    {
      dataField: "action",
      caption: "Action",
      filterValues: action,
    },
    {
      dataField: "process_result",
      caption: "Process Result",
      filterValues: process_result,
    },
    {
      dataField: "process_error",
      caption: "Process Error",
      filterValues: process_error,
      cellRender: renderErrorDescription,
    },
    {
      dataField: "elapsed_time",
      caption: "Elasped Time",
      filterValues: elapsed_time,
    },
    {
      dataField: "timestamp",
      caption: "Timestamp",
      filterValues: timestamp,
    },
  ];

  const gridProps = {
    dataSource: DataSource,
    columns: columns,
    keyExpr: "metric_id",
    showBorders: true,
    columnAutoWidth: true,
    allowedPageSizes: allowedPageSizes,
    allowColumnReordering: true,
    allowColumnResizing: true,
    focusedRowEnabled: true,
    headerFilterVisible: true,
    pagerEnabled: true,
    pagingEnabled: true,
  };

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics title={"Logs & Metrics"} refresh={handleRefetch} />
      <LogsAndMetricsFilter
        clientDropDownData={clientSpecificDropDownData}
        filterByClientData={(value: any) => {
          dispatch({ type: "SET_SELECTED_CLIENT", payload: value });
          filterByClientData(value);
        }}
        filterByProject={(value: any) => {
          dispatch({ type: "SET_SELECTED_PROJECT", payload: value });
          filterByProject(value);
        }}
        filterByTask={(value: any) => {
          dispatch({ type: "SET_SELECTED_TASK", payload: value });
          filterByTask(value);
        }}
        filterByExecutions={(value: any) => {
          dispatch({ type: "SET_SELECTED_EXECUTION", payload: value });
          filterByExecutions(value);
        }}
        projectData={projectData}
        taskData={taskData}
        executionData={executionData}
        selectedClient={at_clientName}
        selectedProject={at_projectName}
        selectedTask={at_taskName}
        selectedExecution={at_executionName}
      />
      <div className="grid-container">
        <div className="grid-container">
          <div className="horizontal-scroll">
            <PsDataGrid
              pageProps={pageProps}
              gridProps={gridProps}
              apiProps={apiProps}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogsAndMetrics;
