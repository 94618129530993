import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { allowedPageSizes } from "../../../../config/datastudio/commonConfig";
import MasterDetailView from "./CatalogGrid/DocumentInfo";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import MetricsIcon from "../../../../assets/icons/icon-metrics-4.png";
import TelemetryIcon from "../../../../assets/icons/icon-telemetry-5.png";
import MapFiles from "../../../../assets/icons/icon-file-processing-2.png";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { useCallback, useMemo, useState } from "react";
import { useFetchMetricsDataApi } from "../../../../hooks/activity-monitor/useLogsMetrics";
import { useFetchSingleFiles } from "../../../../hooks/digital-service/useFileManager";
import TelemetryPopup from "../../../../components/core-component/recentActivity/helper-component/telemetry/Telemetry";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import { useFetchMapFiles } from "../../../../hooks/datastudio/useDocumentCatalog";
import DataSource from "devextreme/data/data_source";
import { usePage } from "../../../../contexts/pageContext";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";

const DocumentGridView = (props: any) => {
  const {
    onRowClick,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData,
    mapFileData,
    disableLinks,
    setMapFileData,
    apiProps,
    boardViewApiProps,
    handleColumnIndexChange,
    id
  } = props;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName: clientColunm,
    projectName: projectColumn,
    taskName: taskNameColumn,
    DocumentName: DocumentName,
    uploadedBy: uploadedBy,
    executionStatus: executionStatus,
    createdDate: createdDate,
    updatedDate: updatedDate
  } = filterValue || {};
  const [openPopup, setOpenPopup] = useState(false);
  const [titleData, setTitleData] = useState<any>("");
  const [showTelemetryModal, setShowTelemetryModal] = useState(false);

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const { data: metricsGridData, isLoading: isMetricsLoading } =
    useFetchMetricsDataApi(metricsData);
  const metricsInfo = metricsGridData?.data;

  const { data: pdfInfo, isLoading: isPdfLoading } =
    useFetchSingleFiles(pdfFinalData);

  const { data: mapData, isLoading: isMapLoading } =
    useFetchMapFiles(mapFileData);

  const handleViewMapFile = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      const { row } = cell;
      const { data } = row;
      setPdfFinalData(null);
      setTelemetryData(null);
      setMetricsData(null);
      const mapfileParams = {
        document_id: data?.DocumentId,
        map: data?.isMap,
        ext: "map",
      };
      const titleParams = {
        fileName: data?.DocumentName,
        status: data?.executionStatus,
        ext: "map",
      };
      setMapFileData(mapfileParams);
      setTitleData(titleParams);
      setOpenPopup(true);
    },
    [setMapFileData, setMetricsData, setPdfFinalData, setTelemetryData]
  );

  const renderViewMapFile = useCallback(
    (cell: any) => {
      return (
        <PSIconText
          src={MapFiles}
          alt="Map File"
          hint="Map File"
          width={30}
          height={30}
          onClick={(e: any) => handleViewMapFile(e, cell)}
          disabled={
            cell?.data?.isMap === "disable" ||
            cell?.data?.executionStatus === "Pending"
          }
        />
      );
    },
    [handleViewMapFile]
  );

  const handleViewPdf = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      const { row } = cell;
      const { data } = row;
      setMapFileData(null);
      setMetricsData(null);
      setTelemetryData(null);
      const titleParams = {
        fileName: data?.fileName ? data?.fileName : data?.DocumentName,
        status: data?.executionStatus,
        ext: "pdf",
      };
      const extension =
        data?.DocumentName?.split(".")?.pop() ||
        data?.fileName?.split(".")?.pop();
      const params = {
        name: data?.fileName ? data?.fileName : data?.DocumentName,
        fileExtension: extension,
        projectId: data?.projectId,
        type: data?.type,
        ...data,
      };
      setPdfFinalData(params);
      setTitleData(titleParams);
      setOpenPopup(true);
    },
    [setMapFileData, setMetricsData, setPdfFinalData, setTelemetryData]
  );

  const renderViewFile = useCallback(
    (cell: any) => {
      return (
        <PSIconText
          src={MyFilesView}
          alt="View File"
          hint="View File"
          width={30}
          height={30}
          onClick={(e: any) => handleViewPdf(e, cell)}
        />
      );
    },
    [handleViewPdf]
  );

  const handleViewMetrics = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      const { row } = cell;
      const { data } = row;
      setMapFileData(null);
      setPdfFinalData(null);
      setTelemetryData(null);
      const metricsParams = {
        executionName: data?.executionName,
        clientShortName: data?.clientShortName,
        projectShortName: data?.projectShortName,
        projectId: data?.projectId,
        executionTime: data?.createdDate,
      };
      const titleParams = {
        fileName: data?.DocumentName,
        status: data?.executionStatus,
        ext: "json",
      };
      setMetricsData(metricsParams);
      setTitleData(titleParams);
      setOpenPopup(true);
    },
    [setMapFileData, setMetricsData, setPdfFinalData, setTelemetryData]
  );

  const renderViewMetricsFile = useCallback(
    (cell: any) => {
      return (
        <PSIconText
          src={MetricsIcon}
          alt="Metrics File"
          hint="Metrics File"
          width={30}
          height={30}
          onClick={(e: any) => handleViewMetrics(e, cell)}
          disabled={cell?.data?.executionStatus === "Pending"}
        />
      );
    },
    [handleViewMetrics]
  );

  const handleViewTelemetry = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      const { row } = cell;
      const { data } = row;
      setMapFileData(null);
      setPdfFinalData(null);
      setMetricsData(null);
      const telemetryParams = {
        manifestId: data?.manifestId,
        executionId: data?.executionId,
        taskName: data?.taskName,
        executionName: data?.executionName,
        clientShortName: data?.clientShortName,
        projectShortName: data?.projectShortName,
        projectId: data?.projectId,
        executionTime: data?.createdDate,
      };
      setTelemetryData(telemetryParams);
      setShowTelemetryModal(true);
    },
    [setMapFileData, setMetricsData, setPdfFinalData, setTelemetryData]
  );

  const renderViewTelemetryFile = useCallback(
    (cell: any) => {
      return (
        <PSIconText
          src={TelemetryIcon}
          alt="Telemetry File"
          hint="Telemetry File"
          width={30}
          height={30}
          onClick={(e: any) => handleViewTelemetry(e, cell)}
          disabled={cell?.data?.executionStatus === "Pending"}
        />
      );
    },
    [handleViewTelemetry]
  );

  const title = { file: titleData?.fileName, status: titleData?.status };

  const extension = titleData?.ext;

  const renderFileName = useCallback(
    (cell: any) => {
      return (
        <span
          className="file-name"
          style={{
            cursor: "pointer",
            color: "#0F6CBD",
            textDecoration: "underline",
          }}
          onClick={() => onRowClick(cell)}
        >
          {cell.data.DocumentName}
        </span>
      );
    },
    [onRowClick]
  );

  const columns = useMemo(
    () => [
      {
        caption: "Client",
        dataField: "clientName",
        dataType: "string",
        filterValues: clientColunm,
        cellRender: (data: any) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return disableLinks ? (
            <span>{clientName}</span>
          ) : (
            <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }
      },
      {
        caption: "Project Name",
        dataField: "projectName",
        dataType: "string",
        filterValues: projectColumn,
        cellRender: (data: any) => {
          const projectName = data?.data?.projectName;
          const projectId = data?.data?.projectId;
          return disableLinks ? (
            <span>{projectName}</span>
          ) : (
            <a
              href="*"
              onClick={(e) => handleProjectLinkClick(e, projectId)}
            >
              {projectName}
            </a>
          );
        }
      },
      {
        caption: "Task Name",
        dataField: "taskName",
        dataType: "string",
        filterValues: taskNameColumn,
        cellRender: (data: any) => {
          const taskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return disableLinks ? (
            <span>{taskName}</span>
          ) : (
            <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {taskName}
            </a>
          );
        }
      },      
      {
        caption: "File Name",
        dataField: "DocumentName",
        dataType: "string",
        filterValues: DocumentName,
        cellRender: (data: any) => {
          const FileName = data?.data?.DocumentName;
          return disableLinks ? <span>{FileName}</span> : renderFileName(data);
        },
      },
      { caption: "File Size", dataField: "fileSize", dataType: "string" },
      {
        caption: "Uploaded By",
        dataField: "uploadedBy",
        dataType: "string",
        filterValues: uploadedBy
      },
      {
        caption: "Status",
        dataField: "executionStatus",
        dataType: "string",
        filterValues: executionStatus,
        cellRender: ContactStatus,
      },
      {
        caption: "created Date",
        dataField: "createdDate",
        dataType: "datetime",
        filterValues: createdDate,
        cellRender: cellStartTime,
      },
      {
        caption: "updated Date",
        dataField: "updatedDate",
        dataType: "datetime",
        filterValues: updatedDate,
        cellRender: cellUpdatedTime,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderViewMapFile,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderViewMetricsFile,
        width: 70,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderViewTelemetryFile,
        width: 70,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderViewFile,
        width: 70,
      },
    ],
    [
      clientColunm,
      projectColumn,
      taskNameColumn,
      DocumentName,
      uploadedBy,
      executionStatus,
      createdDate,
      updatedDate,
      handleClientLinkClick,
      handleProjectLinkClick,
      handleTaskLinkClick,
      disableLinks,
    ]
  );
  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "DocumentId",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      detailComponent: MasterDetailView,
      handleColumnIndexChange
    }),
    [columns, handleColumnIndexChange]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No files to display at this time",
      heightClass: "height-large",
      id: id ? id : "documentCalaogGrid",
    }),
    []
  );

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps ? apiProps : boardViewApiProps}
        />
        <TelemetryPopup
          selectedExecution={telemetryData}
          showTelemetryModal={showTelemetryModal}
          onRowClick={() => setShowTelemetryModal(false)}
        />
        <FileViewer
          key={"file-viewer"}
          visible={openPopup}
          onHiding={closePopupException}
          data={mapData || pdfInfo || JSON?.stringify(metricsInfo, null, 2)}
          title={title}
          isLoading={isPdfLoading || isMetricsLoading || isMapLoading}
          extensionType={extension}
        />
      </div>
    </>
  );
};

export default DocumentGridView;
