import { useMemo, useState } from "react";
import { useGetDataProcessingDataApi } from "../../../hooks/datastudio/useDataAnalyzer";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import CatalogGrid from "./helper-component/CatalogGrid";
import { useHelperHooks } from "../../../hooks/helper/utils";
import MyFilesView from "../../../assets/icons/icon-eye.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { useNavigate } from "react-router-dom";
import { Item } from "devextreme-react/toolbar";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { usePage } from "../../../contexts/pageContext";
import { useDataCatalog } from "../../../hooks/datastudio/useDataCatalog";
import MapfileEdit from "./helper-component/mapfile-edit/mapFileEdit";
import { DropDownButton } from "devextreme-react";
import { dropDownOptions } from "../../../config/constants";

export const DataCatalog = () => {
  const { data: DateRange } = useFetchDateRange();

  const {
    handlePageIndexChange,
    handleDropDownPageIndex,
    handleDateRangeChange,
    handleColumnIndexChange,
    datacatalogProcessType,
    datacatalogStatus,
    filterByDataCatalogStatus,
    filterByDataCatalogProcessType
  } = useAppHook();

  const {
    state: {
      popupVisible,
      selectedData,
      filesData,
      openPopup,
      // refreshKey,
      openLineage,
      updatedData
    },
    setPopupOpen,
    setPopupClose,
    setSelectedData,
    setFilesData,
    setOpenPopup,
    // setRefreshKey,
    setOpenLineage,
    setUpdatedData
  } = useDataCatalog();

  const { file_name, user_id, process_type, dataCatalogId } = filesData || {};
  const [refreshKey, setRefreshKey] = useState(0);

  const { data: getFilesData, isLoading: isDataLoading } = useGetDataProcessingDataApi({ filename: file_name, userId: user_id, processType: process_type, dataCatalogId: dataCatalogId, isFileUploaded: true });
  const fileExtensions = file_name?.split(".").pop();
  const fileViewerData = fileExtensions === "pdf" 
  ? getFilesData?.data?.data?.[0]?.filePath 
  : fileExtensions === "xml" ? JSON?.stringify(getFilesData?.data?.data, null, 2) 
  : JSON?.stringify(getFilesData?.data, null, 2);

  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const navigate = useNavigate();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    datacatalog_processtype,
    datacatalog_status,
  } = filterValue || {};

  const editProps = useMemo(() => {
    return {
      selectedData,
      popupVisible,
      setPopupClose,
      updatedData,
      setUpdatedData
    }
  }, [
    selectedData,
    popupVisible,
    setPopupClose,
    updatedData,
    setUpdatedData
  ]);

  const title = "Data Catalog";
  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      file_name: data?.fileName,
      user_id: data?.userId,
      status: data?.status,
      process_type: data?.processType,
      dataCatalogId: data?.dataCatalogId,
      ...data
    };
    setFilesData(params);
    setOpenPopup(true);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        width={28}
        hint="View File"
        height={28}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  };

  const handleRowClick = (cell: any) => {
    const { data } = cell;
    const filename = data?.fileName;
    const dataCatalogId = data?.dataCatalogId;
    const userId = data?.userId;
    const processType = data?.processType;
    const status = data?.status;
    if (data?.processType === "Data Analyzer") {
      navigate(`/data-analyzer?fileName=${filename}&userId=${userId}&processType=${processType}&action=${true}`);
    } else if (data?.processType === "Document Analyzer") {
      navigate(`/document-analyzer?fileName=${filename}&dataCatalogId=${dataCatalogId}&userId=${userId}&processType=${processType}&status=${status}&action=${true}`);
    }
  };


  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.fileName}
      </span>
    );
  };

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      processType: datacatalog_processtype === "All Types" ? "" : datacatalog_processtype,
      status: datacatalog_status === "All Items" ? "" : datacatalog_status,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [datacatalog_processtype, datacatalog_status, filterValue]);

  const handleRefetch = () => {
    setRefreshKey((prevKey: any) => prevKey + 1);
  };

  const DataGridProps = {
    handleViewFiles,
    renderViewFile,
    renderFileName,
    filesData,
    openPopup,
    closePopupException,
    fileViewerData,
    isDataLoading,
    fileExtensions,
    handlePageIndexChange,
    additionalParams,
    refreshKey,
    handleColumnIndexChange,
    setPopupOpen,
    selectedData,
    setSelectedData,
    openLineage,
    setOpenLineage
  };

  const pageBodyComponent = () => [
    {
      mode: "grid",
      Component: CatalogGrid,
      componentProps: DataGridProps,
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={datacatalogProcessType}
        width={"auto"}
        text={datacatalog_processtype ? datacatalog_processtype : "All Types"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByDataCatalogProcessType}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        items={datacatalogStatus}
        width={"auto"}
        text={datacatalog_status ? datacatalog_status : "All Items"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByDataCatalogStatus}
        onItemClick={handleDropDownPageIndex}
      />
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} handleDropDownPageIndex={handleDropDownPageIndex} />
    </Item>
  );

  const layoutProps = {
    headerText: title,
    refetch: handleRefetch,
    additionalToolbarContent,
    pageBodyComponent: pageBodyComponent(),
    isLoading: false,
    showBackButton: showBackButton,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <MapfileEdit {...editProps} />
    </>
  );
};

export default DataCatalog;
