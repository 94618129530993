import { get, post, update, upload } from "../api/api";
import { makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchDateRangeApi = async () => {
  const response = await get("core/v1/daysrangedropdown/daysInfo");
  return response;
};

export const fetchWorkSpaceApi = async (params: any) => {
  const query = makeQueryParam(params);
  const response = await get(`core/v1/workspace/workspaceInfo${query}`);
  return response;
}; // used 

export const createWorkSpaceApi = async (payload: any) => {
  const response = await post("core/v1/workspace/create", payload);
  return response;
}; // used 

export const updateWorkSpaceApi = async (payload: any) => {
  const response = await update("core/v1/workspace/update", payload);
  return response;
}; // used 

export const fetchWorkBookApi = async (params: any) => {
  const query = makeQueryParam(params);
  const response = await get(`core/v1/workbook/workbookInfo${query}`);
  return response;
}; // used 

export const createWorkBookApi = async (payload: any) => {
  const response = await post("core/v1/workbook/create", payload);
  return response;
}; // used 

export const updateWorkBookApi = async (payload: any) => {
  const response = await update("core/v1/workbook/update", payload);
  return response;
}; // used 

export const createWorkItemApi = async (payload: any) => {
  const response = await post("core/v1/activityitems/create", payload);
  return response;
}; // used 

export const fetchActivityItemsPdf = async (params: any) => {
  const query = makeQueryParam(params);
  const response = await get(`core/v1/activityitems/itemsInfo${query}`);
  return response;
}; // used 

export const updateWorkItemApi = async (payload: any) => {
  const response = await update("core/v1/activityitems/update", payload);
  return response;
}; // used 

export const fetchWorkBookItemApi = async (params: any) => {
  const query = makeQueryParam(params);
  const response = await get(`core/v1/workbook/workbookInfo${query}`);
  return response;
}; // used 

export const fetchProjectInfoApi = async (params: any) => {
  const {project_tuples} = params
  const response = await get(
    `client/v1/project/info/projects/list/${project_tuples}`
  );
  return response;
}; // used 

export const fetchRecentActivityInfoApi = async (params: any) => {
  const {project_tuples} = params
  const response = await get(
    `automation/v1/recentactivity/info/project_tuple/${project_tuples}`
  );
  return response;
}; // used 


export const fetchUserInformationData = async (useId: string) => {
  const response = await get(`admin/v1/usercreation/userinfo?userId=${useId}`);
  return response;
};

export const updateWorkbenchShare = async (payload: any) => {
  const response = await update("core/v1/workbookuserentitlement/update", payload);
  return response;
}; // used 


export const createWorkbenchShare= async (payload: any) => {
  const response = await post("core/v1/workbookuserentitlement/create", payload);
  return response;
}; // used 

export const fetchSharedUserData = async (subscriberId: string,assetType:string) => {
  const response = await get(`core/v1/workbookuserentitlement/shareInfo?subscriber_id=${subscriberId}&asset_type=${assetType}`);
  return response;
}; // used 

export const fetchUserDataGrid = async (queryParams:any) => {
  const query = makeQueryParam(queryParams);

  const response = await get(`admin/v1/useradministration/useradmin${query}`);
  return response;
};

export const fetchEditUserStatus = async (payload: any) => {
  const response = await update("admin/v1/usercreation/updateuser", payload);
  return response;
};

export const sendProjectCollaborationMsgApi = async (payload: any) => {
  const response = await post("core/v1/collaboration/create", payload);
  return response;
};


export const fetchProjectCollaborationApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`core/v1/collaboration/collaborationInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const updateAppUserApi = async (payload: any) => {
  const response = await post("admin/v1/usercreation/useronboard", payload);
  return response;
};


export const createErrorLogs = async (payload: any) => {
  const response = await upload("logs/v1/polarisexception/create", payload);
  return response;
};

export const fetchSharedWorkBenchData = async (useId: string,assetId:any) => {
  const response = await get(`core/v1/workbookuserentitlement/userinfo?userId=${useId}&assetId=${assetId}`);
  return response;
}; 