import TaskSummary from "./helper-component/TaskSummary";
import { useMemo } from "react";
import { useCallback, useState } from "react";
import "../../../styles/task/client-task.scss";
import { Item } from "devextreme-react/toolbar";
import { useFetchTaskDetails } from "../../../hooks/task/useTask";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import { useAppHook } from "../../../hooks/app/useApps";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { customDateRange } from "../../../config/constants";

export const ClientTask = () => {
  const { onRowClick } = useAppHook();

  const { data: DateRange } = useFetchDateRange();

  const { data: taskData, refetch } = useFetchTaskDetails();

  const clientIdSpecific = taskData?.[0]?.clientId;
  const taskId = taskData?.[0]?.taskId;
  const 
  {state:{selectedDays},
  handleDateRangeChange
} = useAppHook();

  const DataGridProps = useMemo(() => {
    return {
      taskData,
      onRowClick,
      PagerVisible: true,
      toolbarVisible: true,
      selectedDays,
      showToolbar: false,
      refetch,
    };
  }, [onRowClick, selectedDays, taskData, refetch]);

  const title = taskData
    ? `Task Details - ${taskData?.[0]?.taskName}`
    : "Task Details";

  if (typeof window === "undefined") return null;

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} />
    </Item>
  );

  const pageBodyComponent = () => [
    {
      title: "",
      Component: TaskSummary,
      componentProps: DataGridProps,
      reloadParams: ['fetch-task-detail', 'fetch-finops-cost', 'fetch-task-webscraping-list', 'fetch-invoice-info', 'fetch-collaboration']
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: { showToolbar: false, height: "height-medium", selectedDays: selectedDays, filteredClientSpecific: clientIdSpecific, filterTaskId: taskId, onCurrentValueChange: handleDateRangeChange,  title: "Recent Activity",
      pageRoute: "/activity-dashboard",   },
      pageRoute: "/activity-dashboard",
      componentId: "activitydashboard",
      reloadParams: 'fetch-recent-activity',
      titleToolBar: true,
      hideHeader: true
    },
  ];

  const LayoutProps = {
    headerText: title,
    additionalToolbarContent,
    showBackButton: true,
    pageBodyComponent: pageBodyComponent(),
  };

  return <DataGridTitleCardLayout {...LayoutProps} />;
};
