import { TreeView } from "devextreme-react";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import {
  transformClientIntegrationData,
  useFetchClientIntegrationList,
  useClientProfileData
} from "../../../../hooks/client/useClientProfile";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import { useMemo } from "react";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useAppHook } from "../../../../hooks/app/useApps";
import { ReferenceComponent } from "../../../core-component/referenceDataGrid/referenceComponent";
import { useGetDataProcessingDataApi } from "../../../../hooks/datastudio/useDataAnalyzer";
import "../../../../styles/client/dataTab.scss";

export const IntegrationTab = (props: any) => {
  const { clientContactData } = props;
  const clientId = useHelperHooks().getQueryParams("clientId") ?? "";
  const client = clientContactData?.length > 0 ? clientContactData[0] : {};
  const clientName = client?.name;

  const { data: integrationList, isFetching } = useFetchClientIntegrationList(clientId);

  const finalData = useMemo(() => {
    return integrationList ? transformClientIntegrationData(integrationList, clientName) : [];
  }, [integrationList, clientName]);

  const { handleColumnIndexChange } = useAppHook();

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewIntegrationFileClick
  } = useClientProfileData();

  const { data: SelectedClientFileData, isLoading: isDataLoading } = useGetDataProcessingDataApi({ filename: selectedFileData?.fileName, userId: selectedFileData?.userId, processType: selectedFileData?.processType, dataCatalogId: selectedFileData?.dataCatalogId, isFileUploaded: true });

  const renderItem = (item: any) => {
    const gridProps = {
      id: { projectId: item?.projectId },
      projectShortName: item?.projectShortName,
      onViewIntegrationFileClick,
      handleColumnIndexChange
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? <ReferenceComponent {...gridProps} /> : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.fileName,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
      {isFetching ? (
        <PsSkeleton height={30} count={6} />
      ) : (
        <TreeView
          id="simple-treeview"
          items={finalData}
          itemRender={renderItem}
        />
      )}
      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewIntegrationFileClick}
        data={JSON?.stringify(SelectedClientFileData?.data)}
        title={title}
        isLoading={isDataLoading}
        extensionType={selectedFileData?.assetType}
      />
    </>
  );
};

export default IntegrationTab;