import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import { allowedPageSizes, MapFileProcessType } from "../../../../config/datastudio/commonConfig";
import DataCatlogLineage from "../../../../lineageTracing/data-catlog";
import DataSource from "devextreme/data/data_source";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import LineageViewer from "../../../../components/ui-components/lineageView/LineageView";
import React, { useCallback, useMemo } from "react";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { fetchDataCatalogGridApi } from "../../../../service/datastudio/api-service"
import { usePage } from "../../../../contexts/pageContext";

const CatalogGrid = React.memo((props: any) => {

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};

  const {
    fileName,
    originalFileName,
    userName,
    processType,
    status,
    created_at,
    updated_at
  } = filterValue || {};

  const {
    renderViewFile,
    openPopup,
    filesData,
    closePopupException,
    fileViewerData,
    isDataLoading,
    fileExtensions,
    renderFileName,
    additionalParams,
    refreshKey,
    handleColumnIndexChange,
    setPopupOpen,
    selectedData,
    setSelectedData,
    openLineage,
    setOpenLineage
  } = props;

  const title = {
    file: filesData?.file_name,
    status: filesData?.status,
  };

  const exportData = {
    name: filesData?.file_name,
    userId: filesData?.user_id,
    type: filesData?.process_type,
  };

  const onHanldingLineage = useCallback(() => {
    setOpenLineage(!openLineage);
  }, [openLineage, setOpenLineage]);

  const handleViewWorkflow = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  }, [onHanldingLineage, setSelectedData]);

  const renderViewWorkflowFile = useCallback((cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="Workflow File"
        hint="Workflow File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewWorkflow(e, cell)}
      />
    );
  }, [handleViewWorkflow]);

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: (
      <DataCatlogLineage data={selectedData} />
    ),
  };

  const pageProps = useMemo(
    () => ({
      nodataText: "No catalog to display at this time",
      heightClass: "height-large",
      id: "dataCatalogGrid"
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        dataField: "fileName",
        caption: "System File Name",
        cellRender: renderFileName,
        filterValues: fileName
      },
      {
        dataField: "originalFileName",
        caption: "Original File Name",
        filterValues: originalFileName
      },
      {
        dataField: "userName",
        caption: "Uploaded By",
        filterValues: userName
      },
      {
        dataField: "processType",
        caption: "Asset Type",
        filterValues: processType
      },
      {
        dataField: "status",
        caption: "Status",
        cellRender: ContactStatus,
        filterValues: status
      },
      {
        dataField: "created_at",
        caption: "Uploaded On",
        cellRender: cellStartTime,
        filterValues: created_at
      },
      {
        dataField: "updated_at",
        caption: "Last Updated",
        cellRender: cellUpdatedTime,
        filterValues: updated_at
      },
      {
        caption: "",
        type: "buttons",
        width: 50,
        cellRender: renderViewFile,
      },
      {
        caption: "",
        type: "buttons",
        width: 70,
        cellRender: renderViewWorkflowFile,
      }
    ], [fileName, originalFileName, userName, processType, status, created_at, updated_at]
  );


  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns: columns,
      keyExpr: "dataCatalogId",
      allowedPageSizes: allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagerEnabled: true,
      pagingEnabled: true,
      handleColumnIndexChange,
      onRowEdit: (cell: any) => {
        if (cell?.row?.data?.processType === MapFileProcessType) {
          setPopupOpen(cell);
        }
      },
    }),
    [columns, handleColumnIndexChange, setPopupOpen]
  );

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchDataCatalogGridApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={isDataLoading}
        extensionType={fileExtensions}
        params={exportData}
        showExportToDataLake={true}
      />
      <LineageViewer {...lineageDataProps} />
    </div>
  );
});

export default CatalogGrid;
