import { TreeView } from "devextreme-react/tree-view";
import { useNavigate } from "react-router-dom";
import { ContactStatus } from "../../../../../../components/ui-components/contact-status/ContactStatus";
import {
  CellDollar,
  cellStartTime,
} from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../../../../components/ui-components/fileViewer/FileViewer";
import { cellEndTime } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import {
  transformProjectStorageData,
  useFetchSelectedProjectFileData,
  useProjectProfile,
} from "../../../../../../hooks/client/useProjectProfile";
import { useMemo } from "react";
import { GridComponent } from "../../../../../../components/core-component/dataLakeGrid/GridComponent";
import { location } from "../../../../../../config/client/commonConfig";
import InvoiceInformations from "../../../../../../components/core-component/invoiceComponent/invoiceInformation";
import PsDataGrid from "../../../../../../components/ui-components/dataGrid/DataGrid";
import { useAppHook } from "../../../../../../hooks/app/useApps";


export function CustomTitle(data: any) {
  return (
    <>
      <div className="header">{data?.title}</div>
    </>
  );
}



export function CustomItem(
  data: any,
  activityType: any,
  projectName: string,
  params: any,
  projectShortName: string
) {
  const navigate = useNavigate();

//   const {
//   handleColumnIndexChange,
// } = useAppHook();


  const handleTaskLinkClick = (event: any, taskId: string) => {
    navigate(`/client-task?taskId=${taskId}`);
    event.preventDefault();
  };

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewScrapingButtonClick,
  } = useProjectProfile();

  const { data: SelectedProjectFileData, isLoading } =
    useFetchSelectedProjectFileData(selectedFileData);

  const renderItem = (item: any) => {
    const gridProps = {
      id: { projectId: params?.projectId },
      location: location[item?.id],
      projectShortName: projectShortName,
      activityType: activityType,
      onViewScrapingButtonClick,
      //handleColumnIndexChange,
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? <GridComponent {...gridProps} /> : null}
      </div>
    );
  };

  const transformData = useMemo(() => {
    if (data?.clientData?.length > 0) {
      return transformProjectStorageData(data?.clientData?.[0], projectName);
    }
    return [];
  }, [data, projectName]);

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  const commonColumns = [
    { dataField: "designation", caption: "Role" },
    { dataField: "cost_per_day", caption: "Rate", cellRender: CellDollar },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
    {
      dataField: "startDate",
      caption: "Start Date",
      cellRender: cellStartTime,
    },
    { dataField: "endDate", caption: "End Date", cellRender: cellEndTime },
  ];

  const tasksColumns = [
    {
      dataField: "taskName",
      caption: "Task Name",
      cellRender: (data: any) => {
        const taskDescription = data?.data?.taskName;
        const taskId = data?.data?.taskId;
        return (
          <a href="#" onClick={(e) => handleTaskLinkClick(e, taskId)}>
            {taskDescription}
          </a>
        );
      },
    },
    { dataField: "taskDescription", caption: "Task Description" },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
  ];

  const infrastructureColumns = [
    { dataField: "resource", caption: "Resource Name" },
    { dataField: "quantity", caption: "Quantity" },
    { dataField: "cost_per_day", caption: "Rate", cellRender: CellDollar },
    { dataField: "status", caption: "Status", cellRender: ContactStatus },
    {
      dataField: "startDate",
      caption: "Start Date",
      cellRender: cellStartTime,
    },
    { dataField: "endDate", caption: "End Date", cellRender: cellEndTime },
  ];


  return (
    <div>
      {data.departments &&
        data.departments.map((department: any, index: any) => {
          const departmentDataSource =
            department?.digitalServices ||
            department?.operations ||
            department?.infrastructure;
  
          const departmentColumns =
            department?.title === "Infrastructure"
              ? infrastructureColumns
              : commonColumns;
  
          return (
            <div key={index}>
              <div className="header-title">{department?.title}</div>
              <PsDataGrid
                pageProps={{
                  nodataText: "No data to display",
                  id:`Projectresourcegrid-${params?.projectId}-${department?.title}`
                }}
                gridProps={{
                  dataSource: departmentDataSource,
                  columns: departmentColumns,
                  keyExpr: department?.title === "Infrastructure" ? "resourceId" : "designation",
                  pagingEnabled: false,
                  pagerEnabled: false,
                }}
              />
            </div>
          );
        })}

      {data?.tasks && (
        <PsDataGrid
          pageProps={{
            nodataText: "No tasks to display",
            id: `ProjectTaskgrid-${params?.projectId}`,
          }}
          gridProps={{
            dataSource: data?.tasks,
            columns: tasksColumns,
            keyExpr: "taskId",
            pagingEnabled: false,
            pagerEnabled: false,
          }}
        />
      )}

      {data.finops && (
        <InvoiceInformations clientInfo={data.finops} isLoading={"isLoading"} />
      )}

      {data?.clientData && (
        <TreeView
          id="simple-treeview"
          items={transformData}
          itemRender={renderItem}
        />
      )}

      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewScrapingButtonClick}
        data={
          selectedFileData?.fileExtension === "pdf"
            ? SelectedProjectFileData?.data
            : JSON.stringify(SelectedProjectFileData?.data)
        }
        title={title}
        isLoading={isLoading}
        extensionType={selectedFileData?.fileExtension}
      />
    </div>
  );
}
