import { useCallback, useEffect, useMemo, useState } from "react";
import SummaryChart from "../../../components/client/client-dashboard/dataSummary";
import "../../../styles/client/client-dashboard.scss";
import ComputeUitilization from "../../../components/client/client-dashboard/computeUitilization";
import ActiveTask from "../../../components/client/client-dashboard/activeTask";
import { Item } from "devextreme-react/toolbar";
import { Projects } from "../projects/projects";
import { DropDownButton } from "devextreme-react";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { customDateRange, dropDownOptions } from "../../../config/constants";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useAppHook } from "../../../hooks/app/useApps";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";
import { usePage } from "../../../contexts/pageContext";

export const ClientDashboard = () => {

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { data: DateRange } = useFetchDateRange();

  const {
    t_clientName: t_clientName,
    clientId: clientId,
    pageIndex: pageIndex,
    pageSize: pageSize,
    formattedStartTime,
    formattedEndTime,
    timePeriod,
    projectId
  } = filterValue || {};

  const screen = "homeGraph";

  const {
    state: { selectedDays },
    filterByClient,
    clientDropDownData,
    onRowClick,
    handleDropDownPageIndex,
    handleDateRangeChange,
  } = useAppHook();

  const params = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
      clientId,
      projectId
    };
  }, [filterValue]);

  const DataGridProps = useMemo(() => {
    return {
      onRowClick,
      showToolbar: false,
      selectedDays: { ...params, screen, clientId },
      clientDropDownData,
      clientId,
    };
  }, [clientDropDownData, onRowClick,clientId, selectedDays]);

  const AnalyticsComponent = useCallback(() => {
    return (
      <div className="view-wrapper-analytics">
        <SummaryChart {...DataGridProps} />
        <div className="datails-wrapper">
          <ActiveTask {...DataGridProps} />
          <ComputeUitilization {...DataGridProps} />
        </div>
      </div>
     
    );
  }, [DataGridProps]);

  const pageBodyComponent = useMemo(
    () => [
      {
        title: "Cost Summary",
        Component: AnalyticsComponent,
        componentProps: DataGridProps,
        reloadParams: ['fetch-finops-cost', 'fetch-active-task']
      },
      {
        title: "Project Details",
        Component: Projects,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          selectedDays: selectedDays,
          height: "height-medium",
          title: "Project Details",
          pageRoute: "/projects"
        },
        pageRoute: "/projects",
        componentId: "projects",
        reloadParams: 'fetch-project-clientInfo'  ,
        titleToolBar: true,
        hideHeader: true
      },
      {
        title: "Recent Activity",
        Component: RecentActivityDataGrid,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          height: "height-medium",
          selectedDays: selectedDays,
          onCurrentValueChange: handleDateRangeChange,
          title: "Recent Activity",
          pageRoute: "/activity-dashboard",
        },
        pageRoute: "/activity-dashboard",
        componentId: "activitydashboard",
        reloadParams: 'fetch-recent-activity',
        titleToolBar: true,
        hideHeader: true
      },
      {
        title: "Alerts & Exceptions",
        Component: AlertExceptionPage,
        componentProps: {
          showToolbar: false,
          filteredClientId: clientId,
          height: "height-medium",
          selectedDays: selectedDays,
          onCurrentValueChange: handleDateRangeChange,
          title: "Alerts & Exceptions",
          pageRoute: "/alert-exceptions",
        },
        pageRoute: "/alert-exceptions",
        componentId: "alert&exceptions",
        reloadParams: 'fetch-alert-Exception',
        titleToolBar: true,
        hideHeader: true
      },
    ],
    [AnalyticsComponent, DataGridProps, clientId, handleDateRangeChange, selectedDays]
  );

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          items={clientDropDownData || ["Loading..."]}
          stylingMode="text"
          text={t_clientName ? t_clientName : "All Clients"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
        />
        <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} handleDropDownPageIndex={handleDropDownPageIndex} />
      </Item>
    );
  }, [
    t_clientName,
    clientDropDownData,
    DateRange,
    filterByClient,
    handleDateRangeChange,
  ]);

  const LayoutProps = useMemo(() => {
    return {
      headerText: "Client Activity Summary",
      additionalToolbarContent,
      pageBodyComponent: pageBodyComponent,
    };
  }, [additionalToolbarContent, pageBodyComponent]);

  return <DataGridTitleCardLayout {...LayoutProps} />;
};