import { useQuery } from "react-query";
import {
  fetchDataProcessDropDownApi,
  fetchMetricsDataApi,
} from "../../service/activity-monitor/api-service";
import { useReducer } from "react";
import { usePage } from "../../contexts/pageContext";
import { useLocation } from "react-router-dom";

export interface LogsAndMetricsState {
  clientId: string;
  projectId: string;
  taskId: string;
  executionId: string;
  projectData: any;
  taskData: any;
  executionData: any;
  dataProcessingParams: any;
  showingDuplicates: string[];
  confidencParams: string;
  projectType: string;
  clientTag: string;
  webScrapingPath: string;
  uploadData: string;
  clientShortName: string;
  projectShortName: string;
  taskShortName: string;
  executionName: string;
  executionTime: string;
}

export const initialState: LogsAndMetricsState = {
  clientId: "",
  projectId: "",
  taskId: "",
  executionId: "",
  projectData: null,
  taskData: null,
  executionData: null,
  dataProcessingParams: {},
  showingDuplicates: [],
  confidencParams: "",
  projectType: "",
  clientTag: "",
  webScrapingPath: "",
  uploadData: "",
  clientShortName: "",
  projectShortName: "",
  taskShortName: "",
  executionName: "",
  executionTime: "",
};

export const reducer = (state: LogsAndMetricsState, action: any) => {
  switch (action?.type) {
    case "CLIENTID":
      return {
        ...state,
        clientId: action.payload,
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload,
      };
    case "TASKID":
      return {
        ...state,
        taskId: action.payload,
      };
    case "EXECUTIONID":
      return {
        ...state,
        executionId: action.payload,
      };
    case "PROJECTDATA":
      return {
        ...state,
        projectData: action.payload,
      };
    case "TASKDATA":
      return {
        ...state,
        taskData: action.payload,
      };
    case "EXECUTIONDATA":
      return {
        ...state,
        executionData: action.payload,
      };
    case "DATAPROCESSINGPARAMS":
      return {
        ...state,
        dataProcessingParams: action.payload,
      };
    case "PROJECTTYPE":
      return {
        ...state,
        projectType: action.payload,
      };
    case "CLIENTTAG":
      return {
        ...state,
        clientTag: action.payload,
      };
    case "CLIENTSHORTNAME":
      return {
        ...state,
        clientShortName: action.payload,
      };
    case "PROJECTSHORTNAME":
      return {
        ...state,
        projectShortName: action.payload,
      };
    case "EXECUTIONNAME":
      return {
        ...state,
        executionName: action.payload,
      };
    case "EXECUTIONTIME":
      return {
        ...state,
        executionTime: action.payload,
      };
    case "WEBSCRAPINGPATH":
      return {
        ...state,
        webScrapingPath: action.payload,
      };
    case "SET_GET_UPLOADED_DATA":
      return {
        ...state,
        uploadData: action.payload,
      };
    case "CLEAR_DEPENDENCIES":
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useLogsAndMetrics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { updatePageProperties } = usePage();
  const { pathname } = useLocation();
  const path = pathname.split("/").join("");

  const filterByClientData = (e: any) => {
    const { item } = e;
    const { clientId, clientTag, clientShortName, name } = item || {};
    dispatch({ type: "CLIENTID", payload: clientId });
    dispatch({ type: "CLIENTTAG", payload: clientTag });
    dispatch({ type: "CLIENTSHORTNAME", payload: clientShortName });
    updatePageProperties(path, {
      at_clientName: name,
      at_clientId: clientId,
      at_clientShortName: clientShortName,
      at_projectName: "",
      at_projectId: "",
      at_projectShortName: "",
      at_taskName: "",
      at_taskId: "",
      at_executionName: "",
      at_executionId: "",
      at_executionTime: "",
    });
  };

  const handleChangeProjectData = (e: any) => {
    dispatch({ type: "PROJECTDATA", payload: e?.projects[state?.clientId] });
  };

  const filterByProject = (e: any) => {
    const { item } = e;
    const { projectId, projectType, projectShortName, projectName } =
      item || {};
    dispatch({ type: "PROJECTID", payload: projectId });
    dispatch({ type: "PROJECTTYPE", payload: projectType });
    dispatch({ type: "PROJECTSHORTNAME", payload: projectShortName });
    updatePageProperties(path, {
      at_projectName: projectName,
      at_projectId: projectId,
      at_projectShortName: projectShortName,
      at_taskName: "",
      at_taskId: "",
      at_executionName: "",
      at_executionId: "",
      at_executionTime: "",
    });
  };

  const filterByTask = (e: any) => {
    const { item } = e;
    const { taskId, filePath, taskName } = item || {};
    dispatch({ type: "TASKID", payload: taskId });
    dispatch({ type: "WEBSCRAPINGPATH", payload: filePath });
    updatePageProperties(path, {
      at_taskName: taskName,
      at_taskId: taskId,
      at_executionName: "",
      at_executionId: "",
      at_executionTime: "",
    });
  };

  const filterByExecutions = (e: any) => {
    const { item } = e;
    const {
      taskId,
      projectId,
      clientTag,
      projectType,
      webScrapingPath,
      clientShortName,
      projectShortName,
      executionName,
    } = state || {};
    dispatch({ type: "EXECUTIONID", payload: item?.executionId });
    dispatch({ type: "EXECUTIONNAME", payload: item?.executionName });
    dispatch({ type: "EXECUTIONTIME", payload: item?.executionTime });
    const additionalParams = {
      taskId: taskId,
      projectId: projectId,
      executionId: item?.executionId,
      executionName: executionName,
      clientTag: clientTag,
      activityType: projectType,
      webScrapingPath: webScrapingPath,
      clientShortName: clientShortName,
      projectShortName: projectShortName,
      executionTime: item?.executionTime,
    };
    dispatch({ type: "DATAPROCESSINGPARAMS", payload: additionalParams });
    updatePageProperties(path, {
      at_executionName: item?.executionName,
      at_executionId: item?.executionId,
      at_executionTime: item?.executionTime,
    });
  };

  const filterByPdf = (e: any) => {
    const { item } = e;
    const {
      clientId,
      taskId,
      projectId,
      executionId,
      clientTag,
      projectType,
      webScrapingPath,
    } = state || {};
    const additionalParams = {
      clientId: clientId,
      taskId: taskId,
      projectId: projectId,
      executionId: executionId,
      documentPath: item?.outputFilePath,
      documentFile: item?.outputFileName,
      clientTag: clientTag,
      activityType: projectType,
      webScrapingPath: webScrapingPath,
    };
    dispatch({ type: "DATAPROCESSINGPARAMS", payload: additionalParams });
  };

  const handleDuplicates = (e: any) => {
    const { value, checked } = e || {};
    dispatch({ type: "DUPLICATEPARAMS", payload: { value, checked } });
  };

  const handleConfidence = (e: any) => {
    const { value } = e || {};
    dispatch({ type: "CONFIDENCEPARAMS", payload: value });
  };

  const getClientId = (e: any) => {
    const { value } = e || {};
    const { clientId } = value || {};
    dispatch({ type: "CLIENTID", payload: clientId });
  };

  const getProjectId = (e: any) => {
    const { value } = e || {};
    const { projectId } = value || {};
    dispatch({ type: "PROJECTID", payload: projectId });
  };

  return {
    state,
    filterByClientData,
    handleChangeProjectData,
    filterByProject,
    filterByTask,
    filterByExecutions,
    filterByPdf,
    handleDuplicates,
    handleConfidence,
    getClientId,
    getProjectId,
  };
};

export const useFetchDataProcessDropDownDataApi = (id: string) => {
  return useQuery(
    ["fetch-dataprocess-drop-down", id],
    () => fetchDataProcessDropDownApi(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchMetricsDataApi = (logmetricsParams: any) => {
  return useQuery(
    ["fetch-metrics-data", logmetricsParams],
    () => fetchMetricsDataApi(logmetricsParams),
    {
      refetchOnWindowFocus: false,
      enabled:
        !!logmetricsParams?.executionName &&
        !!logmetricsParams?.clientShortName &&
        !!logmetricsParams?.projectShortName &&
        !!logmetricsParams?.projectId &&
        !!logmetricsParams?.executionTime,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};
