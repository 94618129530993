import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import ProjectDetails from "../../../../components/client/project-details/ProfileTab/projectDetails";
import { TasksTab } from "../../../../components/client/project-details/TasksTab/tasksTab";
import ProjectDataTab from "../../../../components/client/project-details/DataTab/ProjectDataTab";
import ResourcesTab from "../../../../components/client/project-details/ResourcesTab/resourcesTab";
import InfrastructureTab from "../../../../components/client/project-details/InfrastructureTab/infrastructureTab";
import FinopsTab from "../../../../components/client/project-details/FinopsTab/finopsTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";
import IntegrationTab from "../../../../components/client/project-details/integrationTab/integrationTab";
import { useEffect, useState } from "react";
import { usePage } from "../../../../contexts/pageContext";
import { useHelperHooks } from "../../../../hooks/helper/utils";

const ProjectSummary = (props: any) => {
  const { selectedDays, projectData, refetch } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const projectId = useHelperHooks().getQueryParams("projectId");
  const { getPageProperties, updatePageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { selectedTabIndex: p_selectedTabIndex, selectedProjectId } = filterValue || {};

  useEffect(() => {
    if (projectId !== selectedProjectId) {
      updatePageProperties("project-profile", { selectedTabIndex: 0, selectedProjectId: projectId });
    } else {
      setSelectedTabIndex(p_selectedTabIndex || 0);
    }
  }, [p_selectedTabIndex, projectId, selectedProjectId, updatePageProperties])

  const TabMap = [
    { title: "Project Profile", component: ProjectDetails, componentProps: { selectedDays } },
    { title: "Tasks", component: TasksTab },
    {
      title: "Data",
      component: ProjectDataTab,
      componentProps: { selectedDays, projectData },
    },
    {
      title: "Team",
      component: ResourcesTab,
      componentProps: { selectedDays, ResourceData: projectData, refetch },
    },
    {
      title: "Infrastructure",
      component: InfrastructureTab,
      componentProps: { selectedDays, ResourceData: projectData, refetch },
    },
    { title: "Finops", component: FinopsTab, componentProps: { projectData } },
    { title: "Collaboration", component: CollaborationChats },
    { title: "Integration", component: IntegrationTab, componentProps: { projectData } }
  ];

  const onTabChange = (e: any) => {
    if (e.name === "selectedIndex") {
      setSelectedTabIndex(e.value);
      updatePageProperties("project-profile", { selectedTabIndex: e.value });
    }
  };

  const TabPanelItems = TabMap.map((tab, index) => {
    const Component = tab.component;
    return (
      <TabPanelItem title={tab.title} key={index}>
        <div style={{ minHeight: "465px" }}>
          <Component {...tab.componentProps} />
        </div>
      </TabPanelItem>
    );
  });

  return (
    <TabPanel
      focusStateEnabled={false}
      deferRendering={false}
      animationEnabled={true}
      swipeEnabled={true}
      selectedIndex={p_selectedTabIndex || selectedTabIndex}
      onOptionChanged={onTabChange}
    >
      {TabPanelItems}
    </TabPanel>
  );
};

export default ProjectSummary;
