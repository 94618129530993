import { useQuery } from 'react-query';
import { createErrorLogs, fetchUserDataGrid } from '../../service/admin/api-service';
import { useMutation } from "react-query";
import { updateAppUserApi, fetchEditUserStatus } from "../../service/admin/api-service";

export const useUpdateUser = () => {
  return useMutation(updateAppUserApi, {
    onError: (error: Error) => {
      console.log(error);
    },
  });
};



export const useEditUserStatus = () => {
  return useMutation(fetchEditUserStatus, {
    onError: (error: Error) => {
      console.log(error);
    },
  });
}

export const useUpdateErrorLog = () => {
  return useMutation(createErrorLogs, {
    onError: (error: Error) => {
      console.log(error);
    },
  });
}